import React, { useState, useEffect } from 'react';

const Timer = ({ hoursMinSecs }) => {

    const { minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[mins, secs], setTime] = useState([minutes, seconds]);

    const tick = () => {
        if (mins === 0 && secs === 0) {
        } else if (secs === 0) {
            setTime([mins - 1, 59]);
        } else {
            setTime([mins, secs - 1]);
        }
    };

    useEffect(() => {
        const timerId = setInterval(tick, 1000);
        return () => clearInterval(timerId);
    });

    return (
        <div>
            <p className="api_text text-center mt-4">Time Remaining</p>
            {mins === 0 && secs === 0 ?
                <>
                    <p>time over</p>
                </> :
                <>
                    <p className='text-center'>{`${mins
                        .toString()
                        .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}</p>
                </>
            }
        </div>
    );
}

export default Timer;