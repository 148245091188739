import React, { useEffect, useState } from "react";
import { fetchToken } from "../../Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../Feature/Auth/authSlice";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import statement from "./Group 87.png";
import setting_img from "./Group 97.png";
import SmallFooter from "../SmallFooter";
import Loader from "../Loader";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import cross from "./Group 438.svg";
import LoginSocialAccount from "../../components/LoginSocialAccount";

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [show2, setShow2] = useState(false);
  const [pass, setPass] = useState("");
  const [fa2, setfa2] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [loading, setLoading] = useState(true);

  const getInfo = () => {
    fetch("https://api.flitchcoin.com/api/users/me/items/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          setUsername(res.username);
          const data = JSON.stringify({
            emailid: res.username,
          });
          fetch("https://api.flitchcoin.com/api/userchrono_info", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: data,
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.fa2) {
                setfa2(true);
              }
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    change();
    getInfo();
  }, []);

  const [avt, setAvt] = useState();

  const change = () => {
    fetch("https://api.flitchcoin.com/api/dashboard", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          setAvt(res.avtar_im);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const closeAccount = () => {
    setLoading(true);
    var data = `grant_type=&username=${username}&password=${pass}&scope=&client_id=&client_secret=`;
    fetch("https://api.flitchcoin.com/api/Signup", {
      method: "DELETE",
      headers: {
        Accept: "application/x-www-form-urlencoded ",
        "Content-Type": "application/x-www-form-urlencoded ",
        Authorization: `Bearer ${fetchToken()}`,
      },
      body: data,
    })
      .then((result) =>
        result.json().then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setShowA(true);
            dispatch(logOutUser());
            navigate("/login");
          } else {
            setShowB(true);
          }
          setTimeout(() => {
            setShowA(false);
            setShowB(false);
          }, 3000);
        })
      )
      .catch((err) => console.log(err));
  };

  const responseSocialAuth = async (response) => {
    // debugger
    const { user } = response;
    const {
      reloadUserInfo: { passwordHash },
    } = user;
    setPass(passwordHash);
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="row mt-4">
            <div
              className="col-xxl-2 col-xl-3 col-12 side_navigation"
            >
              <Link to="/profile" className="link">
                <i className=" ps-4 pe-4 pt-2 pb-2 mb-3 dropdown-item mt-5 ">
                  <img
                    src={avt}
                    alt="profile"
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                  />{" "}
                  &nbsp; Profile
                </i>
              </Link>
              <Link to="/statements" className="link">
                <i className=" ps-4 pe-4 pt-2 pb-2 mb-3 dropdown-item">
                  <img
                    alt="statement"
                    src={statement}
                    style={{ height: "32px", width: "32px" }}
                  />{" "}
                  &nbsp; Statements
                </i>
              </Link>
              <Link to="/settings" className="link">
                <i className="ps-4 pe-4 pt-2 pb-2 dropdown-item  selected-item">
                  <img
                    alt="settings"
                    src={setting_img}
                    style={{ height: "25px", width: "25px" }}
                  />{" "}
                  &nbsp; Settings
                </i>
              </Link>
            </div>
            <div className="col-md-2"></div>
            <div
              className="col-12 col-md-6 mt-4"
              id="mainsettings"
            >
              <p
                className="text-center mt-4"
                style={{ fontSize: "47px", fontWeight: "700" }}
              >
                Settings
              </p>
              <hr />
              <div className="mt-5" id="stepverify">
                <div className="row settings_box p-4">
                  <div className="col-lg-8">
                    <p style={{ fontSize: "32px", fontWeight: "600" }}>
                      2 - Step Verification
                    </p>
                    <p className="text-muted">
                      You have currently opted to continue{" "}
                      <span className="text-dark">
                        {" "}
                        {fa2 ? <>with</> : <>without</>}
                      </span>{" "}
                      2 - Factor Authentication
                    </p>
                    <p className="text-muted">
                      Note : To make your account secure we recommend to you to
                      have active 2 factor authentication.
                    </p>
                  </div>
                  <div className="col-lg-4 mt-5 " id="fa2button">
                    <button
                      className="w-100 btn-dark round-btn"
                      onClick={() => navigate("/qr_verify")}
                    >
                      {fa2 ? <>Deactivate</> : <>Activate</>} 2-FA
                    </button>
                  </div>
                </div>
                <div
                  className="row settings_box p-4 mt-4 mb-4"
                  id="closeaccount"
                >
                  <div className="col-lg-8">
                    <p style={{ fontSize: "32px", fontWeight: "600" }}>
                      Close Account
                    </p>
                    <p className="text-muted">
                      On clicking the button you will close your account
                      permanently . Make sure you have no amount in your
                      flitchCoin wallet otherwise it may lead to loss of that
                      amount.
                    </p>
                    <Modal
                      show={show2}
                      onHide={() => setShow2(false)}
                      backdrop="static"
                      keyboard={false}
                      className="modal-dialog-coin"
                    >
                      <div className="popup_error">
                        <div className="popup_error_head">
                          <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
                            Enter Your Password
                          </p>
                          <div className="event_line"></div>
                        </div>
                        <div className="form-group p-3 pb-0">
                          <input
                            name="Password" value={pass} onChange={(e) => setPass(e.target.value)}
                            type="password"
                            className="form-control undefined"
                          />
                        </div>
                        <div className="or-continue-wrapper">
                          <div className="or-c-lr">
                            <div className="or-continue-box">
                              <div className="or-continue-content">
                                <span>or continue with</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ paddingLeft: "5.6rem", paddingRight: "5.6rem" }}>

                          <LoginSocialAccount socialAuthResponse={responseSocialAuth} />
                        </div>

                        <div className="d-flex justify-content-between ms-5 me-5 pb-4">
                          <button
                            type="button"
                            className="btn btn-spl-black ps-3 pe-3"
                            onClick={() => setShow2(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-spl-black ps-3 pe-3"
                            onClick={closeAccount}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <div className="col-lg-4 mt-5 " id="closebutton">
                    <button
                      className="w-100 btn-dark round-btn"
                      onClick={() => setShow2(true)}
                    >
                      Close Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SmallFooter />
          <Modal
            show={showA}
            onHide={() => setShowA(false)}
            backdrop="static"
            keyboard={false}
            className="modal-dialog-coin"
          >
            <div className="popup_error">
              <div className="popup_error_head">
                <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
                  Deleted !
                </p>
                <div className="event_line"></div>
              </div>
              <p className="popup_error_body_text pt-4 pb-3">
                Your account has been deleted permanently..
              </p>
              <div className="d-flex justify-content-center pb-4">
                <CountdownCircleTimer
                  size={40}
                  strokeWidth={3}
                  isPlaying
                  duration={3}
                  colors={"red"}
                  colorsTime={[3, 0]}
                >
                  {({ remainingTime }) => {
                    return (
                      <img
                        src={cross}
                        className="cross"
                        onClick={() => setShowA(false)}
                      />
                    );
                  }}
                </CountdownCircleTimer>
              </div>
            </div>
          </Modal>
          <Modal
            show={showB}
            onHide={() => setShowB(false)}
            backdrop="static"
            keyboard={false}
            className="modal-dialog-coin"
          >
            <div className="popup_error">
              <div className="popup_error_head">
                <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
                  Error !
                </p>
                <div className="event_line"></div>
              </div>
              <p className="popup_error_body_text pt-4 pb-3">Wrong Password </p>
              <div className="d-flex justify-content-center pb-4">
                <CountdownCircleTimer
                  size={40}
                  strokeWidth={3}
                  isPlaying
                  duration={3}
                  colors={"red"}
                  colorsTime={[3, 0]}
                >
                  {({ remainingTime }) => {
                    return (
                      <img
                        src={cross}
                        className="cross"
                        onClick={() => setShowB(false)}
                      />
                    );
                  }}
                </CountdownCircleTimer>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Settings;
