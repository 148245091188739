import React, { useState,useEffect } from 'react'
import Fields from "./fields";
import "./index.css";
import dropdown from "./Polygon 2.svg";
import { fetchToken } from "../../Auth";
import { Modal } from "react-bootstrap";
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import SmallFooter from "../SmallFooter/index.jsx";
import { Helmet } from 'react-helmet';

const Club = () => {

  const [show,setShow] = useState(false);
  const [coinImg,setCoinImg] = useState("");
  const [coin, setCoin] = useState("Select coin");
  const [asset, setAsset] = useState([]);

  const [searchParam, setSearchParam] = useState("");

  function asset_link() {
    fetch("https://api.flitchcoin.com/api/asset_link", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${fetchToken()}`
      }
    }).then((result) => {
      result.json().then((res) => {
        const data = Object.values(res);
        let tmpSymbol = [];
        data.map(items => {
          tmpSymbol.push(items)
        });
        setAsset([...tmpSymbol]);
      });
    });
  };

  useEffect(() => {
    asset_link();
  },[]);

  return (
    <>
    <Helmet>
        <title>Join the Cryptocoin Club: Connect, Learn, and Grow with Like-Minded Crypto Enthusiasts</title>
        <meta name="description" content="Immerse Yourself in the Cryptocoin Club: Collaborate, Share Knowledge, and Stay Informed about the Latest Trends and Opportunities in the Cryptocurrency Space. Join Our Community Today and Amplify Your Crypto Journey!"/>
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      <div className="row">
        <div className="col-2 pt-5 pb-5">
          <p className="club_text">The </p><p className="club_text p-3"> 7% </p><p className="club_text">Club </p>
        </div>
        <div className="col-2"></div>
        <div className="col-3 pt-3 mt-4" style={{marginLeft : "3rem"}}>
          <div
            className="card special_card_earn pt-4 p-4"
          >
            <div className="row order__body p-0">
              <h2 className="text-center text_design placeorder-heading">
                Earn Order
              </h2>
              <p className="earn_subhead">
                Earning Asset :
              </p>
              <div className="col-12  btn-group select-coin-btn">
                <button
                  type="button"
                  className="select_coin_btn w-100 mb-4"
                  onClick={() => setShow(true)}
                >
                  <div className="row">
                    <div className="col-2">
                      {coinImg === "" ? (
                        <></>
                      ) : (
                        <>
                          <img
                            src={coinImg}
                            className="select_img"
                            alt="coin"
                          />
                        </>
                      )}
                    </div>
                    <div className="col-8 text-center">
                      <b>{coin}</b>
                    </div>
                    <div className="col-2 text-center">
                      <img src={dropdown} alt="drop" />
                    </div>
                  </div>
                </button>
              </div>
              <Fields
                sym={coin}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
              show={show}
              onHide={() => setShow(false)}
              backdrop="static"
              keyboard={false}
              className="modal-dialog-coin"
            >
              <div className="coin_popup">
                <div className="popup_coin_upper_section p-3">
                  <div className="row">
                    <div className="text-end" onClick={() => setShow(false)}><img src={cross} className="cross" onClick={() => setShow(false)}/></div>
                    <div className="text-start inter_text" style={{ fontSize: "20px", fontWeight: "500", marginTop: "-20px" }} onClick={() => setShow(false)}>Select a Token</div>
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"><img src={search} style={{ height: "18px" }} /></span>
                      <input type="text" class="form-control" onChange={(e) => setSearchParam(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                  </div>
                    <ul className="coin_pop mb-4 p-3 pt-3">
                      {asset.map((items) => {
                        if((items.symbol).toLowerCase().includes(searchParam.toLowerCase()) || (items.name).toLowerCase().includes(searchParam.toLowerCase())){
                        return (
                          <div
                            className="row coin_hover mb-3 pt-3 pb-3"
                            onClick={() => {
                              setShow(false);
                              setCoin(items.symbol);
                              setCoinImg(items.link);
                            }}
                          >
                            <div className="row text-start ps-4">
                              <li className="coin-list">
                                <img
                                  src={items.link}
                                  alt="coin"
                                  className="select_img"
                                />{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ps-3 pt-2 coin-name">{items.symbol}</span>
                                <span className='ps-3 pt-2 coin_subname'>{items.name}</span>
                              </li>
                            </div>
                          </div>
                        );
                      }
                      })}
                    </ul>
                </div>
            </Modal>
      <SmallFooter/>
    </>
  )
}

export default Club