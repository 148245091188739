import React from 'react';
import StaySafeImg from "../assets/stay-safe.svg";
const StaySafe = () => {
  return (
    <div className="stay-safe-wrapper">
      <div className="stay-safe">
        <img src={StaySafeImg} alt="Stay and Safe" className="img-fluid" />
      </div>
    </div>
  )
}

export default StaySafe;