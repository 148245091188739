import React, { useEffect,useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

const Instant_Signup = () => {

  const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [tokenQuery, setTokenQuery] = useState(
      searchParams.get("token") ? searchParams.get("token") : null
    );

    useEffect(() => {
      localStorage.setItem("token", tokenQuery);
      navigate("/dashboard");
    },[tokenQuery]);

  return (
    <div>
        
    </div>
  )
}

export default Instant_Signup