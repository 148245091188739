import React, { useState, useEffect } from "react";
import "./index.css";
import { fetchToken } from "../../Auth";
import copy from "./Group 223.svg";
import green_tick from "./Group 224.svg";
import info from "./Group 246.png";
import emoji from "./emoji.png";
import { refresh } from "aos";
import { Modal } from "react-bootstrap";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';

const Referral = () => {
  const [avt, setAvt] = useState();
  const [ref, setRef] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [refArr, setRefArr] = useState([]);
  const [emailAdd, setEmailAdd] = useState("");
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [loading, setLoading] = useState(true);

  const getAvt = () => {
    fetch("https://api.flitchcoin.com/api/dashboard", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          setAvt(res.avtar_im);
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllRef = () => {
    fetch("https://api.flitchcoin.com/api/referral/book", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          if (res.ref === "") {
            fetch("https://api.flitchcoin.com/api/users/me/items/", {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
              },
            })
              .then((result) =>
                result.json().then((res) => {
                  const rand = String(Math.floor(Math.random() * 100));
                  const user = res.username
                    .substring(0, res.username.indexOf("@"))
                    .concat(rand);
                  const data = JSON.stringify({
                    tag: user,
                  });
                  fetch("https://api.flitchcoin.com/api/referral/book", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${fetchToken()}`,
                    },
                    body: data,
                  })
                    .then((result) =>
                      result.json().then((res) => {
                        setRef(user);
                      })
                    )
                    .catch((err) => {
                      console.log(err);
                    });
                })
              )
              .catch((err) => {
                console.log(err);
              });
          } else {
            setRef(res.ref);
          }
          setRefArr(res.fr);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const sendRef = () => {
    var data = JSON.stringify({
      "email": emailAdd,
      "ref": ref
    })
    fetch('https://api.flitchcoin.com/api/quick/access/token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: data
    }).then((result) => result.json()
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          setShowA(true);
        } else {
          setShowB(true);
        }
      })).catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getAvt();
    getAllRef();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
      setShowA(false);
      setShowB(false);
    }, 3000);
  }, [isCopied, showA, showB]);

  return (
    <>
    <Helmet>
        <title>Refer Your Friends to Earn Rewards: Join Our Crypto Referral Program Today</title>
        <meta name="description" content="Our referral page offers a great opportunity to earn rewards by referring your friends to our platform. Simply share your referral link and earn rewards for every friend that signs up and starts investing with us. With our easy-to-use platform and a range of powerful investment tools and resources, you and your friends can take advantage of the exciting world of crypto investing. Join our referral program today and start earning rewards for sharing the benefits of crypto with your network." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {
        loading ?
          <Loader /> :
          <>
            <div className="referral-section">
              <div className="container">
                <h2 className="referral-main-heading">
                  Most Rewarding Referral Awaits
                  <img src={emoji} alt="" className="emoji-img" />
                </h2>
                <div className="row">
                  <div className="col-md-12">
                    <div className="referral-card">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="left-section">
                            <div className="upper-section d-flex align-items-center justify-content-between">
                              <div className="re-profile-img">
                                <img src={avt} className="avatar_big_3" alt="profile img" />
                              </div>
                              <div className="referral-text">
                                <div className="referral-id ud-referral-bg">
                                  <h3>Referral ID :</h3>
                                  <p>{ref}</p>
                                </div>
                                <div className="referral-link ud-referral-bg">
                                  <h3>Referral Link :</h3>
                                  <p>https://app.flitchcoin.com/register-step?ref={ref}</p>
                                  <a href="#">
                                    {isCopied ? <img src={green_tick} className="copy-img" /> : <img src={copy} className="copy-img" onClick={() => { navigator.clipboard.writeText(`https://app.flitchcoin.com/register-step?ref=${ref}`) }} />}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="lower-section">
                              <div className="ud-input-section">
                                <input
                                  type="email"
                                  className="email-input"
                                  placeholder="Enter Email address to invite..."
                                  value={emailAdd}
                                  onChange={(e) => setEmailAdd(e.target.value)}
                                />
                                <button type="button" className="invite-btn" onClick={sendRef}>
                                  Invite Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="right-section">
                            <div className="ud-box1">
                              <h3>You Get :</h3>
                              <h2 className="referral-gradient1">30%</h2>
                              <h3>Your Friend Get :</h3>
                              <h2 className="referral-gradient2">10%</h2>
                              <a href="#">
                                <img src={info} alt="" className="info-img" />
                              </a>
                            </div>
                            <div className="your-referrals-box">
                              <h3>Your Referrals</h3>
                              <div className={`${refArr.length <6 ? "enable_section":"enable_scroll_refer"}`}>
                                {
                                  refArr.length === 0 ?
                                    <>
                                      Start refering now to earn rewards.
                                    </> :
                                    <>
                                      {
                                        refArr.map((i, index) => {
                                          return (
                                            <>
                                              {
                                                <div className="row">
                                                  <div className="col-2">
                                                    {index + 1}.
                                                  </div>
                                                  <div className="col-5">
                                                    {i.name}
                                                  </div>
                                                  <div className="col-5">
                                                    {(i.tnx).substring(0, 8)}. . .
                                                  </div>
                                                </div>
                                              }
                                            </>
                                          )
                                        })
                                      }
                                    </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

      <Modal
        show={showA}
        onHide={() => setShowA(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Success !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">Your referral has been send successfully.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowA(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showB}
        onHide={() => setShowB(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error!</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">User already exists.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowB(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Referral;



