import React from "react";
import microsoftIcon from "../assets/icons/microsoft.svg";
import googleIcon from "../assets/icons/google.svg";
import appleIcon from "../assets/icons/apple.svg";
import { deleteUser, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { FIREBASE_CONFIG } from "../helper/constant";

const LoginSocialAccount = ({ socialAuthResponse, ...props}) => {
  const app = initializeApp(FIREBASE_CONFIG);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  const microsoftProvider = new OAuthProvider('microsoft.com')


  const handleGoogleAuth = (e) => {
    // e.nativeEvent.preventDefault();
    
    signInWithPopup(auth, googleProvider)
      .then((result) => {                
        socialAuthResponse(result);                        
      })
  }

  const handleAppleAuth = (e) => {
    // e.nativeEvent.preventDefault();
    signInWithPopup(auth, appleProvider)
    .then((result) => {
      socialAuthResponse(result, auth)
    })
  }

  const handleMicrosoftAuth = (e) => {    
    // e.nativeElement.preventDefault();
    signInWithPopup(auth, microsoftProvider)
    .then((result) => {
      socialAuthResponse(result)
    })
  }

  return (
    <>
      <div className="d-grid gap-2 d-md-block">
        <div className="mt-0 mb-30">
          <div className="row">
            <div className="col btn-col">
              <a href="#" className="btn btn-social-account btn-google btn-spl-block mb-3" onClick={e => handleGoogleAuth(e)}>
                <img src={googleIcon} alt="Google" className="login-icon"/>
                Google
              </a>
            </div>
            <div className="col btn-col">
              <a href="#" className="btn btn-social-account btn-apple btn-spl-block mb-3" onClick={e => handleAppleAuth(e)}>
                <img src={appleIcon} alt="Apple" className="login-icon"/>
                Apple 
              </a>
            </div>
            <div className="col btn-col">
              <a href="#" className="btn btn-social-account btn-microsoft btn-spl-block" onClick={e => handleMicrosoftAuth(e)}>
                <img src={microsoftIcon} alt="Microsoft" className="login-icon"/>
                Micorosoft 
              </a>
            </div>
          </div>  
        </div>                             
      </div>
    </>
  )
}

export default LoginSocialAccount;