import React, { useEffect, useState } from "react";
import { fetchToken } from "../../Auth";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import wave from "./66615-wave-blue-lines.gif";
import usdtimg from "./XTVCUSDT.svg";
import "./index.css";
import cross from "./Group 438.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import tick_gif from "./animation_tick.gif";
import LoadingButton from '../../elements/LoadingButton';

const Fields = (props) => {
  const [value, setValue] = useState();
  const [amount, setAmount] = useState();
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const [loadingButton,setLoadingButton] = useState(false);

  useEffect(() => {
    fetch("https://api.flitchcoin.com/api/agreement/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((res) =>
        res.json().then((result) => {
          if (result !== null) {
          } else {
            setShow(true);
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChangeValues = (e) => {
    if (e.target.value >= 7 && e.target.value <= 365) {
      setValue(e.target.value);
    }
  };

  const onChangeAmount = (e) => {
    if (e.target.value > 20) {
      setAmount(e.target.value);
    }
  };

  const [lt, setLt] = useState(0);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);

  const [priceInUsd, setPriceInUsd] = useState(0);

  const price = async () => {
    if (props.sym) {
      const sym = props.sym.toLowerCase();
      const query = new URLSearchParams({
        value: "0",
        from: "usd",
      }).toString();

      const ticker = sym;
      const resp = await fetch(
        `https://api.cryptapi.io/${ticker}/convert/?${query}`,
        { method: "GET" }
      );

      const data = await resp.text();
      setPriceInUsd(Number(JSON.parse(data).exchange_rate));
    }
  };

  const [amountToBeConverted, setAmountToBeConverted] = useState(0);

  const getLimit = () => {
    fetch("https://api.flitchcoin.com/api/account", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((res) =>
        res.json().then((result) => {
          const data = Object.values(result);
          for (let i = 0; i < data.length; i++) {
            if (data[i].asset === props.sym) {
              setLt(data[i].total - data[i].used);
            }
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  function sendOrder() {
    setLoadingButton(true);
    fetch("https://api.flitchcoin.com/api/agreement/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((res) =>
        res.json().then((result) => {
          if (result) {
            if (result.agreement) {
              if (amountToBeConverted <= 20) {
                setShowA(true);
                setLoadingButton(false);
              } else {
                var data = JSON.stringify({
                  coin: props.sym.toLowerCase(),
                  amount: amount / priceInUsd,
                  duration: value,
                });
                fetch("https://api.flitchcoin.com/api/order", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken()}`,
                  },
                  body: data,
                })
                  .then((res) =>
                    res.json().then((result) => {
                      if (result.status === 200) {
                        setShowSuccess(true);
                        setTimeout(() => {
                          navigate("/order");
                        }, 5000);
                       setLoadingButton(false);
                      }
                    })
                  )
                  .catch((err) => {
                    console.log(err);
                  });
              }
            } else {
              setShow(true);
              setLoadingButton(false);
            }
          } else {
            setShow(true);
            setLoadingButton(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if(amount === undefined){
      setAmountToBeConverted(0);
    }else{
    setAmountToBeConverted(amount * priceInUsd);
    }
  }, [amount]);

  const [repDate, setRepDate] = useState();
  const dateSet = () => {
    if (value) {
      const data = JSON.stringify({
        days: value,
      });
      fetch("https://api.flitchcoin.com/api/repay/date", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((res) =>
          res.json().then((result) => {
            setRepDate(result.substring(0, result.indexOf("T")));
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    dateSet();
  }, [value]);

  useEffect(() => {
    getLimit();
    price();
  }, [props.sym]);

  const termAccept = () => {
    const data = JSON.stringify({
      agreement: true,
    });
    fetch("https://api.flitchcoin.com/api/agreement/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
      body: data,
    })
      .then((res) =>
        res.json().then((result) => {
          setShow(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowA(false);
      setShowB(false);
    }, 10000);
  }, [showA, showB]);

  return (
    <>
      <p className="earn_subhead totalinacc-heading">
        Total in Account : ${" "}
        {(priceInUsd * lt).toFixed(4) === "NaN" ? (
          <>0</>
        ) : (
          <>{priceInUsd * lt}</>
        )}
      </p>
      <div className="col-12">
        <div className="row mt-1 mb-1">
          <div className="col-3">
            <button
              className="pb1"
              onClick={() => setAmount(priceInUsd * lt * 0.1)}
            >
              10%
            </button>
          </div>
          <div className="col-3">
            <button
              className="pb1"
              onClick={() => setAmount(priceInUsd * lt * 0.25)}
            >
              25%
            </button>
          </div>
          <div className="col-3">
            <button
              className="pb1"
              onClick={() => setAmount(priceInUsd * lt * 0.5)}
            >
              50%
            </button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setAmount(priceInUsd * lt)}>
              100%
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-3">
          <input
            type="range"
            class="range-style"
            name="range"
            id="range"
            min="0"
            max={priceInUsd * lt}
            step={0.01 * amount}
            value={Number(amount).toFixed(4)}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="input1 w-100">
          <input
            type="number"
            name="amount"
            placeholder="Amount:"
            className="input_earn w-100 orderpage-input"
            value={amount}
            onChange={onChangeAmount}
          />
        </div>
      </div>
      <div className="col-12 orderpage-marginc">
        <div className="row mb-4 mt-2">
          <div className="col-3 ">
            <button className="pb2" onClick={() => setValue(30)}>
              1 M
            </button>
          </div>
          <div className="col-3">
            <button className="pb2" onClick={() => setValue(90)}>
              3 M
            </button>
          </div>
          <div className="col-3">
            <button className="pb2" onClick={() => setValue(180)}>
              6 M
            </button>
          </div>
          <div className="col-3">
            <button className="pb2" onClick={() => setValue(365)}>
              1 Yr
            </button>
          </div>
        </div>
        <div className="row mb-2">
          <input
            type="range"
            class="range-style"
            name="range"
            id="range"
            value={value}
            min="7"
            max="365"
            onChange={onChangeValues}
          />
        </div>
        <div className="input1 w-100 mt-2">
          <input
            type="number"
            name="duration"
            placeholder="Duration:  Days"
            className="input_earn w-100  orderpage-input"
            value={value}
            onChange={onChangeValues}
          />
        </div>
        <div
          className="row earn_subhead  withdrawpara"
          style={{ fontSize: "14px" }}
        >
          <p>
            Withdraw Date :{" "}
            {repDate === undefined ? <>- - -</> : <>{repDate}</>}
          </p>
        </div>
      </div>
      <div className="row earn_subhead" style={{ fontSize: "14px" }}>
        <p>
          Total Payble Cost :{" "}
          {(amount / priceInUsd).toFixed(6) === "NaN" ? (
            <>0</>
          ) : (
            <>{(amount / priceInUsd).toFixed(6)} </>
          )}{" "}
          {props.sym}
        </p>
        <p>
          Total Received :{" "}
          {(amount * 0.75).toFixed(2) === "NaN" ? (
            <>0</>
          ) : (
            <>{(amount * 0.75).toFixed(2)}</>
          )}{" "}
          USD eq. Tentative
        </p>
      </div>
      <div className="row text-center pe-0" style={{ marginLeft: "-5px" }}>
      <LoadingButton loading={loadingButton} className='round-btn' id="give_margin">
        <button
          className="primary round-btn newpo_btn w-100 ud-place-orderbtn"
          onClick={sendOrder}
        >
          <div className="d-flex justify-content-center align-items-center btnitems p-0">
            <div className="flex-fill">
              <img src={usdtimg} className={`img_btn ms-4 ${Number(amount) === "NaN" ? "" : "ms-2"}`}></img>
            </div>
            <div className="flex-fill">
              {" "}
              Place Order $
              {Number(amount) === NaN ? (
                <>0</>
              ) : (
                <>{Number(amount).toFixed(2)}</>
              )}
            </div>
            <div className="flex-fill">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>
        </button>
        </LoadingButton>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <div className="row back">
          <div className="col-4 p_50 fs-2 fw-bold back__animation ">
            <p className="text-dark">Flitch Coin</p>
            <img src={wave} height="350px" width="200px" alt="wave" />
          </div>
          <div className="col-8 welcome__dialog">
            <div className="content_oveflow">
              <div className="modal__header">
                <h1 className="fw-bold modal-title">
                  Welcome to the Flitch Coin site
                </h1>
              </div>
              <div className="modal__body pressed">
                <p className="h6">
                  Please read this page before proceeding, as it explains
                  certain restrictions imposed by law on the distribution of
                  this information and the jurisdictions in which our products
                  and services are authorised to be offered or sold. It is your
                  responsibility to be aware of and to observe all applicable
                  laws and regulations of any relevant jurisdiction.
                </p>
                <p>
                  By entering this site you are agreeing that you have reviewed
                  and agreed to the terms contained herein, including any legal
                  or regulatory restrictions, the Client and Vendor Privacy
                  Notice, which explains how we collect, use, and disclose your
                  personal information and how it is protected, and the Cookie
                  Notice, which explains how we use cookies on our sites.
                </p>
                <p className="h5">Additional Information</p>
                <p>
                  Investment involves risks. Past performance is not a guide to
                  future performance.
                </p>
              </div>
              <div className="modal__footer">
                <Modal.Footer>
                  <button
                    variant="dark"
                    onClick={() => navigate("/dashboard")}
                    className="primary"
                  >
                    Decline
                  </button>
                  <button
                    onClick={termAccept}
                    className="primary bg-dark text-white accept"
                  >
                    Accept
                  </button>
                </Modal.Footer>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="coin_popup">
          <div className="popup_success p-3">
            <div className="row">
              <div className="text-end" onClick={() => setShowSuccess(false)}>
                <img
                  src={cross}
                  className="cross"
                  onClick={() => setShowSuccess(false)}
                />
              </div>
              <div className="text-center">
                <img src={tick_gif} style={{ height: "188px", width: "188px" }} />
              </div>
              <p className="order_success_text text-center">Order Successful</p>
              <p className="order_amount_text text-center">
                Order Amount - {Number(amount).toFixed(2)} USD
              </p>
              <p className="repayment_date_text text-center">
                Repayment Date : {repDate}
              </p>
              <div className="d-flex justify-content-center">
                <CountdownCircleTimer
                  size={40}
                  strokeWidth={3}
                  isPlaying
                  duration={5}
                  colors={"red"}
                  colorsTime={[5, 0]}
                >
                  {({ remainingTime }) => {
                    return (
                      <img
                        src={cross}
                        className="cross"
                        onClick={() => setShowSuccess(false)}
                      />
                    );
                  }}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showA}
        onHide={() => setShowA(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Please Deposit !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            Please Enter an amount greater than $20 to proceed with the order.
          </p>
          <div className="text-center">
            <button
              className="ps-3 pe-3 mt-3 mb-3 inter_text deposit_btn"
              onClick={() => navigate("/deposit_options")}
            >
              Deposit
            </button>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={10}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowA(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showB}
        onHide={() => setShowB(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Terms Declined !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            Please accept the terms to proceed with order.
          </p>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={10}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowB(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Fields;
