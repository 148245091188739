import React, { useEffect, useState } from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { SymbolInfo } from "react-ts-tradingview-widgets";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import { fetchToken } from "../../Auth";
import Fields from "./fields";
import SmallFooter from "../SmallFooter";
import dropdown from "./Polygon 2.svg";
import { Modal } from "react-bootstrap";
import OrderBook from "./orderBook";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import down_arr from "./Vector 45 (2).svg";
import { MiniChart } from "react-ts-tradingview-widgets";
import Loader from "../Loader";
// added these two
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Helmet } from 'react-helmet';

const PoolParticipant = (props) => {
  AOS.init({
    duration: 1000,
    once: false,
  });

  const [searchParams] = useSearchParams();
  const [coinQuery, setCoinQuery] = useState(
    searchParams.get("coin") ? searchParams.get("coin") : null
  );

  const [asset, setAsset] = useState([]);
  const [coin, setCoin] = useState("Select coin");
  const [coinImg, setCoinImg] = useState("");
  const [show, setShow] = useState(false);
  const [showA, setShowA] = useState(false);
  const [pageType, setPageType] = useState(true);
  const [loading, setLoading] = useState(true);
  // chnaged from here
  const [searchParam, setSearchParam] = useState("");

  function asset_link() {
    fetch("https://api.flitchcoin.com/api/dtl/asset", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    }).then((result) => {
      result.json().then((res) => {
        const data = Object.values(res);
        let tmpSymbol = [];
        data.map((items) => {
          tmpSymbol.push(items);
        });
        setAsset([...tmpSymbol]);
      });
    });
  }
  // to here

  const [checkPool, setCheckPool] = useState(false);

  const getInfo = () => {
    fetch("https://api.flitchcoin.com/api/users/me/items/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          if (res.is_pool) {
            setCheckPool(true);
          }
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const [coins, setCoins] = useState("");
  const [coinsNet, setCoinsNet] = useState("");

  const coin1 = () => {
    if (checkPool) {
      coin === "Select coin" ? setCoins("USDT") : setCoins(coin);
    } else {
      coin === "Select coin" ? setCoins("BTC") : setCoins(coin);
    }
  };

  const coinNet = () => {
    if (checkPool) {
      coin === "Select coin"
        ? setCoinsNet(`USDTUSDT`)
        : setCoinsNet(`${coin}USDT`);
    } else {
      coin === "Select coin"
        ? setCoinsNet(`BTCUSDT`)
        : setCoinsNet(`${coin}USDT`);
    }
  };

  useEffect(() => {
    coin1();
    coinNet();
  }, [coin, checkPool]);

  const [isActive, setIsActive] = useState(false);

  const become = (e) => {
    e.preventDefault();
      const data = JSON.stringify({
        is_pool: !checkPool,
      });
      fetch("https://api.flitchcoin.com/api/mode", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((result) =>
          result.json().then((res) => {
            setIsActive(true);
            setCheckPool(!checkPool);
            window.location.reload();
          })
        )
        .catch((err) => console.log(err));
  };

  const setTredingCoin = (i) => {
    setLoading(true);
    setCoin(i);
    setCoinImg(`https://s3-symbol-logo.tradingview.com/crypto/XTVC${i}.svg`);
    setLoading(false);
  };

  const changePageType = () => {
    if (pageType) {
      setPageType(false);
    } else {
      setPageType(true);
    }
  };

  useEffect(() => {
    asset_link();
    getInfo();
    coin1();
    coinNet();
    if (coinQuery !== null) {
      setCoin(coinQuery.toUpperCase());
      setCoinImg(`https://s3-symbol-logo.tradingview.com/crypto/XTVC${coinQuery.toUpperCase()}.svg`);
    }
  }, []);

  const LoaderFromChild = (val) => {
    if (val) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
        <title>Join Our Crypto Pool: Become a Participant and Earn Rewards</title>
        <meta name="description" content="Our pool participants page offers you the chance to join our crypto pool and earn rewards for contributing to our network. As a participant, you'll be able to mine cryptocurrencies more efficiently and receive a share of the rewards based on your contribution to the pool. Our user-friendly interface and powerful tools make it easy for anyone to become a pool participant, regardless of their level of expertise. Join us today and start earning rewards for your contributions to the crypto community!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {checkPool ? (
            <>
              <div className="row place_order_top place-order-top ps-5  ms-5 me-5  pe-0 align-items-center">
                <div className="col-lg-6">
                  Trending : &nbsp;&nbsp;
                  <b>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("USDT")}
                    >
                      {" "}
                      USDT&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("BUSD")}
                    >
                      {" "}
                      BUSD&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </div>
                <div className="col-lg-6 text-end pe-0">
                  <div className="plain_text margin_from_order">
                    <label
                      className="switch switch_orderpage db"
                      onClick={become}
                    >
                      <input
                        type="checkbox"
                        id="cc"
                        checked={isActive ? true : false}
                      />
                      <span className="slider slider_order design">
                        <span className="pool1 pool01 ps-3">
                          {checkPool ? <>Lend</> : <>Borrow</>}
                        </span>
                        <span className="part1 pool02">
                          {checkPool ? <>Borrow</> : <>Lend</>}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-lg-2" style={{ marginTop: "14rem" }}>
                  <p className="club_text">Len</p>
                  <p className="club_text">d</p>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-3 ms-4">
                  <div className="card special_card_earn place-order-card">
                    <div className="row order__body">
                      <h2 className="text-center text_design placeorder-heading mb-4 mt-1">
                        Place Order
                      </h2>
                      <p className="earn_subhead lending-para">
                        Lending Asset :
                      </p>
                      <div className="col-12  btn-group select-coin-btn">
                        <button
                          type="button"
                          className="select_coin_btn w-100 mb-4 selectcoin-btn"
                          onClick={() => setShow(true)}
                        >
                          <div className="row">
                            <div className="col-2">
                              {coinImg === "" ? (
                                <></>
                              ) : (
                                <>
                                  <img
                                    src={coinImg}
                                    className="select_img"
                                    alt="coin"
                                  />
                                </>
                              )}
                            </div>
                            <div className="col-8 text-center">
                              <b>{coin}</b>
                            </div>
                            <div className="col-2 text-center">
                              <img src={dropdown} alt="drop" />
                            </div>
                          </div>
                        </button>
                      </div>
                      <Fields sym={coin} loaderFromChild={LoaderFromChild} />
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3" style={{marginLeft: "120px"}}>
                  <div className="special_border orderpage-card2">
                    <MiniChart
                      colorTheme="light"
                      width="100%"
                      isTransparent
                      symbol={coins}
                    ></MiniChart>
                    <div id="marketview">
                      <SymbolInfo
                        colorTheme="light"
                        height={100}
                        width="98%"
                        symbol="USDT"
                        isTransparent
                      ></SymbolInfo>
                    </div>
                    <div classname="orderbook" style={{ marginTop: "-4.5rem" }}>
                      <OrderBook />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
              {/* changed form here */}
              <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
              >
                <div className="coin_popup">
                  <div className="popup_coin_upper_section p-3">
                    <div className="row">
                      <div className="text-end" onClick={() => setShow(false)}>
                        <img
                          src={cross}
                          className="cross"
                          onClick={() => setShow(false)}
                        />
                      </div>
                      <div
                        className="text-start inter_text"
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          marginTop: "-20px",
                        }}
                        onClick={() => setShow(false)}
                      >
                        Select a Token
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <img src={search} style={{ height: "18px" }} />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setSearchParam(e.target.value)}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                  <ul className="coin_pop mb-4 p-3 pt-3">
                    {asset.map((items) => {
                      if ((items.symbol).toLowerCase().includes(searchParam.toLowerCase()) || (items.name).toLowerCase().includes(searchParam.toLowerCase())) {
                        return (
                          <div
                            className="row coin_hover mb-3 pt-3 pb-3"
                            onClick={() => {
                              setShow(false);
                              setCoin(items.symbol);
                              setCoinImg(items.link);
                            }}
                          >
                            <div className="row text-start ps-4">
                              <li className="coin-list">
                                <img
                                  src={items.link}
                                  alt="coin"
                                  className="select_img"
                                />{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ps-3 pt-2 coin-name">
                                  {items.symbol}
                                </span>
                                <span className="ps-3 pt-2 coin_subname">
                                  {items.name}
                                </span>
                              </li>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </ul>
                </div>
              </Modal>
              {/* to here */}
              <SmallFooter />
            </>
          ) : (
            <>
              <div className="row place_order_top place-order-top ps-5  ms-5 me-5  pe-0 align-items-center">
                <div className="col-lg-4">
                  Trending : &nbsp;&nbsp;
                  <b>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("BTC")}
                    >
                      {" "}
                      BTC&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("ETH")}
                    >
                      {" "}
                      ETH&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("BNB")}
                    >
                      {" "}
                      BNB&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("SOL")}
                    >
                      {" "}
                      SOL&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span
                      className="trending"
                      onClick={() => setTredingCoin("XRP")}
                    >
                      {" "}
                      XRP&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </div>
                <div className="col-lg-5">
                  <button className="btn btn-dark w-75 round-btn pt-1" onClick={changePageType}>
                    <div className="row">
                      <div className="col-2"></div>
                      <div
                        className="col-8"
                        style={{ color: "#D9D9D9" }}
                      >
                        Show {pageType ? <>Advanced</> : <>Lightweight</>} Chart
                      </div>
                      <div className="col-2">
                        <img src={down_arr} />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-3 text-end  pe-0">
                  <div className="plain_text  margin_from_order">
                    <label
                      className="switch switch_orderpage db" //button
                      onClick={become}
                    >
                      <input
                        type="checkbox"
                        id="cc"
                        checked={isActive ? true : false}
                      />
                      <span className="slider slider_order design">
                        <span className="pool1 pool01">
                          {checkPool ? <>Lend</> : <>Borrow</>}
                        </span>
                        <span className="part1 pool02">
                          {checkPool ? <>Borrow</> : <>Lend</>}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {pageType ? (
                <>
                  <div className="row mt-3 mb-3" id="main-row">
                    <div className="col-lg-2" id="side-borrow" style={{ marginTop: "10rem" }}>
                      <p className="club_text ct1">Bor</p>
                      <p className="club_text">row </p>
                    </div>
                    <div className="col-lg-3" id="order-place-card" style={{ marginLeft: "10rem" }}>
                      <div className="card special_card_earn place-order-card">
                        <div className="row order__body">
                          <h2 className="text-center orderpage-gradient1 placeorder-heading mb-4 mt-1">
                            Place Order
                          </h2>
                          <p className="earn_subhead">Lending Asset :</p>
                          <div className="col-12  btn-group select-coin-btn">
                            <button
                              type="button"
                              className="select_coin_btn w-100 mb-4 selectcoin-btn"
                              onClick={() => setShow(true)}
                            >
                              <div className="row">
                                <div className="col-2">
                                  {coinImg === "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <img
                                        src={coinImg}
                                        className="select_img"
                                        alt="coin"
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="col-8 text-center">
                                  <b>{coin}</b>
                                </div>
                                <div className="col-2 text-center">
                                  <img src={dropdown} alt="drop" />
                                </div>
                              </div>
                            </button>
                          </div>
                          <Fields
                            sym={coin}
                            loaderFromChild={LoaderFromChild}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3" id="order-card-2">
                      <div className="special_border orderpage-card2">
                        <MiniChart
                          colorTheme="light"
                          width="100%"
                          isTransparent
                          symbol={coins}
                        ></MiniChart>
                        <div id="marketview">
                          <SymbolInfo
                            colorTheme="light"
                            height={100}
                            width="98%"
                            symbol={coins}
                            isTransparent
                          ></SymbolInfo>
                        </div>
                        <div style={{ marginTop: "-5rem" }}>
                          <OrderBook />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div
                      className="ps-5 col-xl-8 mt-5"
                      id="rtchart"
                    >
                      <AdvancedRealTimeChart
                        theme="light"
                        autosize
                        symbol={coinsNet}
                      ></AdvancedRealTimeChart>
                    </div>
                    <div className="col-xl-4 ps-2 pe-5">
                      <div className="container">
                        <div className="row pt-5 pb-1">
                          <div className="card special_card_earn pt-4 p-4">
                            <div className="row order__body">
                              <h2 className="text-center text_design mb-3 placeorder-heading">
                                Place Order
                              </h2>
                              <div className="col-12  btn-group select-coin-btn">
                                <button
                                  type="button"
                                  className="select_coin_btn w-100 mb-4"
                                  onClick={() => setShow(true)}
                                >
                                  <div className="row">
                                    <div className="col-2">
                                      {coinImg === "" ? (
                                        <></>
                                      ) : (
                                        <>
                                          <img
                                            src={coinImg}
                                            className="select_img"
                                            alt="coin"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="col-8 text-center">
                                      <b>{coin}</b>
                                    </div>
                                    <div className="col-2 text-center">
                                      <img src={dropdown} alt="drop" />
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <Fields
                                sym={coin}
                                loaderFromChild={LoaderFromChild}
                              />
                            </div>
                          </div>
                          <div id="orderbook" className="pt-4">
                            <OrderBook />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-8">
                      <div
                        className="row card special_card_earn mt-4 mb-4 ms-5"
                        id="chart"
                      >
                        <CryptoCurrencyMarket
                          colorTheme="light"
                          width="100%"
                          height={710}
                          isTransparent
                        ></CryptoCurrencyMarket>
                      </div>
                    </div>
                    <div className="col-xl-4 mt-3">
                      <div className="card special_card_earn">
                        <div
                          className="row mt-4 ms-3"
                          style={{ maxHeight: "460px" }}
                          id="techanalysis"
                        >
                          <TechnicalAnalysis
                            colorTheme="light"
                            height={400}
                            width="100%"
                            symbol={coinsNet}
                            isTransparent
                          ></TechnicalAnalysis>
                        </div>
                        <div className="row mt-4 ms-5" id="marketview">
                          <SymbolInfo
                            colorTheme="light"
                            height="70%"
                            width="100%"
                            symbol={coins}
                            isTransparent
                          ></SymbolInfo>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <SmallFooter />
              {/* changed form here */}
              <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
              >
                <div className="coin_popup">
                  <div className="popup_coin_upper_section p-3">
                    <div className="row">
                      <div className="text-end" onClick={() => setShow(false)}>
                        <img
                          src={cross}
                          className="cross"
                          onClick={() => setShow(false)}
                        />
                      </div>
                      <div
                        className="text-start inter_text"
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          marginTop: "-20px",
                        }}
                        onClick={() => setShow(false)}
                      >
                        Select a Token
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <img src={search} style={{ height: "18px" }} />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setSearchParam(e.target.value)}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                  <ul className="coin_pop mb-4 p-3 pt-3">
                    {asset.map((items) => {
                      if ((items.symbol).toLowerCase().includes(searchParam.toLowerCase()) || (items.name).toLowerCase().includes(searchParam.toLowerCase())) {
                        return (
                          <div
                            className="row coin_hover mb-3 pt-3 pb-3"
                            onClick={() => {
                              setShow(false);
                              setCoin(items.symbol);
                              setCoinImg(items.link);
                            }}
                          >
                            <div className="row text-start ps-4">
                              <li className="coin-list">
                                <img
                                  src={items.link}
                                  alt="coin"
                                  className="select_img"
                                />{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ps-3 pt-2 coin-name">
                                  {items.symbol}
                                </span>
                                <span className="ps-3 pt-2 coin_subname">
                                  {items.name}
                                </span>
                              </li>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </ul>
                </div>
              </Modal>
              {/* to here */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PoolParticipant;
