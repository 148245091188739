import React, { useState, useEffect } from "react";
import StaySafe from "../../components/StaySafe";
import LoginSocialAccount from "../../components/LoginSocialAccount";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginToken, reset, signupUser, userLogin, verifyEmail } from "../../Feature/Auth/authSlice";
import { checkUser, getRandomPassword } from "../../helper/api";
import { updatePassword } from "firebase/auth";
import CryptoJS from "crypto-js";
import cross from "./Group 438.svg";
import { Modal } from "react-bootstrap";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Checkbox from "../../FromComponents/Checkbox";
import { Helmet } from 'react-helmet';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { loginString, verifyString, userExistStatus } = useSelector(
    (state) => state.auth
  );

  const { userToken, user } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const [otpQuery, setOtpQuery] = useState(
    searchParams.get("otp") ? searchParams.get("otp") : null
  );
  const [memoQuery, setMemoQuery] = useState(
    searchParams.get("memo") ? searchParams.get("memo") : null
  );
  const [refQuery, setRefQuery] = useState(
    searchParams.get("ref") ? searchParams.get("ref") : null
  );

  const [show, setShow] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [otp, setOtp] = useState("");
  const [signType, setSignType] = useState("");
  const [page, setPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState();
  const [termChecked, setTermChecked] = useState(false);

  const otpHandler = (e) => {
    debugger
    setLoading(true);
    let data;
    if (formData.username === "") {
      data = {
        otp: String(otp),
        add: String(loginString.msg),
        link: true,
      };
    } else {
      data = {
        otp: String(otp),
        add: String(loginString.msg),
        link: false,
      };
    }
    e.preventDefault();
    dispatch(verifyEmail(data));
    setLoading(false);
    if (verifyString === "Wrong OTP") {
      setShowOtp(true);
      setOtp("");
      setTimeout(() => {
        setShowOtp(false);
      }, 3000);
    }

  };
  const nextHandler = (e) => {
    const { username, password, fullName, type } = formData;
    setLoading(true);
    if (e) {
      e.preventDefault();
      setLoading(false);
    }
    if (username === "" || password === "" || fullName === "") {
      setLoading(false);
      setShowA(true);
    } else {
      setLoading(true);
      setSignType("participant");
      type = "participant";
      if (username === "" || password === "" || fullName === "" || type === "") {
        setShowA(true);
      } else {
        dispatch(signupUser({ formData, type }));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (userToken?.access_token) {
      dispatch(userLogin());
    }
    if (user && user?.username) {
      navigate("/dashboard");
    }
  }, [userToken, user])

  const handleChange = () => {
    setTermChecked(!termChecked);
  }

  useEffect(() => {
    var fun = (text) => {
      text = text.trim();
      var newString = "";
      for (const char of text) {
        newString += char === " " ? "+" : char;
      }
      return newString;
    };

    if (otpQuery && memoQuery) {
      var str = memoQuery;
      var pos = [];
      for (var i = 0; i < 50; i++) {
        if (str[i] === " ") {
          pos.push(i);
        }
      }

      const strMemo = fun(memoQuery);
      const strOtp = fun(otpQuery);
      var decryptedBytesOtp = CryptoJS.AES.decrypt(strOtp, process.env.REACT_AES_KEY);
      var decryptedOtp = decryptedBytesOtp.toString(CryptoJS.enc.Utf8);
      var decryptedBytesMemo = CryptoJS.AES.decrypt(strMemo, process.env.REACT_AES_KEY);
      var decryptedMemo = decryptedBytesMemo.toString(CryptoJS.enc.Utf8);
      let data = {
        otp: String(decryptedOtp),
        add: String(decryptedMemo),
        link: false,
      };
      navigate("/login");

      dispatch(verifyEmail(data));
      if (verifyString) {
        if (verifyString?.status === 200) {
          navigate("/login");
        }
      }
    }
    if (userExistStatus === true) {
      setShowB(true);
      setTimeout(() => {
        dispatch(reset());
        navigate("/login");
      }, 3000);
    } else if (userExistStatus === false && userExistStatus !== null) {
      setLoading(false);
      setShow(true);
    }
    if (verifyString === "Wrong OTP") {
      setShowOtp(true);
      setOtp("");
      setTimeout(() => {
        setShowOtp(false);
      }, 3000);
    }
    if (verifyString) {
      if (verifyString?.status === 200) {
        dispatch(loginToken(formData))
        // navigate("/login");
      }
    }
  }, [userExistStatus, otpQuery, memoQuery, verifyString]);


  const handleSocialSign = async (response, auth) => {
    debugger
    const { user } = response
    if (user && user.email) {
      const userResponse = await checkUser({ emailid: user.email, fa2: true });
      if (userResponse) {
        if (userResponse.fa2) {
        } else {
          if (userResponse.email) {
            const { reloadUserInfo: { passwordHash } } = user;
            const data = {
              email: userResponse.email,
              password: passwordHash
            }
            dispatch(loginToken(data))
          }
        }
        if (userResponse.detail) {
          getRandomPassword().then((password) => {
            updatePassword(user, password)
              .then((result) => {
                // Updated password
                const { reloadUserInfo: { passwordHash } } = user;
                const type = "participant";
                const formData = {
                  username: user.email,
                  password: passwordHash,
                  fullName: user.displayName,
                  type
                };

                setFormData(formData)
                dispatch(signupUser({ formData, type }));
              }).catch((e) => {
                // error 
              })
          })
        }
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setShowA(false);
      setShowB(false);
    }, 3000)
  }, [showA, showB]);

  const [isRipple, setIsRipple]= useState(true);
  const [coords , setCoords]=useState({x:-1,y:-1});

  
  // const handleClick = (e) => {

  //   setCoords({
  //     x:/* e.clientX - e.target.offsetLeft*/ 50 ,
  //     y: /*e.clientY - e.target.offsetTop*/ 50,
  //   });
  //   // onClick;
  // };
  
  useEffect(()=> {
    if(coords.x=== -1 && coords.y===-1){
      setIsRipple(true);

      setTimeout(()=> setIsRipple(false),1000);
    }
    else
    {
      setIsRipple(false);
    }
  },[coords]);

    const handleclick = (e) => {
      setCoords({
            x: -1 ,
            y: -1,
          });
    };


  return (
    <>
    <Helmet>
        <title>Register for Your Crypto Account: Join the Future of Finance Today</title>
        <meta name="description" content="Our register page offers a simple and straightforward way to create your own crypto account and start investing in the future of finance. With our easy-to-use platform and advanced security measures, you can trust us to keep your funds safe and secure while you grow your investments. Whether you're a seasoned crypto pro or just getting started, our register page has everything you need to get started on your crypto journey. Join us today and start investing in the future of finance." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      <div className="login-2-page">
        <div className="container">
          <div className="row login-row">
            <div className="col-xs-12 col-sm-12 col-lg-6">
              <div>
                <div>
                  <div>
                    <h4 className="r-top-title">
                      <span className="a-t-s">Lend. Borrow. Earn</span>&nbsp;
                      with Flitchcoin
                    </h4>
                  </div>

                  <h3 className="r-main-title">Create Personal Account</h3>
                </div>
                <div className="d-grid">
                  <Link  to={'/register-step'} className="block btn btn-spl-primary">
                  {isRipple ? <span className="Ripple" /> : ""}
                  Signup with Email
                  </Link>
                </div>
              </div>
{/* 
              <div className="or-continue-wrapper">
                <div className="or-c-lr">
                  <div className="or-continue-box">
                    <div className="or-continue-content">
                       <span>
                         or continue with
                       </span>
                     </div>
                  </div>
               </div>
               </div>
                */}
              <div>
                
                 {/* <LoginSocialAccount socialAuthResponse={handleSocialSign} /> */}
                <div className="form-group mb-3">
                  <Checkbox checked={termChecked} onChange={handleChange} />
                  <span className="ml-1">
                    I have read and agree to Flitchcoin's
                    <a href="#">Terms of Service</a>
                    and
                    <a href="#">Privacy Policy</a>
                  </span>
                  {
                    !termChecked && (
                      <div className="invalid-feedback">
                        <span>! Please agree terms.</span>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="ats-content">
                <p className="mb-0 text-center">
                  I don’t have Flitchcoin account
                  &nbsp;<Link className="a-t-s a-link" to={'/register-step'}>
                    advance to Signup
                  </Link>
                </p>

              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-6 login-a-center">
              <StaySafe />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} className="modal-dialog-popup">
        <div className="signup_popup p-5 pt-0">
          <div className="text-end pt-2 signup_cross" onClick={() => setShow(false)}>&#9747;</div>
          <p className="pt-4">Please check your email for a registration link or OTP. You can register any way by clicking on the <span className="text_design">link in E-mail </span>or <span className="text_design">by entering OTP </span>in the designated column. If you didn't receive an email, you can click I didn't receive any email.</p>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="signup_popup mt-5 p-4">
                <b>Enter OTP</b><br />
                <input
                  type="text"
                  className="w-50 signup_input mt-3"
                  placeholder="x x x x"
                  onChange={(e) => setOtp(e.target.value)}
                  name="otp"
                  value={otp}
                />
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6 mt-4">
                    <button className="ok_btn_login w-100" onClick={(e) => otpHandler(e)}>
                      Proceed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;›
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 text-start">
                  <button className="round-btn btn-dark mt-4 ps-4 pe-4" onClick={(e) => nextHandler(e)}>
                    I didn't receive Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;›
                  </button>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showA}
        onHide={() => setShowA(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Unfilled !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">Please enter the details to proceed.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowA(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showB}
        onHide={() => setShowB(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">User already exist. <br />Taking you back to Login Page.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowB(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
    </>
  )
}


export default Login;
