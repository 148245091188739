import Layout from "./layouts";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { defaultType, userLogin } from "./Feature/Auth/authSlice";
import "./app.scss";
import frontimg from "./logo192.png";
import "./Styles/main.scss";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  const { selectedType } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(defaultType("accept"));
    dispatch(userLogin());
  }, []);

  return (

    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
         {
           "@context": "https://schema.org",
           "@type": "WebPage",
           "name": "Flitchcoin",
           "description": "Flitchcoin:way to make passive income equivalent to profitable traders with investment products, borrow, lend, stake & earn via our Risk Distribution infrastructure",
           "url": "https://www.flitchcoin.com",
           "image": ${frontimg}
         }
          <meta property="og:image" content={frontimg} />
          <link rel="alternate" hreflang="en-US" href="https://www.example.com/en-us" />
          <link rel="alternate" hreflang="en-UK" href="https://www.example.com/en-uk" />
          <link rel="alternate" hreflang="en-default" href="https://www.example.com/en-default" />
          <title>Flitchcoin</title>
          <meta name="description" content="Flitchcoin:way to make passive income equivalent to profitable traders with investment products, borrow, lend, stake & earn via our Risk Distribution infrastructure" />
          <link rel="canonical" href="https://www.example.com" />
       `}
        </script>
      </Helmet>

      <Layout />
    </div>
  );
};

export default App;
