import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sign from "./Group 191.svg";
import { useSelector } from "react-redux";

const Lend = () => {

    const { selectedType } = useSelector((state) => state.auth);

    const [homeState, setHomeState] = useState("Lend");
    const navigate = useNavigate();

    var r = ["Lend", "Borrow", "Earn"];
    const rep = (i) => {
        setHomeState(r[i]);
    }

    useEffect(() => {
        for (let i = 0; i < 100000; i++) {
            const set = setTimeout(function () {
                rep(i % 3);
            }, 2000 * i)
        }
    }, []);

    const onClick = () => {
        navigate("/sign-up")
    }

    return (
        <>

            <div className="texthome_box"><p className="text_home text-center">LEND. BORROW. EARN</p></div>
            <div style={{ background: "#000" }} className="position-relative pb-2 pb-xl-0">
                <div className="row container mx-auto">
                    <div className="col-lg-6 px-1 py-3 d-flex flex-column justify-content-center" >
                        <h1 className="pt-3 lend_text">KNOW THE FLITCHCOIN ADVANTAGE</h1>
                        <p className="pt-3 api_text lend_details">
                            Unleash the full potential of your cryptocurrency portfolio with Flitchcoin's lending and Borrowing feature. Get access to funds for trading or generate passive income with your digital assets.
                        </p>
                        {
                            selectedType === "accept" || selectedType === undefined || selectedType === null ?
                                <>
                                    <div className="row py-3">
                                        <div className="col-xl-6 py-3">
                                            <button
                                                type="button"
                                                className="sign_up_btn_dark w-100"
                                                onClick={onClick}
                                            >
                                                <div className="row hover_sign">
                                                    <div className="col-3"><img src={sign} style={{ height: "38px", width: "38px" }} alt="lend" /></div>
                                                    <div className="col-6 signup_home">Sign-up</div>
                                                    <div className="col-3 pt-1"><img src="/assets/arrow.svg" alt="" /></div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-xl-6 py-3">
                                            {homeState === "Lend" ?
                                                <>
                                                    <button
                                                        type="button"
                                                        style={{ fontSize: "24px" }}
                                                        className="button w-100 special_btn_home home_card_lend"
                                                        onClick={() => navigate("/login")}
                                                    >
                                                        <p className=" mb-0">{homeState}</p>
                                                    </button>
                                                </> :
                                                <>
                                                    {
                                                        homeState === "Borrow" ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    style={{ fontSize: "24px" }}
                                                                    className="button w-100 special_btn_home home_card_borrow"
                                                                    onClick={() => navigate("/login")}
                                                                >
                                                                    <p className=" mb-0">{homeState}</p>
                                                                </button>
                                                            </> :
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    style={{ fontSize: "24px" }}
                                                                    className="button w-100 special_btn_home home_card_earn"
                                                                    onClick={() => navigate("/login")}
                                                                >
                                                                    <p className=" mb-0">{homeState}</p>
                                                                </button>
                                                            </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="col-xl-6 ms-4 mt-5 mb-1">
                                            {homeState === "Lend" ?
                                                <>
                                                    <button
                                                        type="button"
                                                        style={{ fontSize: "24px" }}
                                                        className="button w-100 special_btn_home home_card_lend"
                                                        onClick={() => navigate("/dashboard")}
                                                    >
                                                        <p className=" mb-0">{homeState}</p>
                                                    </button>
                                                </> :
                                                <>
                                                    {
                                                        homeState === "Borrow" ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    style={{ fontSize: "24px" }}
                                                                    className="button w-100 special_btn_home home_card_borrow"
                                                                    onClick={() => navigate("/dashboard")}
                                                                >
                                                                    <p className=" mb-0">{homeState}</p>
                                                                </button>
                                                            </> :
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    style={{ fontSize: "24px" }}
                                                                    className="button w-100 special_btn_home home_card_earn"
                                                                    onClick={() => navigate("/dashboard")}
                                                                >
                                                                    <p className=" mb-0">{homeState}</p>
                                                                </button>
                                                            </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center align-items-center lend_div">
                        <img src="https://flitchcoin.s3.eu-west-2.amazonaws.com/sign.webp" alt="top" className="lend_img" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Lend