import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../Feature/Auth/authSlice";
import { fetchToken } from "../../Auth";
import cross from "./cross_x.png";
import dropdown from "./Polygon 8.png";
import lock from "./Group 191 (1).svg";
import arrow from "./left_arr.svg";
import open_lock from "./Group 195.svg";
import { MdArrowForwardIos } from "react-icons/md";

function Header() {
  const { selectedType } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    type: null
  });
  const { type } = formData;

  function rel_login() {
    setFormData((prevData) => ({
      ...prevData,
      type: null
    }));
  };

  const onClick = () => {
    open();
    rel_login();
    dispatch(logOutUser());
    navigate("/login");
  }
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');

  const getInfo = () => {
    fetch('https://api.flitchcoin.com/api/users/me/items/', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${fetchToken()}`
      }
    }).then((result) => result.json()
      .then(res => {
        setUsername(res.username);
        if ((res.name).indexOf(" ") > 0) {
          setFullName((res.name).substring(0, (res.name).indexOf(" ")));
        } else {
          setFullName((res.name));
        }
      })).catch((err) => {
        console.log(err);
      })
  };
  const [avt, setAvt] = useState();

  const change = () => {
    fetch('https://api.flitchcoin.com/api/dashboard', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${fetchToken()}`
      }
    }).then((result) => result.json()
      .then(res => {
        setAvt(res.avtar_im);
      })).catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    getInfo();
    change();
  }, [selectedType]);

  const [isActive, setIsActive] = useState(false);

  function open() {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  function function1() {
    document.getElementById("wa").style.width = "200px";
    document.getElementById("wa").style.opacity = "1";
  }

  function function2() {
    document.getElementById("wa").style.width = "0";
    document.getElementById("wa").style.opacity = "0";
  }

  function animation1() {

    document.getElementById("id_lock").classList.add('logani1');
    document.getElementById("id_log").classList.add('logani2');
    document.getElementById("id_arrow").classList.add('logani2');
    document.getElementById("open_id").classList.add('arrow_ani');

  }
  function unanimate() {

    document.getElementById("id_lock").classList.remove('logani1');
    document.getElementById("id_log").classList.remove('logani2');
    document.getElementById("id_arrow").classList.remove('logani2');
    document.getElementById("open_id").classList.remove('arrow_ani');

  }

  const onNavClick = () => {
    if (isActive) {
      setIsActive(false);
    }
  }

  useEffect(() => {
    if(selectedType === "accept" || selectedType === undefined || selectedType === null){
      
    }else{
      setInterval(() => {
        if(!localStorage.getItem("token")){
          rel_login();
          dispatch(logOutUser());
          navigate("/login");
        }
      },3000)
    }
  },[]);
  


  return (
    <>
      {
        selectedType === "error" ?
          <>
          </> : <>
            <div className="ending_margin">
              <nav className="navbar mt-4" id="navb">
                {/* changed this line only */}
                <div className={`container container_margin_remove ${selectedType === "accept" || selectedType === undefined || selectedType === null ? "" : "mx-0"}`}>
                  <div className="containerinner ">
                    {
                      selectedType === "accept" || selectedType === undefined || selectedType === null ?
                        <>
                          <Link to="/" className="text-dark p-1" >
                            <div className="i"></div>
                            <div className="i"></div>
                          </Link>
                          <Link to="/login" type="button" onClick={animation1} onMouseLeave={unanimate} className="text_c8 me-1 login_container " >
                            <i id="img_id"><img src={lock} id="id_lock" className="lock_style " alt="lock" /></i>
                            <i id="id_log" className=" icons_login ms-3 me-5">&nbsp; Login</i>
                            {/* <img id="id_arrow" src="/assets/arrowBlack.svg" className="pe-4" alt="arrow" /> */}
                            <span className="login-arrow" id="id_arrow">
                              <MdArrowForwardIos />
                              </span>
                            <img src={open_lock} id="open_id" className="arrow_style" alt="arrow" />
                          </Link>
                        </> :
                        <></>
                    }
                  </div>
                  <div className="contain" id="mainlist">
                    {
                      selectedType === "pool" || selectedType === "participant" ?
                        <>
                          {
                            !isActive ?
                              <i className="fa-solid fa-angle-left brand" onClick={() => navigate(-1)}></i>
                              : <></>
                          }
                          {
                            isActive ?
                              <>
                                <img src={cross} alt="avatar" className="logo" id="menubtn" onClick={open} />
                              </> :
                              <>
                                <img src={avt} alt="avatar" className="logo" id="menubtn" onClick={open} onMouseEnter={function1} onMouseLeave={function2} /><span className="avt" id="wa"><span className="tx pe-4"><img src={dropdown} alt="name" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fullName}</span></span>                              </>
                          }

                          <div className="headinglist">
                            <Link to="/club" className={isActive ? "margin_start nav_hover padding_start text-dark" : "margin_start nav_hover padding_start text_c8"} onClick={onNavClick}>7%Club</Link>
                            <Link to="/dashboard" className={isActive ? "margin_bw nav_hover text-dark" : "margin_bw nav_hover text_c8"} onClick={onNavClick}>Dashboard</Link>
                            <Link to="/" className={isActive ? "margin_bw text-dark" : "margin_bw text_c8"} onClick={onNavClick}><div className="i"></div>
                              <div className="i"></div></Link>
                            <Link to="/place_order" className={isActive ? "ps-5 margin_bw nav_hover text-dark" : "ps-5 margin_bw nav_hover text_c8"} onClick={onNavClick}>Order</Link>
                            <Link to="/order" className={isActive ? "margin_bw nav_hover text-dark" : "margin_bw nav_hover text_c8"} onClick={onNavClick}>History</Link></div>
                        </> :
                        <>
                        </>
                    }
                  </div>
                </div>

                <div className={isActive ? "menu display_block" : "menu display_none"} id="menu-box">
                  <div className="subcontainer">
                    <div className="hamburger">
                      <div className="containerinner ">
                        {
                          selectedType === "accept" || selectedType === undefined || selectedType === null ?
                            <>
                              <Link to="/" className="text_dark ms-4 p-1" ><div className="i"></div>
                                <div className="i"></div></Link>
                              <Link to="/login" type="button" onClick={animation1} onMouseLeave={unanimate} className="text_c8 me-1 login_container " ><i id="img_id"><img src={lock} id="id_lock" className="lock_style " alt="lock" /></i><i id="id_log" className=" icons_login ">&nbsp; Login</i>
                              <span className="login-arrow" id="id_arrow">
                              <MdArrowForwardIos />
                              </span>
                              <img src={open_lock} id="open_id" className="arrow_style" alt="arrow" /></Link>                    </> :
                            <></>
                        }
                      </div>
                      <div className="contain ms-5" id="mainmenu">
                        {
                          selectedType === "pool" || selectedType === "participant" ?
                            <>
                              {
                                !isActive ?
                                  <i className="fa-solid fa-angle-left brand" onClick={() => navigate(-1)}></i>
                                  : <></>
                              }
                              {
                                isActive ?
                                  <>
                                    <img src={cross} alt="avatar" className="logo" id="menubtn" onClick={open} />
                                  </> :
                                  <>
                                    <img src={avt} alt="avatar" className="logo" id="menubtn" onClick={open} />
                                  </>
                              }
                              <img src={avt} alt="avatar" className="logo" id="menubtn" onClick={open} />

                              <ul className="dvlist">
                                <li><Link to="/club" className={isActive ? "margin_bw pb-2 text-dark" : "margin_bw pb-2 text_c8"} onClick={open}>7%Club</Link></li>
                                <li><Link to="/dashboard" className={isActive ? "margin_bw pb-2 text-dark" : "margin_bw pb-2 text_c8"} onClick={open}>Dashboard</Link></li>
                                <li><Link to="/" className={isActive ? "margin_bw pb-2 text-dark" : "margin_bw pb-2 text_c8"} onClick={open} >Flitch Coin</Link></li>
                                <li><Link to="/place_order" className={isActive ? "margin_bw pb-2 text-dark" : "margin_bw pb-2 text_c8"} onClick={open}>Order</Link></li>
                                <li><Link to="/order" className={isActive ? "margin_bw pb-2 text-dark" : "margin_bw pb-2 text_c8"} onClick={open}>History</Link></li>
                              </ul>
                            </> :
                            <>
                            </>
                        }
                      </div>
                    </div>
                    <br></br>
                    <img className="btc mt-5" id="navavt" src={avt} alt="avatar"></img><br></br>
                    <br></br>
                    <p className="para" id="navusername">{username}</p>
                    <br></br>
                    <button className="manage ps-5 pe-5 profmanage" onClick={() => {
                      navigate("/profile");
                      open();
                    }}>Manage your Account</button>
                    <br></br><br></br>

                    <ul className="submenu">
                      <li><Link to="/api" className="text-dark nav_on_hover" onClick={open}>Api</Link></li>
                      <li><Link to="/referral" className="text-dark nav_on_hover" onClick={open}>Referral</Link></li>
                      <li><Link to="/" className="text-dark nav_on_hover" onClick={open}>Reports</Link></li>
                      <li><Link to="/" className="text-dark nav_on_hover" onClick={open}>Help</Link></li>
                      <li><Link to="/" className="text-dark" onClick={onClick}><button className="manage text-danger ps-5 pe-5 sout" style={{ width: "20%" }} onClick={() => navigate("/login")}>
                        Sign Out
                      </button></Link></li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </>
      }
    </>
  );
}

export default Header;