import React,{useState} from 'react';
import { fetchToken } from "../../Auth";
import transakSDK from '@transak/transak-sdk';
import { Helmet } from 'react-helmet';

const Ramp = () => {

    const [formData, setFormData] = useState({
        cryptoCurrencyCode: "",
        fiatAmount: "",
      })
    
      const { cryptoCurrencyCode, fiatAmount} = formData;
    
      const onChange = (e) => {
        setFormData((prevData) => ({
          ...prevData,
          [e.target.name]: e.target.value,
        }));
      };
    
      const onSubmit = (e) => {
        var data = JSON.stringify({
          "cryptoCurrencyCode": cryptoCurrencyCode,
          "network": cryptoCurrencyCode,
          "fiatAmount": Number(fiatAmount),
        });
        fetch(`https://api.flitchcoin.com/api/transak/${e === "BUY" ? "on":"off"}ramp/order`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${fetchToken()}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: data
        }).then(res => res.json())
          .then((result) => {
            buy({amount : result.FiatAmount,email: result.email,sym: result.cryptoCurrencyCode,net: result.network,add: result.walletAddress,order: result.partnerOrderId,cust: result.partnerCustomerId,type:e});
          }).catch((err) => {
            console.log(err);
          })
      };

      const buy = (e) => {
        let transak = new transakSDK({
            apiKey: '2a813961-cdff-4403-9392-0abcdac79454',
            environment: 'PRODUCTION',
            walletAddress: e.add,
            fiatAmount: e.amount,
            email: e.email,
            cryptoCurrencyCode: e.sym,
            network: e.net,
            partnerOrderId: e.order,
            partnerCustomerId: e.cust,
            widgetHeight: "95%",
            redirectURL: "https://app.flitchcoin.com/dashboard",
            disableWalletAddressForm: true,
            isAutoFillUserData: true,
            isFeeCalculationHidden: true,
            productsAvailed: e.type,
            hideMenu: true
        });

        transak.init();
        transak.on(transak.ALL_EVENTS, (data) => {
            console.log(data);
        });
        transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
            transak.close();
        });
        transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            console.log(orderData);
            transak.close();
        });
      }

    return (
        <div className='container'>
          <Helmet>
        <title>Buy Crypto with Ease: Use Ramp for Fast and Secure Transactions</title>
        <meta name="description" content="Our Ramp page offers a fast, secure, and reliable way to buy crypto assets. With our easy-to-use platform and advanced security measures, you can trust us to keep your funds safe and secure while you purchase the crypto assets you want. Our user-friendly interface makes it easy to navigate the buying process, while our fast transaction times ensure that you can start investing in no time. Whether you're new to the world of crypto or a seasoned investor, our Ramp page has everything you need to buy crypto with ease." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
            <h1>Transack Ramp</h1>
                <input type="text" className='w-50' name="cryptoCurrencyCode" value={cryptoCurrencyCode} placeholder='Crypto' onChange={onChange}/><br/><br/>
                <input type="text" className='w-50' name="fiatAmount" value={fiatAmount} placeholder='Amount' onChange={onChange}/><br/><br/>
                <button type='button' className='btn btn-primary' onClick={() => onSubmit("BUY")}>Buy</button>
                <button type='button' className='btn btn-primary' onClick={() => onSubmit("SELL")}>Sell</button>
        </div>
    )
}

export default Ramp