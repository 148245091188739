import React, { useState, useEffect } from 'react';
import { fetchToken } from "../../Auth";
import { useNavigate } from "react-router-dom";
import SmallFooter from '../SmallFooter';
import dropdown from "../../Polygon 2.png";
import { Modal } from "react-bootstrap";
import Loader from "../Loader";
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import { Helmet } from 'react-helmet';

const Withdraw = () => {

  const navigate = useNavigate();

  const [asset, setAsset] = useState([]);
  const [coin, setCoin] = useState("Select coin");
  const [coinImg, setCoinImg] = useState("");
  const [cardClicked, setCardClicked] = useState(null);
  const [showModalCoin, setShowModalCoin] = useState(false);
  const [showModalNetwork, setShowModalNetwork] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParam, setSearchParam] = useState("");
  const [searchParamNetwork, setSearchParamNetwork] = useState("");

  function asset_link() {
    fetch("https://api.flitchcoin.com/api/asset_link", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${fetchToken()}`
      }
    }).then((result) => {
      result.json().then((res) => {
        const data = Object.values(res);
        let tmpSymbol = [];
        data.map(items => {
          tmpSymbol.push(items)
        });
        setAsset([...tmpSymbol]);
      });
    });
  };

  const [network, setNetwork] = useState("Select Network");
  const [network1, setNetwork1] = useState("Select Network");

  function network_list() {
    var data = JSON.stringify({
      "string": coin
    })
    fetch("https://api.flitchcoin.com/api/network", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((res) => res.json()
        .then((result) => {
          let tmpArray = [];
          if (searchParamNetwork !== "") {
            setNetwork([]);
            result.map((items) => {
              if (items.toLowerCase().includes(searchParamNetwork.toLowerCase())) {
                tmpArray.push(items);
              }
            });
          } else {
            result.map((items) => {
              tmpArray.push(items);
            });
          }
          setNetwork([...tmpArray]);
        }))
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    network_list();
    setNetwork1([]);
  }, [searchParamNetwork]);

  useEffect(() => {
    network_list();
    setNetwork1("Select Network")
  }, [coin]);

  const [walletData, setWalletData] = useState([]);

  const getWallet = () => {
    fetch('https://api.flitchcoin.com/api/user_wallet', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      }
    }).then(res => res.json()
      .then((result) => {
        let tempArray = [];
        result.map((items) => {
          for (let i = 0; i < 1; i++) {
            tempArray.push(items);
          }
        });
        setWalletData([...tempArray]);
        setLoading(false);
      })).catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getWallet();
    asset_link();
  }, [])

  var [withdrawData, setWithdrawData] = useState({
    WithdrawAddress: "",
    WithdrawCoin: "",
    WithdrawNetwork: "",
    WithdrawTag: ""
  });

  const [withdrawAmt, setWithdrawAmt] = useState();

  var { WithdrawAddress, WithdrawCoin, WithdrawNetwork, WithdrawTag } = withdrawData;

  const gettingWallet = (items) => {
    setNetwork1(items.network);
    setCoin(items.sym);
    setCoinImg(`https://s3-symbol-logo.tradingview.com/crypto/XTVC${items.sym}.svg`)
    setWithdrawData(() => ({
      WithdrawAddress: items.wallet_add,
      WithdrawCoin: items.sym,
      WithdrawNetwork: items.network,
      WithdrawTag: Number(items.memo)
    }))
  };

  const onWithdrawChange = (e) => {
    if (e.target.value < 0) { return } else {
      setWithdrawAmt(e.target.value)
    }
  }

  const onWithdraw = (e) => {
    setLoading(true)
    e.preventDefault();
    var data = JSON.stringify({
      "address": WithdrawAddress,
      "amt": withdrawAmt,
      "coin": WithdrawCoin,
      "network": WithdrawNetwork,
      "tag": WithdrawTag
    });
    fetch("https://api.flitchcoin.com/api/withdraw", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: data
    }).then(res => res.json())
      .then((result) => {
        setLoading(false)
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
          <Helmet>
        <title>Withdraw Your Crypto Funds with Ease: Fast and Secure Transactions Guaranteed</title>
        <meta name="description" content="Our withdraw page makes it easy to transfer your crypto funds to your desired destination with fast and secure transactions. With our user-friendly interface and advanced security measures, you can rest assured that your funds are in good hands. Whether you're looking to cash out your investments or make a transfer to another account, our withdraw page has everything you need to complete your transaction quickly and efficiently." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {
        loading ?
          <><Loader /></> :
          <>
            <div className="container mt-4">
              <div className="row mb-5">
                <div className="col-xl-8 pe-5 wpara1">
                  <p className="welcome_1 p-0 mt-4">I want to Withdraw :</p>
                  <p className="plain_text">Select your preferred coin for depositing your cryptocurrencies to your account.</p>
                  <div className="col-12 mb-3 btn-group">
                    <button
                      type="button"
                      className="btn-dark w-50 round-btn pt-2 pb-2"
                      onClick={() => setShowModalCoin(true)}
                    >
                      <div className="row">
                        <div className="col-2">
                          {coinImg === "" ? (
                            <></>
                          ) : (
                            <>
                              <img
                                src={coinImg}
                                className="select_img"
                                alt="coin"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-8 text-center pt-1 mt-1">
                          <b>
                            {coin}
                          </b>
                        </div>
                        <div className="col-2 text-center pt-1 mt-1">
                          <img src={dropdown} alt="drop" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <p className="welcome_1 p-0 mt-5">Select Saved Wallet :          <span className='profile_section ps-4 pe-4 pt-2 pb-2' id='plus' style={{ marginLeft: "20vw", cursor: "pointer" }} onClick={() => (navigate('/add_wallet'))} >+</span></p>
                  <p className="plain_text">Select your preferred wallet for withdrawing {coin} to your account or add another wallet.</p>
                  {walletData.length === 0 ?
                    <>
                      <p className="profile_section">Please add a Wallet</p>
                    </> :
                    <div className='enable_scroll_3 mt-4'>
                      {walletData.map((items, index) => {
                        return (
                          <div key={index} className={`profile_section mt-4 ${cardClicked === index ? 'special_card_order' : ""}`} onClick={() => { gettingWallet(items); setCardClicked(index) }} >
                            <div className='row'>
                              <div className="col-sm-11">
                                <div>- Name : {items.name}</div>
                                {items.memo === null ? <></> : <><div>- Memo : {items.memo}</div></>}
                                {items.network === null ? <></> : <><div>- Network : {items.network}</div></>}
                                {items.sym === null ? <></> : <><div>- Coin : {items.sym}</div></>}
                                {items.wallet_add === null ? <></> : <><div>- Wallet address : {items.wallet_add}</div></>}
                                <div>- Transaction id : {items.tnx_id}</div>
                                <div>- UID : {items.uid}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>}
                </div>
                <div className="col-xl-4 ps-5 pe-5 card back special_card_deposit mt-4" id='withdrawcard'>
                  <p className="welcome_1 text-center">Withdraw Here </p>
                  <form onSubmit={onWithdraw}>
                    <p className="plain_text">Choose Transfer Network :</p>
                    <div className="col-12 mb-3 btn-group">
                      <button
                        type="button"
                        className="btn-dark w-100 round-btn pt-2 pb-2"
                        onClick={() => setShowModalNetwork(true)}
                      >
                        <div className="row">
                          <div className="col-10 text-center">
                            <b>
                              {network1}
                            </b>
                          </div>
                          <div className="col-2 text-center">
                            <img src={dropdown} alt="drop" />
                          </div>
                        </div>
                      </button>
                    </div>
                    <p className="plain_text mt-5">Wallet address :</p>
                    {withdrawData.WithdrawAddress === "" ?
                      <div>
                        <input className='input_login p-2 w-100' />
                      </div> : <div>
                        <input className="input_login p-2 w-100" value={withdrawData.WithdrawAddress} disabled />
                      </div>}
                    <p className="plain_text mt-4">Memo (optional) :</p>
                    {withdrawData.WithdrawTag === "" ?
                      <div>
                        <input className='input_login p-2 w-100' />
                      </div> : <div>
                        <input className="input_login p-2 w-100" value={withdrawData.WithdrawTag} disabled />
                      </div>}
                    <p className="plain_text mt-4">Amount :</p>
                    <input
                      type="number"
                      className="p-2 w-100  input_login"
                      onChange={onWithdrawChange}
                      name="withdrawAmt"
                      value={withdrawAmt}
                    />
                    <button
                      className="ps-5 pe-5 round-btn mt-5 mb-4 w-100"
                      type='submit'
                    >
                      Withdraw
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <SmallFooter />
            <Modal
              show={showModalCoin}
              onHide={() => setShowModalCoin(false)}
              backdrop="static"
              keyboard={false}
              className="modal-dialog-coin"
            >
              <div className="coin_popup">
                <div className="popup_coin_upper_section p-3">
                  <div className="row">
                    <div className="text-end" onClick={() => setShowModalCoin(false)}><img src={cross} className="cross" onClick={() => setShowModalCoin(false)} /></div>
                    {/* changed this line */}
                    <div className="text-start inter_text" style={{ fontSize: "20px", fontWeight: "500", marginTop: "-20px" }} onClick={() => setShowModalCoin(false)}>Select a Token</div>
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"><img src={search} style={{ height: "18px" }} /></span>
                      <input type="text" class="form-control" onChange={(e) => setSearchParam(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                </div>
                <ul className="coin_pop mb-4 p-3 pt-3">
                  {asset.map((items) => {
                    if ((items.symbol).toLowerCase().includes(searchParam.toLowerCase()) || (items.name).toLowerCase().includes(searchParam.toLowerCase())) {
                      return (
                        <div
                          className="row coin_hover mb-3 pt-3 pb-3"
                          onClick={() => {
                            setShowModalCoin(false);
                            setCoin(items.symbol);
                            setCoinImg(items.link);
                          }}
                        >
                          <div className="row text-start ps-4">
                            <li className="coin-list">
                              <img
                                src={items.link}
                                alt="coin"
                                className="select_img"
                              />{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="ps-3 pt-2 coin-name">{items.symbol}</span>
                              <span className='ps-3 pt-2 coin_subname'>{items.name}</span>
                            </li>
                          </div>
                        </div>
                      );
                    }
                  })}
                </ul>
              </div>
            </Modal>
            <Modal
              show={showModalNetwork}
              onHide={() => setShowModalNetwork(false)}
              backdrop="static"
              keyboard={false}
              className="modal-dialog-coin"
            >
              <div className="coin_popup">
                <div className="popup_coin_upper_section p-3">
                  <div className="row">
                    <div className="text-end" onClick={() => setShowModalNetwork(false)}><img src={cross} className="cross" onClick={() => setShowModalNetwork(false)} /></div>
                    {/* changed this line */}
                    <div className="text-start inter_text" style={{ fontSize: "20px", fontWeight: "500", marginTop: "-20px" }} onClick={() => setShowModalNetwork(false)}>Select a Network</div>
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"><img src={search} style={{ height: "18px" }} /></span>
                      <input type="text" class="form-control" onChange={(e) => setSearchParamNetwork(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                </div>
                <div>
                  <ul className="coin_pop mb-4 p-3 pt-3">
                    {network === 'Select Network' ?
                      <div>
                        <li className="list-items">Please select a Coin</li>
                      </div>
                      :
                      <div>
                        {network.map((items) => {
                          if ((items).toLowerCase().includes(searchParam.toLowerCase())) {
                            return (
                              <div
                                className="row coin_hover mb-3 pt-3 pb-3"
                                onClick={() => {
                                  setShowModalNetwork(false);
                                  setNetwork1(items);
                                }}
                              >
                                <div className="row text-start ps-4">
                                  <li className="coin-list">
                                    <span className="ps-3 coin-name">{items}</span>
                                  </li>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>}
                  </ul>
                </div>
              </div>
            </Modal>
          </>
      }
    </>
  )
}

export default Withdraw
