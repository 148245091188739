import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StaySafe from "../../components/StaySafe";
import LoginSocialAccount from "../../components/LoginSocialAccount";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginToken, userLogin } from "../../Feature/Auth/authSlice";
import { checkUser } from "../../helper/api";
import { Modal } from "react-bootstrap";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import Loader  from "../Loader/index";
import { Helmet } from 'react-helmet';
import "./login.css";

const Login = () => {
  const { userToken, user } = useSelector((state) => state.auth);

  const asyncEmailValidation = async (email) => {
    if (!document.activeElement || (document.activeElement && document.activeElement.type === "submit")) {
      try {
        const response = await checkUser({ emailid: email });
        const { detail } = response;
        if (!detail) {
          if (response.is_pool) {
            setValue('type', 'pool')
          } else {
            setValue('type', 'participant')
          }
          setfa2(JSON.stringify(response.fa2))
          // setError('email', false)
          return true;
        } else {
          // setError('email', true);
          return false;
        }
      } catch (e) {
        return false
      }
    } else {
      return true
    }
  }

  const schema = yup.object({
    password: yup.string().required("Password is required").min(3, "Minimum 3 character"),
    username: yup.string().required("Email field required").email("Valid Email address required").test('userNotFound', 'User not exists', asyncEmailValidation),
    type: yup.string().nullable().default(""),
    otp: yup.mixed().default(100000)
  }).required()

  const { register, handleSubmit, formState: { errors, isValid, isSubmitted },
    setValue, setError, getValues } = useForm({
      resolver: yupResolver(schema),
      mode: "onSubmit"
    });

  const [fa2, setfa2] = useState("null");
  const [show, setShow] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [gAuth, setgAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [proceed,setProceed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    if(proceed && userToken === undefined){
        setShowB(true);
        setTimeout(() => {
        setProceed(true);
        setShowB(false);
        }, 3000);
    }
    if (userToken?.access_token) {
      dispatch(userLogin());
    setLoading(false)
    }
    if (user && user?.username) {
      navigate("/dashboard");
    setLoading(false)
    }

    const formData = getValues();
    if (gAuth && formData.username.length && formData.password.length) {
      dispatch(loginToken(formData));
    setLoading(false)
    }
    setLoading(false)
  }, [userToken, user])

  let str = "";
  const nextDigit = (currVal, e) => {
    str += e;
    if (str.length === 6) {
      setValue("otp", str);
    }
    let ele = document.querySelectorAll('input.otp');
    if(str.length !=6){
      ele[currVal + 0].focus();
    }
  }

  const responseSocialAuth = async (response) => {
    // debugger
    const { user, providerId } = response;
    const { reloadUserInfo: { passwordHash } } = user;
    if (user && user.email) {
      setgAuth(true);
      setValue('username', user.email);
      setValue('password', passwordHash)
      const userResponse = await checkUser({ emailid: user.email });
      if (userResponse) {
        if (userResponse.fa2) {
          setShow(true)
        } else {
          if (!userResponse.detail) {

            dispatch(loginToken(getValues()))
          }
        }
      }
    }
  }

  const otpHandler = (e) => {
    e.preventDefault();
    dispatch(loginToken(getValues()));
    setShow(false);
  };


  const onSubmitHandler = async(e) => {
    setLoading(true)
    const response = await checkUser({ emailid: getValues().username });
    const faValue = JSON.stringify(response.fa2);
    if (isValid) {
      if (faValue === "true") {
        setShow(true);
        setProceed(true)
      } else {
        dispatch(loginToken(e));
        setProceed(true)
      }
    setLoading(false)
    } else {
      setShowA(true);
      setTimeout(() => {
        setShowA(false);
      }, 3000)
    setLoading(false)
    }

  }

  const navigatePage = (page) => {
    navigate(page)
  }

  function createRipple(event) {
  const button = event.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}

const buttons = document.getElementsByTagName("button");
for (const button of buttons) {
  button.addEventListener("click", createRipple);
}


  return (
    <>
          <Helmet>
        <title>Securely Sign in to Your Crypto Account: Access Your Investments with Confidence</title>
        <meta name="description" content="Sign in to your crypto account with confidence using our secure and user-friendly signin page. Manage your investments, track your portfolio, and stay up-to-date on the latest crypto news and trends all in one convenient location." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {
        loading ?
          <>
            <Loader />
          </> :
          <>
            <div className="login-2-page">
              <div className="container">
                <div className="row login-row">
                  <div className="col-xs-12 col-sm-12 col-lg-6">
                    <h3 className="page-title login-title">Flitchcoin Login</h3>
                    <div className="login-wrapper form-wrapper">
                      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete={false} noValidate className={`${isSubmitted && 'was-validated'}`}>
                        <div className="form-group">
                          <label htmlFor="" className="form-label">Personal Email</label>
                          <input
                            {...register('username', { required: true })}
                            type="text" name="username" placeholder="Enter your Email"
                            className={`form-control ${errors?.username && 'is-invalid'}`}

                          />

                          {
                            (errors.username) && (
                              <div className="invalid-feedback">
                                <span>{errors.username?.message}</span>
                              </div>
                            )
                          }

                        </div>
                        <div className="form-group">
                          <label htmlFor="" className="form-label">Password</label>
                          <input {...register('password', { required: true })} name="password" type="password" className={`form-control ${errors?.password && 'is-invalid'}`} />
                          {
                            (errors?.password) && (
                              <div className="invalid-feedback">
                                {errors?.password?.message}
                              </div>
                            )
                          }
                        </div>
                        <div className="text-end a-t-s a-link mt-4"  onClick={e => navigatePage('/forgot_password')}><span>
                          Forgot Password ?
                        </span></div>
                        <div className="form-group mt-60px">
                          <div className="d-grid">
                            <button type="submit" className="block btn btn-spl-primary">Next</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* 
                      <div className="or-continue-wrapper">
                      <div className="or-c-lr">
                          <div className="or-continue-box">
                           <div className="or-continue-content">
                             <span>
                                or continue with
                              </span>
                          </div>
                         </div>
                      </div>
                    </div>
                    <div>
                    <LoginSocialAccount socialAuthResponse={responseSocialAuth} />
                    </div>
                     */}
                    <div className="ats-content">
                      <p className="mb-0 text-center">
                        I don’t have Flitchcoin account
                        &nbsp;<span className="a-t-s a-link" onClick={e => navigatePage('/sign-up')}>
                          advance to Signup
                        </span>
                      </p>

                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-6 login-a-center">
                    <StaySafe />
                  </div>
                </div>
              </div>
              { /* Modal */}
              <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-login"
              >
                <div className="modal_popup">
                  <div className="login_modal_head text-start pt-3 ps-3 pb-2">2 Factor Authentication</div>
                  <div className="popup_line"></div>
                  <div className="login_modal_sub pt-2">Please enter <b>2FA</b> code to login</div>
                  <form className="mt-3">
                    <input className="otp me-2" type="text" onChange={(e) => nextDigit(1, e.target.value)} />
                    <input className="otp me-2" type="text" onChange={(e) => nextDigit(2, e.target.value)} />
                    <input className="otp me-2" type="text" onChange={(e) => nextDigit(3, e.target.value)} />
                    <input className="otp me-2" type="text" onChange={(e) => nextDigit(4, e.target.value)} />
                    <input className="otp me-2" type="text" onChange={(e) => nextDigit(5, e.target.value)} />
                    <input className="otp" type="text" onChange={(e) => nextDigit(6, e.target.value)} />
                  </form>
                  <div className="text-center">
                    <button type="button" className="ok_btn_login mt-5 ps-4 pe-4 mb-4" onClick={otpHandler}>Ok &#10004;</button>
                  </div>
                </div>
              </Modal>
              {/* end Modal */}
              <Modal
                show={showA}
                onHide={() => setShowA(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
              >
                <div className="popup_error">
                  <div className="popup_error_head">
                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Unfilled !</p>
                    <div className="event_line"></div>
                  </div>
                  <p className="popup_error_body_text pt-4 pb-3">Please enter the details to proceed.</p>
                  <div className='d-flex justify-content-center pb-4'>
                    <CountdownCircleTimer
                      size={40}
                      strokeWidth={3}
                      isPlaying
                      duration={3}
                      colors={"red"}
                      colorsTime={[3, 0]}
                    >
                      {({ remainingTime }) => {
                        return <img src={cross} className="cross" onClick={() => setShowA(false)} />
                      }}

                    </CountdownCircleTimer>
                  </div>
                </div>
              </Modal>
              <Modal
                show={showB}
                onHide={() => setShowB(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
              >
                <div className="popup_error">
                  <div className="popup_error_head">
                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                    <div className="event_line"></div>
                  </div>
                  <p className="popup_error_body_text pt-4 pb-3">Please enter the correct Password to proceed.</p>
                  <div className='d-flex justify-content-center pb-4'>
                    <CountdownCircleTimer
                      size={40}
                      strokeWidth={3}
                      isPlaying
                      duration={3}
                      colors={"red"}
                      colorsTime={[3, 0]}
                    >
                      {({ remainingTime }) => {
                        return <img src={cross} className="cross" onClick={() => setShowB(false)} />
                      }}

                    </CountdownCircleTimer>
                  </div>
                </div>
              </Modal>
            </div>
          </>
      }
    </>

  )
}


export default Login;
