import React,{useEffect} from 'react';
import { Modal } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Loader = () => {

  const { selectedType } = useSelector((state) => state.auth);
  const navigate = useNavigate();

    useEffect(() => {
        console.log(selectedType,localStorage.getItem("token"))
        if(selectedType === "accept" || selectedType === undefined || selectedType === null){
          
        }else{
          setInterval(() => {
            if(!localStorage.getItem("token")){
              navigate("/login");
            }
          },3000)
        }
      },[]);

    return (
        <>
        <Helmet>
        <title>Loading... Join our Crypto Community for Exciting Opportunities</title>
        <meta name="description" content="Our website is loading! Join our vibrant crypto community and explore exciting opportunities in the world of cryptocurrency. Stay tuned for more updates!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
        <Modal
            show={true}
            backdrop="static"
            keyboard={false}
            className="modal-dialog-normal"
        >
            <div className="i giving_loading_margin"></div>
            <div className="i giving_loading_margin"></div>
        </Modal>
        </>
    )
}

export default Loader