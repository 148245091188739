import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchToken } from "../../Auth";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { avtarType } from "../../Feature/Order/orderSlice";
import statement from "./Group 87.png";
import setting from "./Group 97.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import SmallFooter from '../SmallFooter';
import Loader from "../Loader";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import { Helmet } from 'react-helmet';

const Profile = () => {

    AOS.init({
        duration: 1000,
        once: false,
    });

    const [avt, setAvt] = useState();
    const [show, setShow] = useState(false);
    const [arr, setArr] = useState();
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState("");
    const [showA, setShowA] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getAvtar = () => {
        fetch('https://api.flitchcoin.com/api/avtar', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                let tempArray = [];
                for (let i = 0; i < Object.entries(res).length; i++) {
                    tempArray.push(Object.entries(res)[i][1]);
                }
                setArr([...tempArray]);
            })).catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        getAvtar();
        change();
        user();
    }, []);

    const edit = (e) => {
        setLoading(true)
        fetch('https://api.flitchcoin.com/api/avtar', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                for (let i = 0; i < Object.entries(res).length; i++) {
                    if (e === Object.entries(res)[i][1]) {
                        var data = JSON.stringify({
                            "avtar": i + 1
                        })
                        fetch("https://api.flitchcoin.com/api/dashboard", {
                            method: "PUT",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${fetchToken()}`
                            },
                            body: data
                        }).then(result => result.json()
                            .then(res => {
                                if (res.status === 200) {
                                    setShow(false);
                                    setLoading(false)
                                }
                            })).catch(err => console.log(err))
                    }
                }
            })).catch((err) => {
                console.log(err);
            })
    };

    const change = () => {
        fetch('https://api.flitchcoin.com/api/dashboard', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                setAvt(res.avtar_im);
                dispatch(avtarType("changed"));
            })).catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        change();
    }, [show]);

    const [username, setUsername] = useState();
    const [name, setName] = useState();

    const user = () => {
        fetch("https://api.flitchcoin.com/api/users/me/items/", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                setUsername(res.username);
                setName(res.name);
                setLoading(false)
            })).catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        user();
    }, [name]);

    const changeUser = () => {
        setLoading(true)
        var data = JSON.stringify({
            "name": display
        })
        fetch("https://api.flitchcoin.com/api/dashboard", {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            },
            body: data
        }).then(result => result.json()
            .then(res => {
                setShowA(true);
                setName(res.name);
                setLoading(true)
            })).catch(err => console.log(err))
    };

    useEffect(() => {
        setTimeout(() => {
            setShowA(false)
        }, 3000)
    }, [showA]);

    return (
        <div>
            <Helmet>
        <title>Manage Your Account with Ease: Customize Your Profile on Our Crypto Platform</title>
        <meta name="description" content="Our profile page provides you with a centralized location to manage and customize your account on our crypto platform. From here, you can update your personal information, manage your communication preferences, and configure your security settings to ensure your account is protected at all times. Whether you're a new user or a seasoned investor, our user-friendly profile page makes it easy to manage your account and stay on top of your crypto investments. Join us today and take control of your account on our trusted and secure crypto platform." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
            {
                loading ?
                    <Loader /> :
                    <>
                        <div className="row mt-4">
                            <div className="col-xxl-2 col-xl-3 col-12 side_navigation" data-aos="fade-right">
                                <Link to="/profile" className='link'><i className=" ps-4 pe-4 pt-2 pb-2 mb-3 dropdown-item mt-5 selected-item"><img src={avt} style={{ height: "30px", width: "30px", borderRadius: "50%" }} alt="profile" /> &nbsp; Profile</i></Link>
                                <Link to="/statements" className='link'><i className=" ps-4 pe-4 pt-2 pb-2 mb-3 dropdown-item"><img src={statement} style={{ height: "32px", width: "32px" }} alt="statement" /> &nbsp; Statements</i></Link>
                                <Link to="/settings" className='link'><i className="ps-4 pe-4 pt-2 pb-2 dropdown-item"><img src={setting} style={{ height: "25px", width: "25px" }} alt="settings" /> &nbsp; Settings</i></Link>
                            </div>
                            <div className="col-md-2">
                            </div>
                            <div className="col-12 col-md-6 mt-4 mb-4" id="mainprofile">
                                <p className='text-center mt-4' style={{ fontSize: "47px", fontWeight: "700" }} data-aos="fade-down">Profile</p>
                                <hr />
                                <div className='mt-5' id="toolcard" data-aos="fade-up">
                                    <div className="row profile_section pt-5">
                                        <div className="col-12 text-center col-sm-2">
                                            <img src={avt} className="avatar_big_2" alt="profile img" />
                                            <p className="text-muted mt-2 edit" onClick={() => setShow(true)}>Edit</p>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="row ms-3 mt-3"><h4>{name}</h4></div>
                                            <div className="row ms-3"><h6 className='text-muted'>{username}</h6></div>
                                        </div>
                                        <Modal
                                            show={show}
                                            onHide={() => setShow(false)}
                                            backdrop="static"
                                            keyboard={false}
                                            className="modal-dialog-popup"
                                        >
                                            <div className="special_popup p-3">
                                                <div className="row">
                                                    <div className="col-10"></div>
                                                    <div className="col-2"><button type="button" className="btn-close" aria-label="Close" onClick={() => setShow(false)}></button></div>
                                                </div>
                                                <h2>Select your Avatar !</h2>
                                                {arr ? <>
                                                    {arr.map((items) => {
                                                        return (
                                                            <img src={items} alt="profile" className="change_profile" onClick={() => edit(items)} />
                                                        )
                                                    })}
                                                </> : <></>
                                                }
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div className='row profile_section mt-4' id="contactcard">
                                    <h2>Contact Info</h2><hr />
                                    <div className="accordion accordion-flush" id="accordionFlushExample" data-aos="zoom-in">
                                        <div className="accordion-item mt-3">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Display Name : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span className='reduce_bold'>{name}</span>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <input className='input_login txt-underline p-3 mt-3 w-100' placeholder='Display name' name="display name" value={display} onChange={(e) => setDisplay(e.target.value)} /><span className="underline"></span><br /><br />
                                                    <div className="row">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-2 col-6"><button className='btn btn-light w-100' onClick={() => setDisplay("")} data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">Cancel</button></div>
                                                        <div className="col-md-2 col-6 "><button className='btn btn-primary w-100' onClick={changeUser}>Save</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="accordion-item mb-3">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Email address : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span className='reduce_bold'>{username}</span>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">Your email address has been set to <span className='text-muted'>{username}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SmallFooter />
                        <Modal
                            show={showA}
                            onHide={() => setShowA(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Done !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">Your display name has been changed.</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowA(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                    </>
            }
        </div>
    )
}

export default Profile