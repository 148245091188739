import React,{useState} from 'react';
import { fetchToken } from "../../Auth";
import { Helmet } from 'react-helmet';

const Wyre = () => {

  const [formData, setFormData] = useState({
    sym: "",
    AssetAmount: "",
    sourceCurrency: "",
    country: "",
    paymentMethod: "",
  })

  const { sym, AssetAmount, sourceCurrency, country, paymentMethod } = formData;

  const onChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      "sym": sym,
      "AssetAmount": Number(AssetAmount),
      "sourceCurrency": sourceCurrency,
      "country": country,
      'paymentMethod': paymentMethod,
    });
    fetch("https://api.flitchcoin.com/api/wyr/onramp", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: data
    }).then(res => res.json())
      .then((result) => {
        console.log(result)
      }).catch((err) => {
        console.log(err);
      })
  }

  return (
    <div className='container'>
      <Helmet>
        <title>Simplify Your Crypto Transactions with Wyre: Fast, Secure,and Reliable</title>
        <meta name="description" content="Our Wyre page offers a simple and secure way to manage your crypto transactions. With our easy-to-use platform and fast transaction times, you can buy, sell, and transfer your crypto assets with ease. Our advanced security measures ensure that your funds are always safe and secure, while our user-friendly interface makes managing your investments a breeze. Whether you're a seasoned investor or just getting started in the world of crypto, our Wyre page has everything you need to simplify your transactions and maximize your profits." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      <form onSubmit={onSubmit}>
        <input type='text' className="w-50" placeholder='Symbol' name='sym' value={sym} onChange={onChange} /><br/><br/>
        <input type='text' className="w-50" placeholder='Amount' name='AssetAmount' value={AssetAmount} onChange={onChange} /><br/><br/>
        <input type='text' className="w-50" placeholder='Currency' name='sourceCurrency' value={sourceCurrency} onChange={onChange} /><br/><br/>
        <input type='text' className="w-50" placeholder='Country' name='country' value={country} onChange={onChange} /><br/><br/>
        <input type='text' className="w-50" placeholder='Payment Method' name='paymentMethod' value={paymentMethod} onChange={onChange} /><br/><br/>
        <button type='submit' className="btn btn-primary">Submit</button>
      </form>
    </div>
  )
}

export default Wyre