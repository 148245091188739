import React, { useEffect, useState } from "react";
import backArr from "./Group 190.png";
import { fetchToken } from "../../Auth";
import SwapArrows from "./swapImage.svg";
import DownArrow from "./downarrow.svg";
import RightArrow from "./rightarr.svg";
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import { Modal } from "react-bootstrap";
import LoadingButton from '../../elements/LoadingButton';

const Swap = (props) => {
  const [asset, setAsset] = useState([]);
  const [coin, setCoin] = useState("Select");
  const [coinImg, setCoinImg] = useState("");
  const [coin2, setCoin2] = useState("Select");
  const [coinImg2, setCoinImg2] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [showModalCoin, setShowModalCoin] = useState(false);
  const [showModalCoin2, setShowModalCoin2] = useState(false);
  const [buttonLoad,setButtonLoad] = useState(false);

  function asset_link() {
    fetch("https://api.flitchcoin.com/api/asset_link", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    }).then((result) => {
      result.json().then((res) => {
        const data = Object.values(res);
        let tmpSymbol = [];
        data.map((items) => {
          tmpSymbol.push(items);
        });
        setAsset([...tmpSymbol]);
      });
    });
  }

  const [quantity, setQuantity] = useState("");
  const [quantityConverted, setQuantityConverted] = useState("");
  const [priceInUsd, setPriceInUsd] = useState(0);

  const getFinalPrice = () => {
    fetch(`https://api.flitchcoin.com/api/prices/${coin.toUpperCase()}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((result) => {
          setPriceInUsd(result[0] * quantity);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const ConvertedQuantity = () => {
    fetch(`https://api.flitchcoin.com/api/prices/${coin2.toUpperCase()}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((result) => {
          setQuantityConverted(priceInUsd / result[0]);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    asset_link();
  }, []);

  useEffect(() => {
    getFinalPrice();
    ConvertedQuantity();
  }, [coin, quantity, priceInUsd, coin2]);

  const swap = () => {
    setButtonLoad(true);
    const data = JSON.stringify({
      from_asset: coin,
      to_asset: coin2,
      quantity: quantity,
    });
    fetch("https://api.flitchcoin.com/api/swap/flitch", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((result) =>
        result.json().then((res) => {
          console.log(res);
          setButtonLoad(false);
        })
      )
      .catch((err) => console.log(err));
  };

  const changePage = () => {
    props.page();
  };

  return (
    <div id="mainswap" className="swapbox-padding">
      <div className="row align-items-center">
        <span className="col-2" onClick={changePage}>
          {" "}
          <img src={backArr} alt="arrow" className="backImg" />
        </span>
        <span className="col-6"></span>
        <span className="col-4">
          <button className="btn-dark round-btn  refresh refresh-btn">
            Refresh
          </button>
        </span>
      </div>
      <div className="row mt-3 mb-2">
        <p className="api_head text_design text-center " id="fs">
          Flitch Swap
        </p>
        <div className="row  input-background inputdiv-1 sf1">
          <p className="ms-2 mb-0 smalltxt from">From :</p>
          <div className="row align-items-center input-row">
            <div className="col-lg-7">
              <label className="label">
                <input
                  className=" input-swap ps-4  w-100"
                  placeholder="Amount in "
                  type="text"
                  name="quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                />
              </label>
            </div>
            <div className="col-lg-5 ps-0  rrm1">
              <div className="text-center btn-group rr1">
                <button
                  id="rr1btn"
                  type="button"
                  className="btn round-btn pe-4 btn-dark w-100"
                  style={{ width: "10%" }}
                  onClick={() => setShowModalCoin(true)}
                >
                  <div className="row">
                    <div className="col-2 ph">
                      {coinImg === "" ? (
                        <></>
                      ) : (
                        <>
                          <img
                            src={coinImg}
                            className="select_img"
                            alt="coin"
                          />
                        </>
                      )}
                    </div>
                    <div className="col-8 text-center">
                      <b>{coin}</b>
                    </div>
                    <div className="col-2 text-center">
                      <img src={DownArrow} alt="drop" className="downarrow" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mb-0 text-center"
          onClick={() => {
            setCoin(coin2);
            setCoin2(coin);
            setCoinImg(coinImg2);
            setCoinImg2(coinImg);
          }}
        >
          <div className="swap-img">
            <img src={SwapArrows} alt="" srcset="" />
          </div>
        </div>
        <div className="row input-background inputdiv-2 sf2">
          <p className=" ms-2 mb-0 smalltxt to">To :</p>
          <div className="row align-items-center input-row">
            <div className="col-lg-7">
              <label className="label">
                <input
                  className=" input-swap  ps-4 w-100"
                  placeholder="Amount in "
                  type="text"
                  name="quantity"
                  value={Number(quantityConverted).toFixed(6)}
                />
              </label>
            </div>
            <div className="col-lg-5 ps-0  rrm2">
              <div className="text-center btn-group rr2">
                <button
                  id="rr2btn"
                  type="button"
                  className="btn round-btn pe-4 btn-dark w-100"
                  onClick={() => setShowModalCoin2(true)}
                  style={{ width: "10%" }}
                >
                  <div className="row">
                    <div className="col-2 ph">
                      {coinImg2 === "" ? (
                        <></>
                      ) : (
                        <>
                          <img
                            src={coinImg2}
                            className="select_img"
                            alt="coin"
                          />
                        </>
                      )}
                    </div>
                    <div className="col-8 text-center">
                      <b>{coin2}</b>
                    </div>
                    <div className="col-2 text-center">
                      <img src={DownArrow} alt="drop" className="downarrow" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-muted swaptxt bottom-content">
        <div className="col-lg-6">Price :</div>
        <div className="col-lg-6 text-end">~ {priceInUsd} USDT</div>
        <div className="col-lg-6">Slippage :</div>
        <div className="col-lg-6 text-end">~ 0.8%</div>
        <div className="col-lg-6">Net Effective Fee :</div>
        <div className="col-lg-6 text-end">~ 0.8%</div>
      </div>
      <div className="row  sb1 ">
        <div className="col-lg-6">
          <button
            className="round-btn red button cancel-btn"
            onClick={changePage}
          >
            Cancel
          </button>
        </div>
        <div className="col-lg-6 pe-0">
        <LoadingButton loading={buttonLoad} className='round-btn'>
          <button
            className="round-btn place_order_btn w-100 row pt-2 pb-2 swap-proceed-btn"
            onClick={swap}
          >
            <div className="col-9 text-end">Proceed</div>
            <div className="col-3 proceed-btn-icon">
              <img src={RightArrow} alt="" />
            </div>
          </button>
          </LoadingButton>
        </div>
      </div>
      <Modal
        show={showModalCoin}
        onHide={() => setShowModalCoin(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="coin_popup">
          <div className="popup_coin_upper_section p-3">
            <div className="row">
              <div className="text-end" onClick={() => setShowModalCoin(false)}>
                <img
                  src={cross}
                  className="cross"
                  onClick={() => setShowModalCoin(false)}
                />
              </div>
              <div
                className="text-start inter_text"
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  marginTop: "-20px",
                }}
              >
                Select a Token
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  <img src={search} style={{ height: "18px" }} />
                </span>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setSearchParam(e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
          <div>
            <ul className="coin_pop mb-4 p-3 pt-3">
              {asset.map((items) => {
                if (
                  items.symbol
                    .toLowerCase()
                    .includes(searchParam.toLowerCase()) ||
                  items.name.toLowerCase().includes(searchParam.toLowerCase())
                ) {
                  return (
                    <div
                      className="row coin_hover mb-3 pt-3 pb-3"
                      onClick={() => {
                        setShowModalCoin(false);
                        setCoin(items.symbol);
                        setCoinImg(items.link);
                      }}
                    >
                      <div className="row text-start ps-4">
                        <li className="coin-list">
                          <img
                            src={items.link}
                            alt="coin"
                            className="select_img"
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="ps-3 pt-2 coin-name">
                            {items.symbol}
                          </span>
                          <span className="ps-3 pt-2 coin_subname">
                            {items.name}
                          </span>
                        </li>
                      </div>
                    </div>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModalCoin2}
        onHide={() => setShowModalCoin2(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="coin_popup">
          <div className="popup_coin_upper_section p-3">
            <div className="row">
              <div
                className="text-end"
                onClick={() => setShowModalCoin2(false)}
              >
                <img
                  src={cross}
                  className="cross"
                  onClick={() => setShowModalCoin2(false)}
                />
              </div>
              <div
                className="text-start inter_text"
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  marginTop: "-20px",
                }}
              >
                Select a Token
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  <img src={search} style={{ height: "18px" }} />
                </span>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setSearchParam(e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
          <div>
            <ul className="coin_pop mb-4 p-3 pt-3">
              {asset.map((items) => {
                if (
                  items.symbol
                    .toLowerCase()
                    .includes(searchParam.toLowerCase()) ||
                  items.name.toLowerCase().includes(searchParam.toLowerCase())
                ) {
                  return (
                    <div
                      className="row coin_hover mb-3 pt-3 pb-3"
                      onClick={() => {
                        setShowModalCoin2(false);
                        setCoin2(items.symbol);
                        setCoinImg2(items.link);
                      }}
                    >
                      <div className="row text-start ps-4">
                        <li className="coin-list">
                          <img
                            src={items.link}
                            alt="coin"
                            className="select_img"
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="ps-3 pt-2 coin-name">
                            {items.symbol}
                          </span>
                          <span className="ps-3 pt-2 coin_subname">
                            {items.name}
                          </span>
                        </li>
                      </div>
                    </div>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Swap;
