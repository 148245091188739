import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { contractABI, contractAddress } from "./constants";
import { fetchToken } from "../Auth";
import ethimg from "./eth.png";
import "./index.css"
import Loader from "../pages/Loader";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import metamask_select from "./metamask_select.jpeg";
import trustwallet_select from "./trustwallet_select.jpg";
import { Modal } from "react-bootstrap";
import Web3Modal from "web3modal";
const { ethereum } = window;
const shortenAddress = (address) => `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;

const Web3 = () => {

    const [currentAccount, setCurrentAccount] = useState('');
    const [amount, setAmount] = useState("");
    const [addressTo, setAddressTo] = useState("0x664912E99eADF2E80887Dad7294ddFBD05886D60");
    const [loading, setLoading] = useState(false);
    const [show,setShow] = useState(false);
    const [showA,setShowA] = useState(false);
    const [showB,setShowB] = useState(false);

    const getEthereumContract = async() => {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const transactionContract = new ethers.Contract(contractAddress, contractABI, signer);
        return transactionContract;
    }

    const connectWallet = async () => {
        try {
            if (!ethereum) return setShowB(true);
            setLoading(true);
            
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            setCurrentAccount(accounts[0]);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setShowA(true);
            console.log(e);
            throw new Error("no ethereum object");
        }

    }

    const checkIfWalletIsConnected = async () => {
        try {
            if (!ethereum) return setShowB(true);
            setLoading(true);
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            if (accounts.length) {
                setCurrentAccount(accounts[0]);
            } else {
                console.log("no account found");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setShowA(true);
            console.log(error);
        }

    }

    const sendTransaction = async (e) => {
        e.preventDefault();
        if (!amount) return;
        try {
            if (!ethereum) return setShowB(true);
            setLoading(true);
            const transactionContract = getEthereumContract();
            const parsedAmount = ethers.utils.parseEther(amount);
            await ethereum.request({
                method: "eth_sendTransaction",
                params: [{
                    from: currentAccount,
                    to: addressTo,
                    value: parsedAmount._hex,
                }]
            });
            fetch("https://api.flitchcoin.com/api/meta/deposit/ETH", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${fetchToken()}`
                },
            }).then(res => res.json())
                .then(async (result) => {
                    const transactionHash = await transactionContract.addToBlockchain(addressTo, parsedAmount, result.token);
                    await transactionHash.wait();
                    fetch("https://api.flitchcoin.com/api/meta/deposit/ETH", {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            Authorization: `Bearer ${fetchToken()}`
                        },
                        body: JSON.stringify({
                            "hash": transactionHash.hash
                        })
                    }).then(res => res.json())
                        .then((result) => {
                            setLoading(false);
                            setAmount(null);
                        }).catch((err) => {
                            console.log(err);
                        });
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                });

        } catch (e) {
            setLoading(false);
            setShow(true);
            setAmount(null);
            console.log(e);
            throw new Error("no ethereum object");
        }
    }

    useEffect(() => {
        checkIfWalletIsConnected();
        getEthereumContract();
        // var data = JSON.stringify({
        //     coin_name: "ETH",
        //     network: "ETH",
        // });
        // fetch("https://api.flitchcoin.com/api/wallet_address/", {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: data
        // }).then(res => res.json())
        //     .then((result) => {
        //         setAddressTo(result.address)
        //     }).catch((err) => {
        //         console.log(err);
        //     });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setShowA(false);
            setShow(false);
          }, 3000)
    },[show,showA,showB]);

    return (
        <>
            {
                loading ?
                    <>
                        <Loader />
                    </> :
                    <>
                        <div className="web3">
                            <div className="container mb-4 pb-2">
                                <div className="row justify-content-between">
                                    <div className="col-md-5">
                                        <h3 className="web3-gradient1">FlitchCoin</h3>
                                        <p className="web3-gradient2">Connect MetaMask</p>
                                        <p className="web3-gradient2">
                                            and <span className="web3-gradient3">Deposit</span>
                                        </p>
                                        {
                                            !currentAccount && (
                                                <button className="primary round-btn newpo_btn w-50 ud-place-orderbtn" onClick={connectWallet}>
                                                    Connect Wallet
                                                </button>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="ud-card">
                                            <div className="card-uppercontent d-flex justify-content-between">
                                                <img src={ethimg} alt="" srcset="" />
                                                <div className="address-content">
                                                    <h2>Deposit Ethereum</h2>
                                                    <h4>{shortenAddress(currentAccount)}</h4>
                                                </div>
                                            </div>
                                            <div className="card-lowercontent">
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    className="web3-input"
                                                    placeholder="Enter Amount in Eth"
                                                    value={amount} onChange={(e) => setAmount(e.target.value)}
                                                />
                                                <button className="web3-depobtn" onClick={sendTransaction} >
                                                    Deposit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                        <div className="event_line"></div>
                    </div>
                    <p className="popup_error_body_text pt-4 pb-3">Transaction declined.</p>
                    <div className='d-flex justify-content-center pb-4'>
                        <CountdownCircleTimer
                            size={40}
                            strokeWidth={3}
                            isPlaying
                            duration={3}
                            colors={"red"}
                            colorsTime={[3, 0]}
                        >
                            {({ remainingTime }) => {
                                return <img src={cross} className="cross" onClick={() => setShow(false)} />
                            }}

                        </CountdownCircleTimer>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showA}
                onHide={() => setShowA(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                        <div className="event_line"></div>
                    </div>
                    <p className="popup_error_body_text pt-4 pb-3">Wallet connection Failed.</p>
                    <div className='d-flex justify-content-center pb-4'>
                        <CountdownCircleTimer
                            size={40}
                            strokeWidth={3}
                            isPlaying
                            duration={3}
                            colors={"red"}
                            colorsTime={[3, 0]}
                        >
                            {({ remainingTime }) => {
                                return <img src={cross} className="cross" onClick={() => setShowA(false)} />
                            }}

                        </CountdownCircleTimer>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showB}
                onHide={() => setShowB(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                        <div className="event_line"></div>
                    </div>
                    <p className="popup_error_body_text pt-4 pb-3">Please install Metamask / Trust Wallet.</p>
                            <div className="row mb-4">
                                <div className="col-5 ms-4 ">
                                    <a href="https://metamask.io/download/" target="_blank">
                                        <img src={metamask_select} className="download_option" alt="" />
                                    </a>
                                </div>
                                <div className="col-5 ms-2">
                                    <a href="https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" target="_blank">
                                        <img src={trustwallet_select} className="download_option" alt="" />
                                    </a>
                                </div>
                            </div>
                </div>
            </Modal>
        </>
    )
}

export default Web3;