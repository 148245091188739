import React, { useEffect, useState } from "react";
import { TickerTape } from "react-ts-tradingview-widgets";
import { fetchToken } from "../../Auth";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Autoplay, Navigation } from "swiper";
import SmallFooter from "../SmallFooter/index.jsx";
import Swap from "./swap.jsx";
import Loader from "../Loader";
import "./swap.css";
import profileEdit from "../../assets/icons/profile-edit.svg";
import "./index.css";
import { Modal } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import cross from "./Group 438.svg";
import { MdArrowForwardIos } from "react-icons/md";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";

function Dashboard() {
  const navigate = useNavigate();
  const { selectedType } = useSelector((state) => state.auth);
  const [username, setUsername] = useState("");
  const [tableInfo, setTableInfo] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [cardInfo, setCardInfo] = useState([]);
  const [page, setPage] = useState("Dashboard");
  const [loading, setLoading] = useState(true);

  const account = () => {
    fetch("https://api.flitchcoin.com/api/account", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          // if we have to convert object to array we have to use Object.keys or Object.entries
          // Object.keys will return keys of object
          // Object.entries will return array of key with value
          // Object.values will return array of values of all keys
          var tempArr = [];
          var tempArr_2 = [];
          var count = 0;
          var count_2 = 0;
          const data = Object.values(res);
          for (let i = 0; i < data.length; i++) {
            tempArr.push(data[i]);
            count += data[i].Total_Quote;
            if (count_2 < 10) {
              if (data[i].total - data[i].used) {
                tempArr_2.push(data[i]);
                count_2 = count_2 + 1;
              }
            }
          }
          var i = 0;
          while (count_2 < 3) {
            tempArr_2.push(data[i]);
            i++;
            count_2++;
          }
          setTableInfo([...tempArr]);
          setTotalBalance(count);
          setCardInfo([...tempArr_2]);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const [fullName, setFullName] = useState("");

  const getInfo = () => {
    fetch("https://api.flitchcoin.com/api/users/me/items/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          setFullName(res.name);
          setUsername(res.username);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const [fa2, setfa2] = useState(false);

  const checkUser = (e) => {
    const data = JSON.stringify({
      emailid: username,
    });
    fetch("https://api.flitchcoin.com/api/userchrono_info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.fa2 === null || data.fa2 === false) {
          setfa2(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [avt, setAvt] = useState();

  const getAvt = () => {
    fetch("https://api.flitchcoin.com/api/dashboard", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          setAvt(res.avtar_im);
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setInterval(account, 60000);
    account();
    getInfo();
    setTimeout(() => {
      setOnLoad(true);
    }, 2000);
    getAvt();
  }, []);

  const [startValue, setStartValue] = useState([]);
  const [endValue, setEndValue] = useState([]);

  useEffect(() => {
    var arr = [];
    var arr2 = [];
    const totalBalanceFixed = Number(totalBalance).toFixed(3)
    for (let i = 0; i < totalBalanceFixed.toString().length - 3; i++) {
      arr.push(totalBalanceFixed.toString().charAt(i));
    }
    for (
      let i = totalBalanceFixed.toString().length - 3;
      i < totalBalanceFixed.toString().length;
      i++
    ) {
      arr2.push(totalBalanceFixed.toString().charAt(i));
    }
    setStartValue([...arr]);
    setEndValue([...arr2]);
  }, [totalBalance]);

  useEffect(() => {
    checkUser(username);
  }, [username]);

  const [onLoad, setOnLoad] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showSwap, setShowSwap] = useState(false);
  const [currAsset, setCurrAsset] = useState("");

  const onEarnClick = (e) => {
    setCurrAsset(e);
    if (totalBalance < 20) {
      setShowDeposit(true);
    } else {
      fetch("https://api.flitchcoin.com/api/account", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
        },
      })
        .then((result) =>
          result.json().then((res) => {
            const data = Object.values(res);
            for (let i = 0; i < data.length; i++) {
              if (data[i].asset === e) {
                if (data[i].Usable_Quote < 20) {
                  setShowSwap(true);
                } else {
                  navigate("/club");
                }
              }
            }
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onLendClick = (e) => {
    setCurrAsset(e);
    if (totalBalance < 20) {
      setShowDeposit(true);
    } else {
      fetch("https://api.flitchcoin.com/api/account", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
        },
      })
        .then((result) =>
          result.json().then((res) => {
            const data = Object.values(res);
            for (let i = 0; i < data.length; i++) {
              if (data[i].asset === e) {
                if (data[i].Usable_Quote < 20) {
                  setShowSwap(true);
                } else {
                  navigate(`/place_order?coin=${e}`);
                }
              }
            }
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowDeposit(false);
      setShowSwap(false);
    }, 15000);
  }, [showDeposit, showSwap]);

  function createRipple(event) {
    const button = event.currentTarget;

    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");
    button.appendChild(circle);
  }

  const buttons = document.getElementsByTagName("button");
  for (const button of buttons) {
    button.addEventListener("click", createRipple);
  }

  useEffect(() => {
    console.log(selectedType, localStorage.getItem("token"))
    if (selectedType === "accept" || selectedType === undefined || selectedType === null) {

    } else {
      setInterval(() => {
        if (!localStorage.getItem("token")) {
          navigate("/login");
        }
      }, 3000)
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cryptocoin Dashboard: Monitor and Manage Your Digital Assets with Ease on Our User-Friendly Platform</title>
        <meta name="description" content="Powerful Cryptocoin Dashboard: Gain Real-time Insights, Track Investments, and Execute Transactions with Efficiency. Take Control of Your Cryptocoin Portfolio Today!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>

      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <div className="container card back parent_card parent-card mt-4 m-parent-card">
            {onLoad ? (
              <>
                <div className="row mt-3 ticker">
                  <TickerTape
                    colorTheme="light"
                    symbols={[
                      {
                        proName: "BITSTAMP:BTCUSD",
                        title: "BTC/USD",
                      },
                      {
                        proName: "BITSTAMP:ETHUSDT",
                        title: "ETH/USDT",
                      },
                      {
                        proName: "BINANCE:SOLUSDT",
                        title: "SOL/USDT",
                      },
                      {
                        proName: "BINANCE:MATICUSDT",
                        title: "MATIC/USDT",
                      },
                      {
                        proName: "BINANCE:AVAXUSDT",
                        title: "AVAX/USDT",
                      },
                      {
                        proName: "BINANCE:XRPUSDT",
                        title: "XRP/USDT",
                      },
                    ]}
                  ></TickerTape>
                </div>
              </>
            ) : (
              <>
                <div className="p-5"> </div>
              </>
            )}

            <div className="row ps-5 pb-4 adding_pad mobile_adding_pad">
              <div className="col-xl-7 me-5">
                <div className="row pb-4">
                  <div className="col-6 welcome inter_text">
                    Welcome{" "}
                    <b>
                      {fullName.indexOf(" ") === -1 ? (
                        <>{fullName}</>
                      ) : (
                        <>{fullName.substring(0, fullName.indexOf(" "))}</>
                      )}
                      ,
                    </b>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <button
                      className="ps-3 pe-3 mt-2 mb-3  inter_text withdraw_btn"
                      onClick={() => navigate("/withdraw_options")}
                    >
                      Withdraw
                    </button>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <button
                      className="ps-3 pe-3 mt-2 mb-3  inter_text deposit_btn"
                      onClick={() => navigate("/deposit_options")}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="scr-less1200 row back acc-summ-card oc1">
                  <p
                    className="ps-4 pt-4 inter_text"
                    style={{ fontSize: "15px" }}
                  >
                    Account Summary : <br />{" "}
                    <span style={{ fontSize: "36px" }}>
                      ${" "}
                      {startValue.map((i) => {
                        return i;
                      })}
                      <span className="text_design">
                        {endValue.map((i) => {
                          return i;
                        })}
                      </span>{" "}
                    </span>
                  </p>
                  {fa2 ? (
                    <>
                      <div
                        className={`url position-relative inter_text ${page === "Swap" ? "mt-2" : ""
                          }`}
                      >
                        <b>
                          Complete 2 - Factor Authentication to enhace your
                          security
                        </b>
                        <Link to="/qr_verify" className="link_to_fa2">
                          Activate Now
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-5"></div>
                    </>
                  )}
                  <div className={`row ${page === "Swap" ? "" : ""}`}>
                    <div className="col-md-12  mx-0 ">
                      <Swiper
                        modules={[
                          EffectCoverflow,
                          Pagination,
                          Navigation,
                          Autoplay,
                        ]}
                        autoplay={{
                          delay: 1000,
                          disableOnInteraction: false,
                          pauseOnMouseEnter: true,
                        }}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={2}
                        loop={true}
                        coverflowEffect={{
                          rotate: 0,
                          stretch: 0,
                          depth: 300,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                            centeredSlides: true,
                          },
                          400: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            centeredSlides: true,
                          },
                          500: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                            centeredSlides: true,
                          },
                          600: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: -20,
                          },
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        spaceBetween={-20}
                      >
                        {cardInfo.map((i) => {
                          return (
                            <SwiperSlide>
                              <div
                                className="swiper-x-card card back parent_card p-4 pt-2 pb-0 py-5 text-end inter_text"
                                id={`${i.asset.toLowerCase()}card`}
                              >
                                <p>{i.asset}</p>
                                <p className="mb-0">Total :</p>
                                <p
                                  className="text-danger"
                                  style={{
                                    fontSize: "30px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {i.Total_Quote.toFixed(2)}
                                </p>
                                <p className="mb-0">Withdrawable Balance :</p>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "900",
                                  }}
                                >
                                  <span className="text_design">
                                    {i.Usable_Quote.toFixed(2)}
                                  </span>{" "}
                                  USDT
                                </p>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sm_src_margin col-xl-4  p-3 oc2"
                id="dbrcard"
              >
                {page === "Dashboard" ? (
                  <>
                    <div className="row mt-2">
                      <div className="col-12 text-center">
                        <p className="text_dashboard_design">Quick Action</p>
                        <div className="ud-profile-image">
                          <img src={avt} alt="" className="avt-image" />
                          <button
                            className="profile-edit"
                            onClick={(e) => navigate("/profile")}
                          >
                            <img src={profileEdit} alt="Profile edit" />
                          </button>
                        </div>
                        <p
                          className="mt-2"
                          style={{ color: "#000000", fontSize: "16px" }}
                        >
                          {fullName}
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row mt-3">
                          <div className="col-12">
                            <ul className="db-profile-menu">
                              <li>
                                <div className="mt-3">
                                  <div onClick={createRipple}>
                                    <button
                                      className="db-profile-btn action_menu_btn inter_text text-center ud-new-btn ripples"
                                      onClick={() => { navigate("/club") }}
                                    >
                                      <span className="title">Stake</span>
                                      <span className="icon">
                                        <MdArrowForwardIos />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="mt-3">
                                  <button
                                    className="db-profile-btn action_menu_btn inter_text text-center ud-new-btn"
                                    onClick={() => navigate("/transaction")}
                                  >
                                    <span className="title">Buy/Sell</span>
                                    <span className="icon">
                                      <MdArrowForwardIos />
                                    </span>
                                  </button>
                                </div>
                              </li>
                              <li>
                                <div className="mt-3">
                                  <button
                                    className="db-profile-btn action_menu_btn inter_text text-center ud-new-btn "
                                    onClick={() => setPage("Swap")}
                                  >
                                    <span className="title">Swap</span>
                                    <span className="icon">
                                      <MdArrowForwardIos />
                                    </span>
                                  </button>
                                </div>
                              </li> 
                              <li>
                                <div className="mt-3">
                                  <button
                                    className="db-profile-btn action_menu_btn inter_text text-center ud-new-btn"
                                    onClick={() => navigate("/place_order")}
                                  >
                                    <span className="title"> Lend/Borrow</span>
                                    <span className="icon">
                                      <MdArrowForwardIos />
                                    </span>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {page === "Swap" ? (
                      <>
                        <Swap page={() => setPage("Dashboard")} />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="some-glass-effect">
            <div className="container mt-4 mb-3 coin-list">
              <div className="coin-list-header">
                <div className="row table_section upper_table mb-3">
                  <div className="col-12 col-lg-2 ps-5 ms-3">
                    <p className="mt-3">Asset</p>
                  </div>
                  <div className="col-12 col-lg-2">
                    <p className="mt-3">Total Balance</p>
                  </div>
                  <div className="col-12 col-lg-2">
                    <p className="mt-3">Current Price</p>
                  </div>
                  <div className="col-12 col-lg-2">
                    <p className="mt-3">Used</p>
                  </div>
                  <div className="col-12 col-lg-2">
                    <p className="mt-3">Available</p>
                  </div>
                </div>
              </div>
              <div className="coin-list-body">
                <div className="ps-4 pe-2 enable_scroll">
                  {tableInfo.map((i, tIndex) => {
                    return (
                      <div
                        className="row table_section coin-item-row"
                        key={`${i.names}-${tIndex}`}
                      >
                        <div className="col-12 col-lg-1 mt-2">
                          <img
                            className="table_coin me-4"
                            src={i.link}
                            alt="coin_img"
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div className="col-12 col-lg-1 mt-1">
                          <div className="coin-list-label-mobile">
                            <h4 className="text-center x-title">Assets</h4>
                          </div>
                          <div className="row-x asset_dashboard">{i.asset}</div>
                          <div className="row-x names_dashboard">{i.names}</div>
                        </div>
                        <div className="col-12 col-lg-2">
                          <div className="coin-list-label-mobile">
                            <h4 className="text-center x-title">
                              Total Balance
                            </h4>
                          </div>
                          <div className="row-x total_dashboard">
                            {i.asset}
                            {i.total.toFixed(4)}
                          </div>
                          <div className="row-x total_2_dashboard">
                            ${i.Total_Quote.toFixed(4)}
                          </div>
                        </div>
                        <div className="col-12 col-lg-2 total_2_dashboard mt-2 ps-2">
                          <div className="coin-list-label-mobile">
                            <h4 className="text-center x-title">
                              Current Price
                            </h4>
                          </div>
                          $ {i.Price_Quote.toFixed(4)}
                        </div>
                        <div className="col-12 col-lg-2">
                          <div className="coin-list-label-mobile">
                            <h4 className="text-center x-title">Used</h4>
                          </div>
                          <div className="row-x total_dashboard">
                            {i.asset} {i.used.toFixed(4)}
                          </div>
                          <div className="row-x total_2_dashboard">
                            ${i.locked_Quote.toFixed(4)}
                          </div>
                        </div>
                        <div className="col-12 col-lg-2">
                          <div className="coin-list-label-mobile">
                            <h4 className="text-center x-title">Available</h4>
                          </div>
                          <div className="row-x total_dashboard">
                            {i.asset} {(i.total - i.used).toFixed(4)}
                          </div>
                          <div className="row-x total_2_dashboard">
                            ${i.Usable_Quote.toFixed(4)}
                          </div>
                        </div>
                        <div className="col-12 col-lg-1 mt-2">
                          <button
                            className={`btn-x earn_btn_dashboard ${i.asset === "USDT" || i.asset === "BUSD"
                              ? "ps-3 pe-2"
                              : " ps-4 pe-4"
                              }`}
                            onClick={() => { onEarnClick(i.asset) }}
                          >
                            {/* {isRipple ? <span 
                            // style={{
                            //   left:coords.x,
                            //   top:coords.y, 
                            // }} 
                            className="Ripple" /> : ""} */}
                            Earn
                            {i.asset === "USDT" || i.asset === "BUSD"
                              ? "7%"
                              : ""}
                          </button>
                        </div>
                        <div className="col-12 col-lg-1 mt-2">
                          <button
                            className={`btn-x borrow_btn_dashboard me-1 ${i.asset === "USDT" || i.asset === "BUSD"
                              ? "ps-3 pe-3"
                              : "ps-2 pe-2"
                              }`}
                            onClick={() => onLendClick(i.asset)}
                          >
                            {i.asset === "USDT" || i.asset === "BUSD"
                              ? "Lend"
                              : "Borrow"}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <SmallFooter />
        </div>
      )}
      <Modal
        show={showDeposit}
        onHide={() => setShowDeposit(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Low Balance !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            Your Account don't have enough Balance.
            <br />
            Kindly Deposit Assets in your Account.
          </p>
          <div className="text-center">
            <button
              className="ps-3 pe-3 mt-3 mb-3 inter_text deposit_btn"
              onClick={() => navigate("/deposit_options")}
            >
              Deposit Now
            </button>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={15}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowDeposit(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showSwap}
        onHide={() => setShowSwap(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Low Balance !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            You don't have enough {currAsset} in your account.
            <br />
            Kindly Deposit or Swap to get {currAsset}.
          </p>
          <div className="row mb-3">
            <div className="col-xl-5">
              <button
                className="ps-3 pe-3 mt-3 inter_text withdraw_btn"
                onClick={() => {
                  setPage("Swap");
                  setShowSwap(false);
                }}
              >
                Swap Now
              </button>
            </div>
            <div className="col-xl-5">
              <button
                className="mt-3 inter_text deposit_btn"
                onClick={() => navigate("/deposit_options")}
              >
                Deposit
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={15}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowSwap(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Dashboard;
