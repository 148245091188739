import React, { useEffect, useState } from "react";
import { fetchToken } from "../../Auth";
import usdtimg from "./XTVCUSDT.svg";
import "./index.css";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import { Modal } from "react-bootstrap";

const Fields = (props) => {

  const [value, setValue] = useState();
  const [amount, setAmount] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetch("https://api.flitchcoin.com/api/agreement/", {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      }
    }).then((res) => res.json()
      .then((result) => {
        if (result !== null) {
        } else {
          setShow(true);
        }
      }))
      .catch((err) => {
        console.log(err);
      });
  }, [])
  const onChangeValues = (e) => {
    if (e.target.value >= 7 && e.target.value <= 365) {
      setValue(e.target.value);
    }
  };

  const onChangeAmount = (e) => {
    if (e.target.value > 20) {
      setAmount(e.target.value);
    }
  };

  const [lt, setLt] = useState(0);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(false);
  const [priceInUsd, setPriceInUsd] = useState(0);

  const price = async () => {
    if (props.sym) {
      const sym = (props.sym).toLowerCase();
      const query = new URLSearchParams({
        value: '0',
        from: 'usd'
      }).toString();

      const ticker = sym;
      const resp = await fetch(
        `https://api.cryptapi.io/${ticker}/convert/?${query}`,
        { method: 'GET' }
      );

      const data = await resp.text();
      setPriceInUsd(Number(JSON.parse(data).exchange_rate));
    }
  };

  const [amountToBeConverted, setAmountToBeConverted] = useState(0);

  const getLimit = () => {
    fetch('https://api.flitchcoin.com/api/account', {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((res) => res.json()
        .then((result) => {
          const data = (Object.values(result));
          for (let i = 0; i < data.length; i++) {
            if (data[i].asset === props.sym) {
              setLt(data[i].total - data[i].used);
            }
          }
        }))
      .catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    setAmountToBeConverted(amount * priceInUsd);
  }, [amount]);

  const [repDate, setRepDate] = useState();
  const dateSet = () => {
    if (value) {
      const data = JSON.stringify({
        days: value,
      });
      fetch("https://api.flitchcoin.com/api/repay/date", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((res) =>
          res.json().then((result) => {
            setRepDate(result.substring(0, result.indexOf("T")));
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    dateSet();
  }, [value]);

  useEffect(() => {
    getLimit();
    price();
  }, [props.sym]);

  useEffect(() => {
    setTimeout(() => {
      setShowA(false);
      setShowB(false);
      setShowC(false);
    }, 3000)
  }, [showA, showB, showC]);

  return (
    <>
      
      <p className="earn_subhead totalinacc-heading">
        Total in Account : ${" "}
        {(priceInUsd * lt).toFixed(4) === "NaN" ? (
          <>0</>
        ) : (
          <>{priceInUsd * lt}</>
        )}
      </p>
      <div className="col-12">
        <div className="row">
          <div className="col-3">
            <button className="pb1" onClick={() => setAmount(priceInUsd * lt * 0.1)}>10%</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setAmount(priceInUsd * lt * 0.25)}>25%</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setAmount(priceInUsd * lt * 0.5)}>50%</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setAmount(priceInUsd * lt)}>100%</button>
          </div>
        </div>
        <div className="row mt-3 mb-2">
        <input type="range" class="range-style"name="range" id="range" min="0" max={priceInUsd * lt} step={0.01 * amount} value={amount} onChange={(e) => setAmount(e.target.value)} />
        </div>
        <div className="input1 w-100">
          <input
            type="number"
            name="amount"
            placeholder="Amount:"
            className="input_earn w-100"
            value={amount}
            onChange={onChangeAmount}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-3 ">
            <button className="pb1" onClick={() => setValue(30)}>1 M</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setValue(90)}>3 M</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setValue(180)}>6 M</button>
          </div>
          <div className="col-3">
            <button className="pb1" onClick={() => setValue(365)}>1 Yr</button>
          </div>
        </div>
        <div className="row">
        <input type="range" class="range-style" name="range" id="range" value={value} min='7' max='365' onChange={onChangeValues}/>
        </div>
        <div className="input1 w-100 mt-2">
          <input
            type="number"
            name="duration"
            placeholder="Duration:  Days"
            className="input_earn w-100"
            value={value}
            onChange={onChangeValues}
          />
        </div>
        <div className="row earn_subhead" style={{ fontSize: "14px" }}>
          <p>Withdraw Date : {repDate === undefined ? <>- - -</> : <>{repDate}</>}</p>
        </div>
      </div>
      <div className="row earn_subhead" style={{ fontSize: "14px" }}>
        <p>Total Payble Cost : {(amount / priceInUsd).toFixed(6) === "NaN" ? <>0</> : <>{(amount / priceInUsd).toFixed(6)} </>} {props.sym}</p>
      </div>
      <div className="row text-center pe-0" style={{marginLeft: "-5px"}}>
          <button
            className="primary round-btn newpo_btn w-100"
            onClick={() => setShowC(true)}
          ><div className="d-flex justify-content-center align-items-center btnitems p-0">
              <div className="flex-fill"><img src={usdtimg} className="img_btn" ></img></div>
              <div className="flex-fill"> EARN 7%</div>
              <div className="flex-fill"><i class="fa fa-chevron-right" aria-hidden="true"></i></div></div>
          </button>
        </div>
        <Modal
        show={showA}
        onHide={() => setShowA(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">Please Enter an amount greater than $20 to proceed with the order.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowA(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showB}
        onHide={() => setShowB(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Terms Declined !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">Please accept the terms to proceed with order.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowB(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showC}
        onHide={() => setShowC(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Alert !</p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">This service is only available for beta version.</p>
          <div className='d-flex justify-content-center pb-4'>
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return <img src={cross} className="cross" onClick={() => setShowC(false)} />
              }}

            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Fields