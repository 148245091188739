import React, { useEffect, useState } from "react";
import "./index.css";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';

const WithdrawOptions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  function createRipple(event) {
    const button = event.currentTarget;
  
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
  
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");
   button.appendChild(circle);
  }

  return (
    <>
    <Helmet>
        <title>Flexible Withdraw Options: Choose the Best Method for Your Crypto Needs</title>
        <meta name="description" content="Our withdraw options page offers a range of flexible methods for transferring your crypto funds, allowing you to choose the option that best meets your needs. From bank transfers to digital wallets, our platform supports a variety of withdrawal methods to ensure maximum convenience and flexibility for our users. With fast and secure transactions guaranteed, you can trust our platform to deliver your funds quickly and efficiently, no matter which withdrawal option you choose." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="withdraw-options">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard1">
                    <h3>I want to keep cryptocurrencies</h3>
                    <h2 className="depo-gradient1">
                      Connect Wallet & Withdraw Crypto-currencies
                    </h2>
                    <a className="ud-proceed-btn card1-proceed-btn ripples">
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/shieldimg.webp"
                      alt=""
                      className="shield-img"
                    />
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/metamaskimg.webp"
                      alt=""
                      className="metamask-img"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard2">
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/card2img1.webp"
                      alt=""
                      className="card2img1"
                    />
                    <h3>
                      I want to sell cryptocurrency & receive money in my bank
                      account
                    </h3>
                    <h2>Sell & Withdraw to my Bank account </h2>
                    <a href="#" className="ud-proceed-btn  card2-proceed-btn ripples">
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard3">
                    <h3>I want to keep cryptocurrencies</h3>
                    <h2>Withdraw to My Crypto Wallet address </h2>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/card3img1.webp"
                      alt=""
                      className="card3img1"
                    />
                    <a
                      onClick={() => navigate("/withdraw")}
                      className="ud-proceed-btn card3-proceed-btn ripples"
                    >
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/qrimg.webp"
                      alt=""
                      className="qrimg"
                    />
                  </div>
                </div>
                <img
                  src="https://flitchcoin.s3.eu-west-2.amazonaws.com/Card+5+1.webp"
                  alt=""
                  className="debitcard-img"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WithdrawOptions;
