import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../Feature/Auth/authSlice";
import { Modal } from "react-bootstrap";
import Loader from "../Loader";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import { fetchToken } from "../../Auth";
import { Helmet } from 'react-helmet';

function Forgot_Pass() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });
    const { username, password } = formData;
    const [msg, setMsg] = useState();
    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);

    const submitHandler = (e) => {
        setLoading(true);
        e.preventDefault();
        if ((username === "") || (password === "")) {
            setLoading(false);
            setShowA(true);
        } else {
            var data = JSON.stringify({
                "username": username,
                "password": password,
                "is_pool": true
            })
            fetch("https://api.flitchcoin.com/api/forgot/Signup", {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            }).then(res => res.json())
                .then((data) => {
                    if ((data.status) === 200) {
                        setShow(true);
                        dispatch(loginUser(formData));
                        setMsg(data.msg);
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        }
    };

    const onChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const [show, setShow] = useState(false);
    const [otp, setOtp] = useState("");

    const otpHandler = (e) => {
        setLoading(true);
        e.preventDefault();
        if (otp === "") {
            setLoading(false);
            setShowB(true);
        } else {
            var data = JSON.stringify({
                "otp": Number(otp),
                "add": msg,
                "types": "email"
            })
            fetch("https://api.flitchcoin.com/api/forgot/verify_email", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            }).then(res => res.json())
                .then((data) => {
                    if ((data.status) === 200) {
                        dispatch(loginUser(formData));
                        navigate('/login')
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setShowA(false)
            setShowB(false)
        }, 3000)
    }, [showA, showB]);

    const [type, setType] = useState(false);

    const getInfo = () => {
        fetch('https://api.flitchcoin.com/api/users/me/items/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                const data = JSON.stringify({
                    "emailid": res.username
                })
                fetch('https://api.flitchcoin.com/api/userchrono_info', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: data
                }).then(res => res.json())
                    .then((data) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            username: data.username,
                        }));
                        if (data.types === "quick") {
                            setType(true);
                        }
                        setLoading(false);
                    }).catch((err) => {
                        console.log(err);
                    })
            })).catch((err) => {
                console.log(err);
            })
    };
    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
        <Helmet>
        <title>Password Recovery for Your Cryptocoin Account: Easily Reset Your Password and Regain Access</title>
        <meta name="description" content="Effortless Cryptocoin Account Recovery: Regain Access to Your Account by Resetting Your Password Quickly and Securely. Safeguard Your Digital Assets Today!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
            {
                loading ?
                    <Loader /> :
                    <>
                        <div className="login-2-page">
                            <div className="container">
                                <div className="row login-row">
                                    <div className="col-xs-12 col-sm-12 col-lg-6">
                                        <h3 className="page-title login-title">{type ? <></> : <>Forgot</>} Password</h3>
                                        <div className="login-wrapper form-wrapper">
                                            <form className="false" onSubmit={submitHandler}>
                                                {
                                                    type ?
                                                        <></>
                                                        :
                                                        <div className="form-group">
                                                            <label for="" className="form-label">Personal Email</label>
                                                            <input name="username" type="email" value={username} placeholder="Enter your Email" className="form-control undefined" onChange={onChange} />
                                                        </div>
                                                }
                                                <div className="form-group">
                                                    <label for="" className="form-label">{type ? <>New</> : <>Reset</>} Password</label>
                                                    <input name="password" value={password} type="password" placeholder="Enter Password" className="form-control undefined" onChange={onChange} />
                                                </div>
                                                <div className="form-group mt-60px">
                                                    <div className="d-grid">
                                                        <button type="submit" className="block btn btn-spl-primary">Next</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-lg-6 login-a-center">
                                        <div className="stay-safe-wrapper">
                                            <div className="stay-safe">
                                                <img src="/static/media/stay-safe.c760e45312ca564a61ecd9a9de25c641.svg" alt="Stay and Safe" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
                            Enter The OTP
                        </p>
                        <div className="event_line"></div>
                    </div>
                    <div className="form-group p-3 pb-0">
                        <input
                            name="otp" value={otp} onChange={(e) => setOtp(e.target.value)}
                            type="text"
                            className="form-control undefined"
                        />
                    </div>
                    <div className="d-flex justify-content-between ms-5 me-5 pb-4 mt-4">
                        <button
                            type="button"
                            className="btn btn-spl-black ps-3 pe-3"
                            onClick={() => setShow(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-spl-black ps-3 pe-3"
                            onClick={otpHandler}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showA}
                onHide={() => setShowA(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Unfilled !</p>
                        <div className="event_line"></div>
                    </div>
                    <p className="popup_error_body_text pt-4 pb-3">Please enter the details to proceed.</p>
                    <div className='d-flex justify-content-center pb-4'>
                        <CountdownCircleTimer
                            size={40}
                            strokeWidth={3}
                            isPlaying
                            duration={3}
                            colors={"red"}
                            colorsTime={[3, 0]}
                        >
                            {({ remainingTime }) => {
                                return <img src={cross} className="cross" onClick={() => setShowA(false)} />
                            }}

                        </CountdownCircleTimer>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showB}
                onHide={() => setShowB(false)}
                backdrop="static"
                keyboard={false}
                className="modal-dialog-coin"
            >
                <div className="popup_error">
                    <div className="popup_error_head">
                        <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                        <div className="event_line"></div>
                    </div>
                    <p className="popup_error_body_text pt-4 pb-3">Please enter the OTP.</p>
                    <div className='d-flex justify-content-center pb-4'>
                        <CountdownCircleTimer
                            size={40}
                            strokeWidth={3}
                            isPlaying
                            duration={3}
                            colors={"red"}
                            colorsTime={[3, 0]}
                        >
                            {({ remainingTime }) => {
                                return <img src={cross} className="cross" onClick={() => setShowB(false)} />
                            }}

                        </CountdownCircleTimer>
                    </div>
                </div>
            </Modal>
        </>

    );
}

export default Forgot_Pass;
