import React, { useState, useEffect } from 'react';
import { fetchToken } from "../../Auth";
import { QRCodeSVG } from 'qrcode.react';
import statement from "./Group 87.png";
import setting from "./Group 97.png";
import line from "./Line 18.png";
import dropdown from "../../Polygon 2.png";
import Timer from "./timer";
import { Collapse, Modal } from "react-bootstrap";
import networks from "./networks.json";
import tick_img from "./tick.svg";
import copy from "./Group 223.svg";
import green_tick from "./Group 224.svg";
import Loader from "../Loader";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { faArrowRight, faChevronDown, faChevronRight, faChevronUp, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoCurrencyInfo from '../../components/CryptoCurrencyInfo';
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import LoadingButton from '../../elements/LoadingButton';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { Helmet } from 'react-helmet';

const Deposit = () => {

  const [coin, setCoin] = useState("Select coin");
  const [qr, setQr] = useState("");
  const [call, setCall] = useState("");
  const [tick, setTick] = useState("");
  const [memo, setMemo] = useState("");
  const [show, setShow] = useState(false);
  const [walletAdd, setWalletAdd] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [coinImg, setCoinImg] = useState("");
  const [network1, setNetwork1] = useState("");
  const [currentState, setCurrentState] = useState("Choose");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedB, setIsCopiedB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [currentNetwork, setCurrentNetwork] = useState(0);
  const [currentNetwork2Info, setCurrentNetwork2Info] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);

  const changeNetwork2Status = () => {
    setCurrentNetwork2Info((old) => !old)
  }


  const changeCurrentNetwork = () => {
    setCurrentNetwork((oldState) => {
      return oldState + 1;
    })
  }

  const createOrder = () => {
    setloadingButton(true);
    var data = JSON.stringify({
      "coin": coin,
      "network": network1,
      "priority": "string",
      "post": false
    })
    fetch("https://api.flitchcoin.com/api/crypt/deposit", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`
      },
      body: data,
    })
      .then((res) => res.json()
        .then(async (result) => {
          setCall(result.callback_url);
          setTick(result.ticker);
          setMemo(result.memo);
          setWalletAdd(result.address_in);
          const query = new URLSearchParams({ callback: result.callback_url }).toString();
          const resp = await fetch(
            `https://api.cryptapi.io/${result.ticker}/logs/?${query}`,
            { method: 'GET' }
          );
          const data = await resp.json();
          setQr(data.address_in);
          setCurrentState("Verify QR code");
          changeCurrentNetwork();
          setloadingButton(false);
        }))
      .catch((err) => {
        console.log(err);
      });
  };

  const validateOrder = () => {
    setLoading(true);
    var data = JSON.stringify({
      "callback": call,
      "memo": memo,
      "ticker": tick,
    })
    fetch("https://api.flitchcoin.com/api/crypt/deposit", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`
      },
      body: data,
    })
      .then((res) => res.json()
        .then((result) => {
          setLoading(false);
          setShow(true);
          setTimeout(function () {
            setShow(false);
          }, 5000);

        }))
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteOrder = () => {
    setLoading(true);
    var data = JSON.stringify({
      "callback": call,
      "memo": memo,
      "ticker": tick,
    })
    fetch("https://api.flitchcoin.com/api/crypt/deposit", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`
      },
      body: data,
    })
      .then((res) => res.json()
        .then((result) => {
          setLoading(false);
          window.location.reload();
        }))
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (qr !== "") {
      var intervalID = setInterval(async function () {
        const query = new URLSearchParams({ callback: call }).toString();
        const resp = await fetch(
          `https://api.cryptapi.io/${tick}/logs/?${query}`,
          { method: 'GET' }
        );
        const data = await resp.json();
        if (data.callbacks.length) {
          validateOrder();
          clearInterval(intervalID);
        }
      }, 3000);
      setTimeout(function () {
        clearInterval(intervalID);
        deleteOrder();
      }, 1800000);
    }
  }, [qr]);

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false)
      setIsCopiedB(false)
    }, 3000);
  }, [isCopied])



  const hoursMinSecs = { minutes: 30, seconds: 0 };

  let circularProgressTime = (hoursMinSecs.minutes * 60 + hoursMinSecs.seconds)

  return (
    <>
    <Helmet>
        <title>Seamless Cryptocoin Deposits: Effortlessly Fund Your Account and Start Investing in Cryptocurrencies</title>
        <meta name="description" content="Easy and Secure Cryptocoin Deposits: Start Investing in Cryptocurrencies with Confidence by Making Hassle-free Deposits into Your Account. Explore Our Simple Deposit Process Today!"/>
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {
        loading ?
          <>
            <Loader />
          </> :
          <>
            {!show ? (
              <>
                <div className="container mt-5 mb-5">
                  <div className="row">
                    <div className="col-xl-3 pt-5 mt-5 roadmap ">
                      {(currentNetwork >= 0) && <div className="road-map-item" data-step="0">
                        <i className={`pe-4 pt-2 mt-1 dropdown-qr ${currentState === "Choose" ? "selected-item" : ""}`}>
                          <img src={statement} style={{ height: "32px", width: "32px" }} alt="choose" />{" "}
                          &nbsp; Choose
                        </i>
                        <div className="d-flex">
                          <img src={line} alt="line" className="link_deposit  ms-xl-4" />
                          <div className="d-flex content-network">
                            <p className="mb-0">
                              **Choose desired Crypto to deposit and cautiously select respective network from which you want to deposit assets from drop-down menu
                            </p>
                          </div>
                        </div>
                      </div>}
                      {
                        (currentNetwork >= 1) && (
                          <div className="road-map-item" data-step="1">
                            <i className={` pt-2 mt-1 dropdown-qr ${currentState === "Setup Auth" ? "selected-item" : ""}`}>
                              <img src={setting} style={{ height: "25px", width: "25px" }} alt="choose" />{" "}
                              &nbsp; Confirm
                            </i>
                            <div className="d-flex">
                              <img src={line} alt="line" className="link_deposit ms-xl-4" />
                              <div className="d-flex content-network">
                                <p className="mb-0">
                                  **After choosing Network and Coin please press confirm button to proceed.
                                  Once you click you will see details and timer in box adjacent to confirm button.
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        (currentNetwork >= 2) && (
                          <div className="road-map-item" data-step="2">
                            <i className={` pt-2 mt-1 dropdown-qr ${currentState === "Verify QR code" ? "selected-item" : ""}`}>
                              <img src={setting} style={{ height: "25px", width: "25px" }} alt="choose" />{" "}
                              &nbsp; Scan and Pay
                            </i>
                            <div className="d-flex">
                              <img src={line} alt="line" className="link_deposit ms-xl-4" />
                              <div className="d-flex content-network">
                                <p className="mb-0">
                                  **Finally open your desired wallet app and select desired crypto to transfer and than scan the Qr code to transfer the amount & be cautious while selecting Network additionally be informed that you should initiate transfer within given time excluding network transfer time.
                                </p>
                              </div>
                            </div>

                          </div>
                        )
                      }
                      {
                        (currentNetwork >= 3) && (
                          <div className="road-map-item" data-step="3">
                            <i className={` pt-2 mt-1 dropdown-qr ${currentState === "Payment" ? "selected-item" : ""}`}>
                              <img src={setting} style={{ height: "25px", width: "25px" }} alt="choose" />{" "}
                              &nbsp; Deposit Successful
                            </i>
                          </div>
                        )
                      }



                    </div>
                    <div className="col-xl-5 pe-5">
                      <p className="api_head">
                        I want to <span className="text_design">Deposit :</span>
                      </p>
                      <div className="col-12 mb-3 btn-group">
                        <button
                          type="button"
                          className="btn w-100 round-btn pt-2 pb-2"
                          onClick={() => setShowModal(true)}
                        >
                          <div className="row">
                            <div className="col-2">
                              {coinImg === "" ? (
                                <></>
                              ) : (
                                <>
                                  <img
                                    src={coinImg}
                                    className="select_img"
                                    alt="coin"
                                  />
                                </>
                              )}
                            </div>
                            <div className="col-8 text-center">
                              <b>
                                {coin}
                                {network1 === "" ? <></> : <> / {network1}</>}
                              </b>
                            </div>
                            <div className="col-2 text-center">
                              <img src={dropdown} alt="drop" />
                            </div>
                          </div>
                        </button>
                      </div>
                      <p className="api_text pt-4">
                        Select your preferred coin for depositing your
                        cryptocurrencies to your account.
                      </p>
                      <div className="row">
                        <div className="col-12">
                          {currentState === "Choose" ? (
                            <></>
                          ) : (
                            <>
                              {currentState === "Setup Auth" ? (
                                <>
                                  <LoadingButton loading={loadingButton} className='round-btn mt-4'>
                                    <button
                                      className="primary  round-btn  confirm-procees-deposit"
                                      onClick={createOrder}
                                    >
                                      Confirm & Proceed&nbsp;&nbsp;›
                                      <FontAwesomeIcon icon={"fa-regular fa-chevron-right"} />
                                    </button>
                                  </LoadingButton>

                                  <br />
                                </>
                              ) : (
                                <>
                                  {currentState === "Verify QR code" ||
                                    currentState === "Payment" ? (
                                    <>
                                      <div>
                                        <div className="row">
                                          <div className="col-12 col-lg-6">
                                            <a href="javascript:void(0)" className='g-precaution' onClick={e => changeNetwork2Status()}>
                                              Show General precuations &nbsp;
                                              <FontAwesomeIcon icon={currentNetwork2Info ? faChevronUp : faChevronDown} />
                                            </a>
                                          </div>
                                          <div className="col-12 col-lg-6 text-end">
                                            <a href="" className='deposite-spl-next'>
                                              Deposit here &nbsp;
                                              <FontAwesomeIcon icon="fa fa-regular fa-arrow-right" />
                                              <FontAwesomeIcon icon={faArrowRight} className="desposite-spl-next" />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div>
                        {
                          !([0, 1].includes(currentNetwork)) &&
                          <>
                            <Collapse in={currentNetwork2Info}>
                              <div className='content'>
                                <CryptoCurrencyInfo />
                              </div>
                            </Collapse>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-xl-4">
                      {
                        currentNetwork === 0 && (
                          <div className='row'>
                            <div className="col-12">
                              <div className="intro-content">
                                <h4 className=' fw-normal text-end'>
                                  I don't  have
                                  &nbsp;<span className='fw-bold'>
                                    Cryptocurrencies
                                  </span>
                                </h4>
                                <h4 className=' text-end'>
                                  I want to
                                  &nbsp;<span className='a-t-s fw-bold'>
                                    Buy Crypto
                                  </span>
                                </h4>
                                <div className='d-flex mt-5 justify-content-between ms-5 ps-5'>
                                  <a className="btn-spl-outline-1">
                                    Sell
                                  </a>
                                  <a href="" className="confirm-procees-deposit btn-round-0 ">
                                    Buy
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        currentNetwork === 2 && (
                          <div className="card back special_card_deposit pb-5 mt-2 p-4">
                            <h2 className="a-t-s fw-bold text-center">Pay Here</h2>
                            <div className="text-center">
                              <svg width="0" height="0" >
                                <defs>
                                  <linearGradient id="circle-gradient" x1="1" y1="0" x2="0" y2="0">
                                    <stop offset="0%" stopColor="#46FFBC" />
                                    <stop offset="100%" stopColor="#00000" />
                                  </linearGradient>
                                </defs>
                              </svg>
                              <div className='d-flex justify-content-center'>
                                <CountdownCircleTimer
                                  size={260}
                                  strokeWidth={12}
                                  isPlaying
                                  duration={circularProgressTime}
                                  colors={`url(#circle-gradient)`}
                                  colorsTime={[circularProgressTime, 0]}
                                >
                                  {({ remainingTime }) => {
                                    return <QRCodeSVG value={qr} className="" size={150} />
                                  }}

                                </CountdownCircleTimer>
                              </div>
                              {qr !== "" && !show ? (
                                <>
                                  <Timer hoursMinSecs={hoursMinSecs} />
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="row mt-5">
                                <div className="text-start mt-3">
                                  Cryptocurrency : {coin !== "Select coin" ? <>{coin}</> : <>- - -</>}
                                </div>
                                <div className="text-start mt-3">Network :
                                  {network1 !== "Select Network" ? (
                                    <> {network1}</>
                                  ) : (
                                    <> - - -</>
                                  )}
                                </div>
                                <div className="text-start mt-3">
                                  Wallet Address : {walletAdd === "" ? <> - - -</> : <>{walletAdd.substring(0, 20)}... <span onClick={() => setIsCopied(true)}>{isCopied ? <img src={green_tick} className="copy_img" /> : <img src={copy} className="copy_img" onClick={() => { navigator.clipboard.writeText(walletAdd) }} />}</span></>}
                                </div>
                                <div className="text-start mt-3">Memo : N/A</div>
                                <div className="text-start mt-3">
                                  Flitch txn : {memo === "" ? <>- - -</> : <>{memo} <span onClick={() => setIsCopiedB(true)}>{isCopiedB ? <img src={green_tick} className="copy_img" /> : <img src={copy} className="copy_img" onClick={() => { navigator.clipboard.writeText(memo) }} />}</span></>}
                                </div>
                              </div>
                            </div>
                            {qr !== "" && !show ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-spl-black p-3 w-50 mt-5"
                                  onClick={deleteOrder}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      }

                    </div>
                  </div>
                </div>
                <p
                  className="text-center ps-5 pe-5 mb-3"
                  style={{ fontSize: "10px", marginTop: "11.5rem" }}
                >
                  **Filtchcoin by any means is not responsible in case of any failure
                  of the above provided third-party services and in any case of human
                  error flitchcoin.com doesn’t posses any liability to the user or
                  consumers of flitchcoin or cryptapi.
                </p>
                {/* <div id="ramp-container" style={{width: "895px", height:"590px"}}></div> */}

              </>
            ) : (
              <>
                <div class="deposit-card">
                  <div className="card-title d-flex align-items-center">
                    <div className="icon">
                      <img src={tick_img} alt="tick" />
                    </div>
                    <h2>
                      Deposit Successful <span className="symbol">!</span>
                    </h2>
                  </div>
                  <div className="card-maincontent">
                    <h3>
                      Memo <span className="colon">:</span> {(memo).substring(0, 10)} . . .
                    </h3>
                    <h3>
                      Ticker <span className="colon">:</span> {tick}
                    </h3>
                    <div className="transaction-details">
                      <div className="tnxid">
                        <h3>
                          Address <span className="colon">:</span> {(walletAdd).substring(0, 10)} . . .
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="depositcard-footer">
                    <span className="sidedesign"></span>
                    <h4>Total Transfer</h4>
                    <span className="amount">
                      $10. <span className="decimal">025</span>
                    </span>
                  </div>
                  <div class="design designtop">
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                  </div>
                  <div class="design designbottom">
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                  </div>
                </div>
              </>
            )}
          </>
      }
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="coin_popup">
          <div className="popup_coin_upper_section p-3">
            <div className="row">
              <div className="text-end" onClick={() => setShowModal(false)}><img src={cross} className="cross" onClick={() => setShowModal(false)} /></div>
              <div className="text-start inter_text" style={{ fontSize: "20px", fontWeight: "500", marginTop: "-20px" }} onClick={() => setShowModal(false)}>Select a Token</div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><img src={search} style={{ height: "18px" }} /></span>
                <input type="text" class="form-control" onChange={(e) => setSearchParam(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <p
                  className="text-end"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  <span className="text_design">Cryptocurrency</span>
                </p>
              </div>
              <div className="col-5">
                <p
                  className="text-start"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  <span className="text_design">Network</span>
                </p>
              </div>
            </div>
          </div>

          <ul className="coin_pop  mb-4 p-3 pt-3 ">
            {networks.network.map((items) => {
              if (items.coin.toLowerCase().includes(searchParam.toLowerCase()) ||
                items.net.toLowerCase().includes(searchParam.toLowerCase())) {
                return (
                  <>
                    <div
                      className="row coin_hover mb-3 pt-3 pb-3"
                      onClick={() => {
                        setShowModal(false);
                        setCoin(items.coin);
                        setCoinImg(items.img);
                        setNetwork1(items.net);
                        setCurrentState("Setup Auth");
                        changeCurrentNetwork()
                      }}
                    >
                      <div className="row text-center ps-4">
                        <li className="coin-list">
                          <img
                            src={items.img}
                            alt="coin"
                            className="select_img"
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="pt-2 coin-name">{items.coin}</span>
                          <span className='ps-5 pt-2 coin_subname'>{items.net}</span>
                        </li>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default Deposit