import React from "react";
import "../Styles/_button.scss"
const LoadingButton = ( { id,className, children, display='d-table', loading=false, overflow=true, ...props }) => {
  return (
    <div className={`loading-button ${className} ${loading && 'loading'} ${display} ${overflow && 'l-overflow'}`}>
      <div className="loading-button-content">
        { children }
      </div>      
      {
        loading && (
          <div className={`loading-progress ${id}`}>
            <div className="dot-flashing"></div>
          </div>
        )
      }
    </div>
  )
}

export default LoadingButton;