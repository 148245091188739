import React,{useState,useEffect} from 'react'
import { SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'

const Swap = () => {


    return (
        <div className="Uniswap">
            <SwapWidget/>
        </div>
    )
}

export default Swap