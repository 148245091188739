import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";
import Order from "../../pages/Order";
import PoolParticipant from "../../pages/PoolParticipant";
import Api from "../../pages/Api";
import Dashboard from "../../pages/Dashboard";
import QRVerify from "../../pages/QRVerify";
import ForgotPass from "../../pages/Forgot_Pass";
import ProtectedRoutes from "../../pages/ProtectedRoutes";
import Withdraw from "../../pages/Withdraw";
import Profile from "../../pages/Profile";
import Statements from "../../pages/Statements";
import Settings from "../../pages/Settings";
import Protection from "../../pages/Protection";
import Deposit from "../../pages/Deposit";
import AddWallet from "../../pages/Add_Wallet";
import PageNotFound from "../../pages/PageNotFound";
import Club from "../../pages/Club";
import Referral from "../../pages/Referral";
import Login2 from '../../pages/SignIn/Login';
import SignUp2 from '../../pages/SignUp/SignUp';
import Register from '../../pages/Register/index';
import Web3 from "../../Web3/index";
import DepositOptions from "../../pages/DepositOptions";
import WithdrawOptions from "../../pages/WithdrawOptions";
import Ramp from "../../pages/Ramp";
import Wyre from "../../pages/Wyre/index";
import Wyre_link from "../../pages/Wyre/link";
import Instant_Signup from "../../pages/Instant_Signup";
import Staking from "../../pages/Staking";
import Swap from "../../pages/Swap";

function Content() {
  return (
    <div >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sign-up" element={<ProtectedRoutes><SignUp2 /></ProtectedRoutes>} />
        <Route exact path="/register-step" element={<ProtectedRoutes><Register /></ProtectedRoutes>} />
        <Route exact path="/login" element={<ProtectedRoutes><Login2 /></ProtectedRoutes>} />
        <Route exact path="/dashboard" element={<Protection Cmp={Dashboard} />} />
        <Route exact path="/place_order" element={<Protection Cmp={PoolParticipant} />} />
        <Route exact path="/Api" element={<Protection Cmp={Api} />} />
        <Route exact path="/order" element={<Protection Cmp={Order} />} />
        <Route exact path="/profile" element={<Protection Cmp={Profile} />} />
        <Route exact path="/statements" element={<Protection Cmp={Statements} />} />
        <Route exact path="/settings" element={<Protection Cmp={Settings} />} />
        <Route exact path="/withdraw" element={<Protection Cmp={Withdraw} />} />
        <Route exact path="/deposit" element={<Protection Cmp={Deposit} />} />
        <Route exact path="/qr_verify" element={<Protection Cmp={QRVerify} />} />
        <Route exact path="/add_wallet" element={<Protection Cmp={AddWallet} />} />
        <Route exact path="/club" element={<Protection Cmp={Club} />} />
        <Route exact path="/referral" element={<Protection Cmp={Referral} />} />
        <Route exact path="/web3" element={<Protection Cmp={Web3} />} />
        <Route exact path="/deposit_options" element={<Protection Cmp={DepositOptions} />} />
        <Route exact path="/withdraw_options" element={<Protection Cmp={WithdrawOptions} />} />
        <Route exact path="/ramp" element={<Protection Cmp={Ramp} />} />
        <Route exact path="/wyre" element={<Protection Cmp={Wyre} />} />
        <Route exact path="/staking" element={<Protection Cmp={Staking} />} />
        <Route exact path="/swap" element={<Protection Cmp={Swap} />} />
        <Route exact path="/wyre_link" element={<Protection Cmp={Wyre_link} />} />
        <Route exact path="/forgot_password" element={<ForgotPass />} />
        <Route exact path="/quick/access" element={<Instant_Signup />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default Content;
