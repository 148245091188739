import React,{useState} from 'react';
import { Helmet } from 'react-helmet';

const Staking = () => {

  const [formData, setFormData] = useState({
    sym: "",
    AssetAmount: "",
    Withdraw_Address: "",
  })

  const { sym, AssetAmount, Withdraw_Address } = formData;
  const [validatorKey,setValidatorKey] = useState('');

  const onChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-API-KEY': 'HoaZWny4tuNyvh03zVYzSNHkfmHWOUvTcS74njwqhEDspuhg'
          },
        body: JSON.stringify({
          stakes: [
            {
              amount: AssetAmount,
              withdrawal_address: Withdraw_Address,
              quantity: 0,
              fee_recipient: '0xAEAFF3EACDB09d2aAd205Fd5d501bd1dFd1eEBBD'
            }
          ],
        })
      };
      
      fetch('https://svc.blockdaemon.com/boss/v1/ethereum/sepolia/stake-intents', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'X-API-KEY': 'HoaZWny4tuNyvh03zVYzSNHkfmHWOUvTcS74njwqhEDspuhg'
        },
        body: JSON.stringify({
          validator_identifier: {
            validator_public_key: validatorKey,
            validator_index: 25
          }
        })
      };
      
      fetch('https://svc.blockdaemon.com/boss/v1/ethereum/sepolia/voluntary-exit', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
  }

  return (
    <div className='container'>
            <Helmet>
        <title>Maximizing Crypto Investments: The Ultimate Guide to Stacking Coins</title>
        <meta name="description" content="Looking to take your crypto investments to the next level? Stacking coins could be the solution you're looking for. Our comprehensive guide to stacking covers everything you need to know about this popular strategy, from how it works to the best coins to stack. Whether you're a seasoned investor or just getting started in the world of crypto, our stacking page has the information you need to make informed decisions and maximize your profits." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
        <h1 className="text-center">STAKING</h1>
      <form onSubmit={onSubmit}>
      <select value={sym} onChange={onChange} name='sym'>
        <option value="">Select an Coin</option>
        <option value="Ethereum">Ethereum</option>
        <option value="Solana">Solana</option>
        <option value="Polygon">Polygon</option>
        <option value="Polkadot">Polkadot</option>
      </select><br/><br/>
        <input type='text' className="w-50" placeholder='Amount' name='AssetAmount' value={AssetAmount} onChange={onChange} /><br/><br/>
        <input type='text' className="w-50" placeholder='Withdraw Address' name='Withdraw_Address' value={Withdraw_Address} onChange={onChange} /><br/><br/>
        <button type='submit' className="btn btn-primary">Stake</button>
      </form>
      <br/><br/><br/>
      <h1 className="text-center">UNSTAKE</h1>
      <form onSubmit={onSubmitHandler}>
        <input type='text' className="w-50" placeholder='Validator public key' value={validatorKey} onChange={(e) => setValidatorKey(e.target.value)} /><br/><br/>
        <button type='submit' className="btn btn-primary">Unstake</button>
      </form>
    </div>
  )
}

export default Staking