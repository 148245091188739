import React from "react";
import error404 from "./404.svg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  return (
    <div className="errorpage404">
      <Helmet>
        <title>Oops! 404 Error: Page Not Found on Our Crypto Platform</title>
        <meta name="description" content="Our 404 error page not found page on our crypto platform is designed to help you quickly and easily find what you're looking for. If you've landed on this page by mistake, don't worry - our user-friendly interface and comprehensive search function make it easy to navigate to the page you need. Whether you're looking to buy or sell crypto, join our pool, or manage your account, our platform has everything you need to succeed in the world of cryptocurrency. So why wait? Join us today and take the first step towards realizing your full potential as a crypto investor!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      <div className="container errorpage-container">
        <div className="errorpage-header d-flex align-items-center justify-content-between">
          <div className="errorpage-logo">
            <a className="text-dark ms-4 p-1 animation-button">
              <div className="i page404-logo"></div>
              <div className="i page404-logo"></div>
            </a>
          </div>
          <div className="header-content d-flex align-items-center">
            <p className="text-muted">
              The page you’re looking for<br></br> has vanished into thin air.
            </p>
            <Link to="/" className="errorpage-btn">
              Back to home
            </Link>
          </div>
        </div>
        <div className="errorpage-content">
          <div className="errorpage-img">
            <img src={error404} alt="arrow" />
          </div>
          <div className="whoops">
            <h1>WHOOPS</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
