import React, { useState, useEffect } from "react";
import upArr from "./up.png";
import { w3cwebsocket as W3CWebSocket } from "websocket";

const client_id = Date.now();
// const client = new W3CWebSocket(`ws://flitchcoin.com/api/ws1/${client_id}`);
const client = new W3CWebSocket(
  `wss://api.flitchcoin.com/api/ws1/${client_id}`
);

const OrderBook = () => {
  const [demandPrice, setDemandPrice] = useState([]);
  const [demandSym, setDemandSym] = useState([]);
  const [demandSize, setDemandSize] = useState([]);
  const [supplyPrice, setSupplyPrice] = useState([]);
  const [supplySym, setSupplySym] = useState([]);
  const [supplySize, setSupplySize] = useState([]);
  const [mainPrice, setMainPrice] = useState(0);
  const [change, setChange] = useState(true);

  const arr = [-1, -1];

  const wss = () => {
    if (client.readyState === client.OPEN) {
      setInterval(() => {
        client.send(
          JSON.stringify({
            type: "message",
            msg: Date.now(),
          })
        );
      }, 3000);
    }

    client.onmessage = (message) => {
      const dataParse = JSON.parse(message.data);
      if (dataParse) {
        if (dataParse.demand && dataParse.supply) {
          const demand = dataParse.demand;
          const supply = dataParse.supply;
          let dem_price = [];
          let dem_size = [];
          let sup_price = [];
          let sup_size = [];
          let dem_sym = [];
          let sup_sym = [];
          for (var i = 0; i < 4; i++) {
            dem_price.push(demand[i].price);
            dem_sym.push(demand[i].sym);
            dem_size.push(demand[i].size);
            sup_price.push(supply[i].price);
            sup_size.push(supply[i].size);
            sup_sym.push(supply[i].sym);
          }
          setDemandPrice([...dem_price]);
          setDemandSym([...dem_sym]);
          setDemandSize([...dem_size]);
          setSupplyPrice([...dem_price]);
          setSupplySize([...dem_size]);
          setSupplySym([...dem_sym]);

          arr[0] = arr[1];
          arr[1] = demand[0].price;
          if (arr[1] > arr[0]) {
            setChange(true);
          } else {
            setChange(false);
          }
          setMainPrice(arr[1]);
        }
      }
    };
    if (client.onclose !== null) {
      client.onclose = () => {
        const client_id = Date.now();
        const client = new W3CWebSocket(
          `wss://api.flitchcoin.com/api/ws1/${client_id}`
        );
        wss();
      };
    }
  };

  useEffect(() => {
    wss();
  }, []);

  return (
    <div>
      <div className="row text-center pb-0">
        <div className="col-4">
          <span className="prices-heading">Price(USDT)</span>
          {demandPrice.map((i) => {
            return (
              <p className="text-success order_book_data profit-text">{i}</p>
            );
          })}
        </div>
        <div className="col-4">
          <span className="prices-heading">Asset</span>
          {demandSym.map((i) => {
            return (
              <p className="text-success order_book_data profit-text">{i}</p>
            );
          })}
        </div>
        <div className="col-4">
          <span className="prices-heading">Size</span>
          {demandSize.map((i) => {
            return (
              <p className="text-success order_book_data profit-text">{i}</p>
            );
          })}
        </div>
      </div>
      <div className="row text-center">
        <div className="col-4">
          {supplyPrice.map((i) => {
            return <p className="text-danger order_book_data loss-text">{i}</p>;
          })}
        </div>
        <div className="col-4">
          {supplySym.map((i) => {
            return <p className="text-danger order_book_data loss-text">{i}</p>;
          })}
        </div>
        <div className="col-4">
          {supplySize.map((i) => {
            return <p className="text-danger order_book_data loss-text">{i}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
