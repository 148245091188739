import React, { useRef, useState } from "react";
const Checkbox = ({ id, label, labelClassname, checkboxClassname, inputClassname, checked, onChange, ...props}) => {  
  const checkboxRef = useRef();

  return (
    <div className={`custom-checkbox ${checkboxClassname}`}>
      <span className="checkbox-style">
        <input
          type="checkbox"
          className={`custom-checkbox-input ${inputClassname}`}
          ref={ checkboxRef}
          id={id}
          checked={checked}          
          {...props}
          onChange={onChange}
        />
      </span>
      
      <label className={`custom-checkbox-label ${labelClassname}`} htmlFor={id}>{label}</label>
  </div>
  )
}

export default Checkbox;