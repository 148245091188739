import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from "react-router-dom";
import Animation from "../../Animation";
import { fetchToken } from "../../Auth";
import statement from "./Group 87.png";
import setting from "./Group 97.png";
import line from "./Line 18.png";
import SmallFooter from '../SmallFooter';
import { Modal } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from "../Loader";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import cross from "./Group 438.svg";
import { Helmet } from 'react-helmet';

const QRVerify = () => {

    AOS.init();
    AOS.init({
        duration: 1000,
        once: false,
    });

    const navigate = useNavigate();
    const [otp1, setOtp1] = useState();
    const [qr, setQr] = useState("");
    const [page, setPage] = useState("Choose");
    const [isActive, setIsActive] = useState(false);
    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
    const [showC, setShowC] = useState(false);
    const [showD, setShowD] = useState(false);
    const [showE, setShowE] = useState(false);
    const [showF, setShowF] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    const getOtp = () => {
        fetch("https://api.flitchcoin.com/api/fa2url", {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        }).then((result) => {
            result.json().then((res) => {
                console.log(res)
                setQr(res);
            })
        })
    };

    useEffect(() => {
        getOtp();
        getInfo();
    }, []);

    const submitHandler = (e) => {
        setLoading(true)
        e.preventDefault();
        if (otp1 === undefined || otp1 === "") {
            setLoading(false)
            setShowA(true);
        } else {
            const data = JSON.stringify({
                "otp": Number(otp1)
            });
            fetch("https://api.flitchcoin.com/api/fa2url", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${fetchToken()}`,
                },
                body: data
            }).then(res => res.json())
                .then((data) => {
                    setLoading(false)
                    if (data.status === 200) {
                        setShowB(true);
                        navigate("/dashboard");
                    } else {
                        setShowC(true);
                    }
                }).catch((err) => {
                    console.log(err);
                })
        }
    };

    const [fa2, setfa2] = useState("");

    const getInfo = () => {
        fetch('https://api.flitchcoin.com/api/users/me/items/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${fetchToken()}`
            }
        }).then((result) => result.json()
            .then(res => {
                const data = JSON.stringify({
                    "emailid": res.username
                })
                fetch('https://api.flitchcoin.com/api/userchrono_info', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: data
                }).then(res => res.json())
                    .then((data) => {
                        if (data.fa2 === null) {
                            setfa2("null");
                        } else if (data.fa2 === false) {
                            setfa2("false");
                        } else if (data.fa2 === true) {
                            setfa2("true");
                        }
                        setLoading(false)
                    }).catch((err) => {
                        console.log(err);
                    })
            })).catch((err) => {
                console.log(err);
            })
    };

    const yesfa = () => {
        if (fa2 === "true") {
            setShowF(true);
        } else {
            setIsActive(true);
            setPage("Setup");
        }
    };

    const noFa = () => {
        setLoading(true)
        if (fa2 === "true") {
            setLoading(false)
            setShow(true);
        } else if (fa2 === "false") {
            setLoading(false)
            setShowE(true);
        } else if (fa2 === "null") {
            fetch("https://api.flitchcoin.com/api/fa2url", {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${fetchToken()}`,
                }
            }).then(result => result.json()
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        setShowD(true);
                        navigate("/dashboard");
                    }
                })).catch(err => console.log(err));
        }
    };

    const turnOn = () => {
        setLoading(true)
        if (otp1 === undefined || otp1 === "") {
            setLoading(false)
            setShowA(true);
        } else {
            const data = JSON.stringify({
                "otp": Number(otp1)
            });
            fetch("https://api.flitchcoin.com/api/fa2url", {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${fetchToken()}`,
                },
                body: data,
            }).then(result => result.json()
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        setShowD(true);
                        navigate("/dashboard");
                    }
                })).catch(err => console.log(err));
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setShowA(false)
            setShowB(false)
            setShowC(false)
            setShowD(false)
            setShowE(false)
            setShowF(false)
        }, 3000)
    }, [showA, showB,showC, showD,showE, showF]);

    return (
        <>
        <Helmet>
        <title>Verify Your Account with Ease: Scan and Verify with QR Technology</title>
        <meta name="description" content="Our QR verify page offers a fast and secure way to verify your account using QR technology. Simply scan the QR code with your smartphone or tablet, and you can quickly and easily verify your identity to gain access to all the great features and benefits of our platform. With our advanced security measures, you can trust us to keep your personal information safe and secure, while our user-friendly interface makes it easy to complete the verification process in just a few simple steps. Join us today and enjoy the peace of mind that comes with a fully verified account." />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
            {
                loading ?
                    <><Loader /></>
                    :
                    <>
                        <Animation />
                        <div className="container">
                            <div className="row mt-5">
                                <h1 className='text-center setup_text'>Setup Two Factor Authentication</h1>
                                <div className="row ps-5 pe-5">
                                    <div className="card back parent_card ms-3 me-3 mb-3 mt-4 pb-5">
                                        <div className="row">
                                            <div className={`col-xl-4 ps-5 ${page === "Choose" ? 'to_middle' : 'to_middle_2'}`} >
                                                <i className={`ps-4 pe-4 pt-2 mt-1 dropdown-qr ${page === "Choose" ? 'selected-qr ' : ''}`} onClick={() => setPage("Choose")}><img alt="choose" src={statement} style={{ height: "32px", width: "32px" }} /> &nbsp; Choose</i>
                                                <img src={line} alt="line" className='line_link' />
                                                <i className={`ps-4 pe-4 pt-2 mt-1 dropdown-qr ${page === "Setup" ? 'selected-qr' : ''}`}><img alt="choose" src={setting} style={{ height: "25px", width: "25px" }} /> &nbsp; Setup Auth</i>
                                                <img src={line} alt="line" className='line_link' />
                                                <i className={`ps-4 pe-4 pt-2 mt-1 dropdown-qr ${page === "Verify" ? 'selected-qr' : ''}`}><img alt="choose" src={setting} style={{ height: "25px", width: "25px" }} /> &nbsp; Verify QR code</i>
                                            </div>
                                            <div className="col-xl-8 p-5 pb-0">
                                                {
                                                    page === "Choose" ?
                                                        <>
                                                            <div className="row">
                                                                <div className="row settings_box ps-5 pe-5 pt-3 pb-2">
                                                                    <div className="col-lg-8">
                                                                        <p className='qr_head'>I want to Activate 2 - Step Verification</p>
                                                                        <p>*** You can change this anytime through settings page.</p>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <button className="round-btn margin_btn place_order_btn ps-5 pe-5 mt-3 qr_btn" onClick={yesfa}>
                                                                            Activate
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row settings_box ps-5 pe-5 pt-3 pb-2 mt-4 mb-5">
                                                                    <div className="col-lg-8">
                                                                        <p className='qr_head'>Nah ! I'm good without 2 - Step Verification</p>
                                                                        <p>*** You can change this anytime through settings page.</p>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <button className="round-btn red button ps-4 pe-4 mt-5 qr_btn" onClick={noFa}>
                                                                            De - Activate
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <p><span className="text_design">Two-factor authentication (2FA) </span>is an excellent way to add an extra layer of security to your online accounts, including your wallet. Here are a few reasons why you should consider using it:<br />
                                                                    1. It helps protect against unauthorized access to your accounts, even if someone else has obtained your login credentials.<br />
                                                                    2. It's quick and easy to set up, and can be done through a variety of methods, such as SMS, email, or google authentication app.<br />
                                                                    3. It provides peace of mind knowing that your accounts have an extra level of protection.<br />
                                                                    4. Many online services, including financial institutions, now require 2FA for added security, so it's becoming increasingly important to use it.</p>
                                                            </div>
                                                        </> :
                                                        <>
                                                            {
                                                                page === "Setup" && isActive ?
                                                                    <div>
                                                                        <p><span className="qr_head"> Here’s an example to set up two-factor authentication (2FA) using the <span className="text_design"> Google Authenticator</span> app:</span><br /><br />
                                                                            1. Download the Google Authenticator app on your phone. It is available for both Android and iOS devices.<br />
                                                                            2. Go to the account or service that you want to enable 2FA for and look for the option to enable 2FA. This is usually found in the security settings.<br />
                                                                            3. Follow the prompts to set up 2FA. This may include scanning a QR code or entering a secret key.<br />
                                                                            4. The Google Authenticator app will generate a 6-digit code that you will need to enter in order to complete the setup process.<br />
                                                                            5. From now on, whenever you log in to your account, you will be prompted to enter both your password and the 6-digit code generated by the Google Authenticator app.<br /><br />
                                                                            That's it! You have now successfully set up 2FA using the Google Authenticator app. It's a good idea to write down the secret key or save it somewhere safe, in case you lose access to your phone or the app.<br /><br />
                                                                            <p className="text-muted">**You can change your auth app anytime through the same process.</p></p>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <button className="round-btn red button ps-4 pe-4 mt-5 qr_btn w-100">
                                                                                    I need more help !
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-lg-6 text-center">
                                                                                <button className="round-btn margin_btn place_order_btn ps-5 pe-5 mt-5 qr_btn w-100" onClick={() => setPage("Verify")}>
                                                                                    I've Completed setup &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'>'}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <>
                                                                        {
                                                                            page === "Verify" && isActive ?
                                                                                <div>
                                                                                    <div className="row mb-4">
                                                                                        <div className="col-xl-4">
                                                                                            <QRCodeSVG
                                                                                                value={qr}
                                                                                                size={150}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-xl-8 mt-5">
                                                                                            <p>Enter OTP here : &nbsp;&nbsp;&nbsp;<input placeholder='x - x - x - x' value={otp1} onChange={(e) => setOtp1(e.target.value)} className='input_login w-50 p-2 ps-4'></input></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p>Here are the steps to scan a QR code and enter a one-time passcode (OTP) for two-factor authentication (2FA):<br />
                                                                                        1. Open the authenticator app on your phone (such as Google Authenticator).<br />
                                                                                        2. Tap the plus sign (+) or the option to add a new account.<br />
                                                                                        3. Choose the option to scan a QR code.<br />
                                                                                        4. Hold your phone up to the QR code displayed on the screen. The app should automatically scan the code and add the account to your app.<br />
                                                                                        5. Once the account has been added to your authenticator app, it will display a 6-digit code. This is the one-time passcode (OTP) that you will need to enter in order to complete the login process.<br />
                                                                                        6. Finally enter the OTP in input box above and click “proceed & Finish Setup button”.<br />
                                                                                    </p>
                                                                                    <p className="text-muted">**You can change your auth app anytime through the same process.</p>
                                                                                    <div className="row">
                                                                                        <div className="col-lg-6">
                                                                                            <button className="round-btn red button ps-4 pe-4 mt-4 qr_btn w-100" onClick={noFa}>
                                                                                                I don't need 2 FA !
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="col-lg-6 text-center">
                                                                                            <button className="round-btn margin_btn place_order_btn ps-5 pe-5 mt-4 qr_btn w-100" onClick={submitHandler}>
                                                                                                Proceed & Finish setup &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'>'}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> :
                                                                                <></>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SmallFooter />
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-popup"
                        >
                            <div className="p-3 pt-5 special_popup">
                                <div className="row mb-4">
                                    <div className="col-lg-4">
                                        <QRCodeSVG
                                            value={qr}
                                            size={150}
                                        />
                                    </div>
                                    <div className="col-lg-8 mt-5">
                                        <p>Enter OTP here : &nbsp;&nbsp;&nbsp;<input placeholder='x - x - x - x' value={otp1} onChange={(e) => setOtp1(e.target.value)} className='input_login w-50 p-2 ps-4'></input></p>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="primary me-4"
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button" className="primary" onClick={turnOn}>
                                    Confirm
                                </button>
                            </div>
                        </Modal>
                        <Modal
                            show={showA}
                            onHide={() => setShowA(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Unfilled !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">Please enter the OTP to proceed.</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowA(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            show={showB}
                            onHide={() => setShowB(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Done !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">You have now opted for 2 FA !</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowB(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            show={showC}
                            onHide={() => setShowC(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">Please enter the OTP to proceed.</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowC(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            show={showD}
                            onHide={() => setShowD(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Done !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">You have opted to continue without 2 FA .</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowD(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            show={showE}
                            onHide={() => setShowE(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">You have already opted not to continue with 2 FA .</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowE(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            show={showF}
                            onHide={() => setShowF(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                        >
                            <div className="popup_error">
                                <div className="popup_error_head">
                                    <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">Error !</p>
                                    <div className="event_line"></div>
                                </div>
                                <p className="popup_error_body_text pt-4 pb-3">You have already opted to continue with 2 FA .</p>
                                <div className='d-flex justify-content-center pb-4'>
                                    <CountdownCircleTimer
                                        size={40}
                                        strokeWidth={3}
                                        isPlaying
                                        duration={3}
                                        colors={"red"}
                                        colorsTime={[3, 0]}
                                    >
                                        {({ remainingTime }) => {
                                            return <img src={cross} className="cross" onClick={() => setShowF(false)} />
                                        }}

                                    </CountdownCircleTimer>
                                </div>
                            </div>
                        </Modal>
                    </>
            }
        </>
    )
}

export default QRVerify