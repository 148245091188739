import React, { useEffect, useState } from "react";
import "./index.css";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';

const DepositOptions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
    <Helmet>
        <title>Flexible Cryptocoin Deposit Options: Choose Your Preferred Method to Fund Your Account and Start Investing </title>
        <meta name="description" content="Convenient Cryptocoin Deposit Options: Explore a Range of Secure Methods to Fund Your Account and Begin Investing in Cryptocurrencies. Start Your Journey Today!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="deposit-options">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard1">
                    <h3>I have Cryptocurrencies</h3>
                    <h2 className="depo-gradient1">
                      Connect Wallet & Deposit Crypto-currencies{" "}
                    </h2>
                    <a
                      onClick={() => navigate("/web3")}
                      className="ud-proceed-btn card1-proceed-btn ripples"
                    >
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/shieldimg.webp"
                      alt=""
                      className="shield-img"
                    />
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/metamaskimg.webp"
                      alt=""
                      className="metamask-img"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard2">
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/card2img1.webp"
                      alt=""
                      className="card2img1"
                    />
                    <h3>
                      I <span>don't</span> have Cryptocurrencies
                    </h3>
                    <h2>Buy Cryptocurrencies Now With Card/Bank Transfer </h2>
                    <a href="#" className="ud-proceed-btn  card2-proceed-btn ripples">
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ud-depocard ud-depocard3">
                    <h3>I have Cryptocurrencies</h3>
                    <h2>Deposit using Scan & Pay or Using wallet address</h2>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/card3img1.webp"
                      alt=""
                      className="card3img1"
                    />
                    <a
                      onClick={() => navigate("/deposit")}
                      className="ud-proceed-btn card3-proceed-btn deposit-card3-btn ripples"
                    >
                      <span className="title">Proceed</span>
                      <span className="icon">
                        <MdArrowForwardIos />
                      </span>
                    </a>
                    <img
                      src="https://flitchcoin.s3.eu-west-2.amazonaws.com/images/qrimg.webp"
                      alt=""
                      className="qrimg"
                    />
                  </div>
                </div>
                <img
                  src="https://flitchcoin.s3.eu-west-2.amazonaws.com/Card+5+1.webp"
                  alt=""
                  className="debitcard-img"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DepositOptions;
