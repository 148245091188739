import React from "react";

const CryptoCurrencyInfo = () => {
  return(
    <p className="text-muted mt-4">
                        Cryptocurrency transactions are complex, and it's important to
                        take certain <b className="text-dark"> precautions</b> to
                        ensure the security of your funds. Here are some things that
                        you must consider :<br />
                        <br />
                        <b className="text-dark"> Network selection</b> : Different
                        cryptocurrencies operate on different networks, and each
                        network has its own fees and processing times. Carefully
                        consider which network is the most appropriate for your
                        transaction.
                        <br />
                        <br />
                        <b className="text-dark"> Crypto address</b> : Make sure to
                        double-check the recipient's address before sending a
                        transaction. Cryptocurrency transactions are irreversible, so
                        it's crucial to ensure that you are sending funds to the
                        correct address.
                        <br />
                        <br />
                        <b className="text-dark"> Gas fees </b> : Some networks, such
                        as Ethereum, require the use of "gas" to process transactions.
                        Gas fees can vary depending on network demand and the
                        complexity of the transaction. Make sure to consider the gas
                        fees when conducting a transaction.
                        <br />
                        <br />
                        <b className="text-dark">
                          {" "}
                          Few cryptocurrencies require Memo
                        </b>{" "}
                        : Make sure to carefully review the information in the memo
                        field before sending the transaction. Incorrect or missing
                        information in the memo field could result in the transaction
                        being delayed, rejected or even loss of assets.
                        <br />
                        <br />
                      </p>
  )
}

export default CryptoCurrencyInfo;