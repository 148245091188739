import React, { useState, useEffect } from "react";
import { fetchToken } from "../../Auth";
import { Modal } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import dropdown from "../../Polygon 2.png";
import tick from "./tick.svg";
import copy from "./Group 223.svg";
import green_tick from "./Group 224.svg";
import Loader from "../Loader";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import cross from "./Group 438.svg";
import search from "./Group 420.svg";
import { Helmet } from 'react-helmet';

const Order = () => {
  AOS.init({
    duration: 1000,
    once: false,
  });

  const [cardClicked, setCardClicked] = useState(null);
  const [cardClickedDeposit, setCardClickedDeposit] = useState(null);
  const [type, setType] = useState("History");
  const [checkPool, setCheckPool] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [detailType, setDetailType] = useState(true);
  const [detailTypeRe, setDetailTypeRe] = useState(false);
  const [historyType, setHistoryType] = useState("Order History");
  const [isCopied, setIsCopied] = useState(false);
  const [showModalCoin, setShowModalCoin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowA(false);
      setShowB(false);
    }, 3000);
  }, [showA, showB]);

  const getInfo = () => {
    fetch("https://api.flitchcoin.com/api/users/me/items/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          if (res.is_pool) {
            setCheckPool(true);
            setLend(true);
          }
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const order = () => {
    fetch("https://api.flitchcoin.com/api/order", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((resp) => {
          if (!resp.length) {
            setNoOrder(true);
          } else {
            const res = Object.values(resp);
            var tempArr_5 = [];
            for (let i = 0; i < res.length; i++) {
              tempArr_5.push(res[i].memo);
            }
            setAllMemo([...tempArr_5]);
            var tempArr_1 = [];
            var tempArr_2 = [];
            var tempArr_3 = [];
            var tempArr_4 = [];

            for (let i = 0; i < res.length; i++) {
              if (res[i].margin) {
                if (res[i].is_in_escrow_ac) {
                  tempArr_1.push(res[i]);
                } else {
                  tempArr_2.push(res[i]);
                }
              } else {
                if (res[i].used_amt_usd) {
                  tempArr_3.push(res[i]);
                } else {
                  tempArr_4.push(res[i]);
                }
              }
            }
            setOpenPositionDataParticipant([...tempArr_1]);
            setOpenOrderDataParticipant([...tempArr_2]);
            setOpenPositionDataPool([...tempArr_3]);
            setOpenOrderDataPool([...tempArr_4]);
          }
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
    order();
    getDepositHistory();
    margin_asset();
  }, []);

  const [openOrderDataPool, setOpenOrderDataPool] = useState([]);
  const [openOrderDataParticipant, setOpenOrderDataParticipant] = useState([]);
  const [openPositionDataPool, setOpenPositionDataPool] = useState([]);
  const [openPositionDataParticipant, setOpenPositionDataParticipant] =
    useState([]);
  const [allMemo, setAllMemo] = useState([]);
  const [noOrder, setNoOrder] = useState(false);
  const [lend, setLend] = useState(false);

  var [cardDataParticipant, setCardDataParticipant] = useState({
    withdrawableHedge: "",
    entryPrice: "",
    markPrice: "",
    roe: "",
    liquationPrice: "",
    colateralizedAsset: "",
    allocatedMargin: "",
    totalAmount: "",
    alt_id: "",
    memo: "",
  });

  var [cardDataPool, setCardDataPool] = useState({
    _liquationPrice: "",
    _colateralizedAsset: "",
    _totalAmount: "",
    _alt_id: "",
    _memo: "",
  });

  var {
    withdrawableHedge,
    entryPrice,
    markPrice,
    roe,
    liquationPrice,
    colateralizedAsset,
    allocatedMargin,
    totalAmount,
    alt_id,
    memo,
  } = cardDataParticipant;
  var { _liquationPrice, _colateralizedAsset, _totalAmount, _alt_id, _memo } =
    cardDataPool;

  const gettingData = (item) => {
    if (lend) {
      setCardDataPool(() => ({
        _liquationPrice: item.post_price,
        _colateralizedAsset: item.sym,
        _totalAmount: item.amt,
        _alt_id: item.alt_id.substring(0, 8),
        _memo: item.memo,
      }));
    } else {
      const data = JSON.stringify({
        memo: item.memo,
      });
      fetch("https://api.flitchcoin.com/api/check/one/open/positions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((result) => {
          result.json().then((res) => {
            var lip = 0;
            var mark = 0;
            var amnt = res[0].amt;
            lip = res[0].liq_p;
            mark = res[0].mark_price;
            if (item.margin) {
              setCardDataParticipant(() => ({
                withdrawableHedge: item.hedge,
                entryPrice: item.init_price,
                markPrice: item.current_price,
                roe: (
                  Number((mark - item.init_price) / item.init_price) *
                  100
                ).toFixed(2),
                liquationPrice: Number(lip).toFixed(2),
                colateralizedAsset: item.sym,
                allocatedMargin: item.init_amt,
                totalAmount: amnt,
                memo: item.memo,
                date: item.time_duration.substring(0, 10),
              }));
            }
          });
        })
        .catch((err) => console.log(err));
    }
  };

  const [coin, setCoin] = useState("coin");
  const [coinImg, setCoinImg] = useState("");
  const [marginAsset, setMarginAsset] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  const margin_asset = () => {
    fetch("https://api.flitchcoin.com/api/margin/asset", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    }).then((result) => {
      result.json().then((res) => {
        const data = Object.values(res);
        let tmpSymbol = [];
          data.map((items) => {
            tmpSymbol.push(items);
          });
        setMarginAsset([...tmpSymbol]);
      });
    });
  };

  const [amount, setAmount] = useState("");

  const closeOrder = () => {
    setLoading(true);
    var data = JSON.stringify({
      memo: cardDataParticipant.memo,
    });
    fetch("https://api.flitchcoin.com/api/order", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((result) => {
        result.json().then((res) => {
          setShowA(true);
          setLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const addMargin = () => {
    setLoading(true);
    var data = JSON.stringify({
      memo: cardDataParticipant.memo,
      amount: Number(amount),
      hedge: cardDataParticipant.withdrawableHedge,
    });
    fetch("https://api.flitchcoin.com/api/add/margin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((result) => {
        result.json().then((res) => {
          setLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const removeMargin = () => {
    setLoading(true);
    var data = JSON.stringify({
      memo: cardDataParticipant.memo,
    });
    fetch("https://api.flitchcoin.com/api/remove/margin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((result) => {
        result.json().then((res) => {
          setLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const repay = () => {
    setLoading(true);
    var data = JSON.stringify({
      memo: cardDataParticipant.memo,
    });
    fetch("https://api.flitchcoin.com/api/repayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((result) => {
        result.json().then((res) => {
          setLoading(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const closeAllOrders = () => {
    setLoading(true);
    allMemo.map((i) => {
      var data = JSON.stringify({
        memo: i,
      });
      fetch("https://api.flitchcoin.com/api/order", {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((result) => {
          result.json().then((res) => {
            setShow1(false);
            setShowB(true);
            setLoading(false);
          });
        })
        .catch((err) => console.log(err));
    });
  };

  const [depositHistoryData, setDepositHistoryData] = useState([]);
  const [noDepositHistory, setNoDepositHistory] = useState(false);
  const [cardDataDeposit, setCardDataDeposit] = useState({
    _coin: "",
    _network: "",
    _memo_dep: "",
    _amount: "",
    _price: "",
    _ticker: "",
    _address: "",
    _time: "",
    _callback: "",
  });

  const {
    _coin,
    _network,
    _memo_dep,
    _amount,
    _price,
    _ticker,
    _address,
    _time,
    _callback,
  } = cardDataDeposit;
  const [show, setShow] = useState(false);

  const getDepositHistory = () => {
    fetch("https://api.flitchcoin.com/api/crypt/deposit/history", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
      },
    })
      .then((result) =>
        result.json().then((res) => {
          const resp = Object.values(res);
          if (!resp.length) {
            setNoDepositHistory(true);
          }
          var tempArr = [];
          for (let i = 0; i < resp.length; i++) {
            tempArr.push(resp[i]);
          }
          setDepositHistoryData([...tempArr]);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const gettingDataDeposit = (item) => {
    fetch("https://api.flitchcoin.com/api/crypt/deposit/history", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        result.json().then((res) => {
          const resp = Object.values(res);
          for (let i = 0; i < resp.length; i++) {
            if (resp[i].memo === item.memo) {
              setCardDataDeposit({
                _coin: resp[i].coin,
                _network: resp[i].network,
                _memo_dep: resp[i].memo.substring(0, 9),
                _amount: resp[i].amt,
                _price: resp[i].price,
                _ticker: resp[i].ticker,
                _address: resp[i].address_in.substring(0, 9),
                _time: resp[i].T.substring(3),
                _callback: resp[i].callback_url,
              });
            }
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const validate = async () => {
    setLoading(true);
    var data = JSON.stringify({
      "coin": cardDataDeposit._coin,
      "network": cardDataDeposit._network,
      "priority": "string",
      "post": false
    });
    fetch("https://api.flitchcoin.com/api/crypt/deposit", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${fetchToken()}`
      },
      body: data,
    }).then(res => res.json()
      .then(async (result) => {
        const query = new URLSearchParams({ callback: result.callback_url }).toString();
        const resp = await fetch(
          `https://api.cryptapi.io/${result.ticker}/logs/?${query}`,
          { method: 'GET' }
        );
        const data = await resp.json();
        if (data.callbacks.length) {
          var dataBody = JSON.stringify({
            "callback": result.callback_url,
            "memo": result.memo,
            "ticker": result.ticker,
          });
          fetch("https://api.flitchcoin.com/api/crypt/deposit", {
            method: "PUT",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken()}`
            },
            body: dataBody,
          }).then(res => res.json()
            .then(result => {
              setLoading(false);
              setShow(true);
              setTimeout(function () {
                setShow(false);
              }, 5000);
            }))
            .catch(err => {
              console.log(err)
            })
        };
      }))
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);

  const become = (e) => {
    setLoading(true)
      const data = JSON.stringify({
        is_pool: !checkPool,
      });
      fetch("https://api.flitchcoin.com/api/mode", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${fetchToken()}`,
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((result) =>
          result.json().then((res) => {
            setCheckPool(!checkPool)
            window.location.reload();
            setLend(e);
          })
        )
        .catch((err) => console.log(err));
  };

  return (
    <>
    <Helmet>
        <title>Secure and Convenient Cryptocoin Order Page: Buy Cryptocoins Online with Ease</title>
        <meta name="description" content="Streamlined and Secure Cryptocoin Ordering: Track, Manage, and Adjust Orders, Positions, and Margins on this Page"/>
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {!show ? (
            <>
              <div className="container">
                <div className="my-5 history-nav-links">
                  <ul class="nav nav-underline">
                    <li class="nav-item">
                      <div
                        onClick={() => {
                          setHistoryType("Order History");
                          become(false);
                        }}
                        className={`nav-link history_hover ${historyType === "Order History" && !lend && "active"
                          }`}
                      >
                        Borrow Order History
                      </div>
                    </li>
                    <li class="nav-item">
                      <div
                        onClick={() => {
                          setHistoryType("Order History");
                          become(true);
                        }}
                        className={`nav-link history_hover ${historyType === "Order History" && lend && "active"
                          }`}
                      >
                        Lend Order History
                      </div>
                    </li>
                    <li class="nav-item">
                      <div
                        onClick={() => setHistoryType("Deposit History")}
                        className={`nav-link history_hover ${historyType === "Deposit History" && "active"
                          }`}
                      >
                        Deposit History
                      </div>
                    </li>
                    <li class="nav-item">
                      <div
                        onClick={() => setHistoryType("Withdraw History")}
                        className={`nav-link history_hover ${historyType === "Withdraw History" && "active"
                          }`}
                      >
                        Withdraw History
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {historyType === "Order History" ? (
                <>
                  <div className="container" id="historymain">
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="row">
                          <ul className="spl-button-list d-flex justify-content-start">
                            <li className="px-2">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={() => setType("Open Orders")}
                              >
                                Open Order
                              </button>
                            </li>
                            <li className="px-2">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={() => setType("Open Positions")}
                              >
                                Open Positions
                              </button>
                            </li>
                            <li className="px-2">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={() => setType("History")}
                              >
                                History
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="row enable_scroll_4 pt-2 pe-2 ps-2">
                          {noOrder ? (
                            <>
                              <p className="text-center text_design api_head pt-5">
                                There is no order to show.
                              </p>
                            </>
                          ) : (
                            <>
                              {type === "Open Positions" ? (
                                <>
                                  {lend ? (
                                    <>
                                      {openPositionDataPool.map((i, index) => {
                                        console.log(i)
                                        return (
                                          <div
                                            key={index}
                                            className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                              ? "special_card_deposit"
                                              : ""
                                              }`}
                                            onClick={() => {
                                              gettingData(i);
                                              setCardClicked(index);
                                            }}
                                          >
                                            <div className="selection-section">
                                              <label className="spl-checkbox-green">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    cardClicked === index
                                                      ? true
                                                      : false
                                                  }
                                                  name="data"
                                                  className="data_radio"
                                                />
                                                <span className="show-content"></span>
                                              </label>
                                            </div>
                                            <div className="left-side">
                                              <table>
                                                <tbody>
                                                  <tr>
                                                    <td>Date & Time :</td>
                                                    <td>
                                                      {i.time_duration
                                                        .toString()
                                                        .replace("T", " ")}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Total Amount :</td>
                                                    <td>
                                                      {i.amt ? (
                                                        <>
                                                          {Number(
                                                            i.amt
                                                          ).toFixed(2)}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {Number(
                                                            i.current_price
                                                          ).toFixed(2)}
                                                        </>
                                                      )}{" "}
                                                      USD
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Memo :</td>
                                                    <td>{i.memo}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="center-line">
                                              <div className="center-blob top">
                                                <img src={i.logo} alt="" />
                                              </div>
                                              <div className="center-line-line"></div>
                                              <div className="center-blob bottom">
                                                <img src={i.logo} alt="" />
                                              </div>
                                            </div>
                                            <div className="right-side">
                                              <table className="mb-5">
                                                <tbody>
                                                  <tr>
                                                    <td>Liq. Price :</td>
                                                    <td>
                                                      <span className="text-s-error">
                                                        {i.post_price ? (
                                                          <>
                                                            {Number(
                                                              i.post_price
                                                            ).toFixed(2)}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {Number(
                                                              i.init_amt
                                                            ).toFixed(2)}
                                                          </>
                                                        )}
                                                      </span>{" "}
                                                      USD
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Collateral Asset :</td>
                                                    <td>{i.sym}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      {openPositionDataParticipant.map(
                                        (i, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                                ? "special_card_deposit"
                                                : ""
                                                }`}
                                              onClick={() => {
                                                gettingData(i);
                                                setCardClicked(index);
                                              }}
                                            >
                                              <div className="selection-section">
                                                <label className="spl-checkbox-green">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      cardClicked === index
                                                        ? true
                                                        : false
                                                    }
                                                    name="data"
                                                    className="data_radio"
                                                  />
                                                  <span className="show-content"></span>
                                                </label>
                                              </div>
                                              <div className="left-side">
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <td>Date & Time :</td>
                                                      <td>
                                                        {i.time_duration
                                                          .toString()
                                                          .replace("T", " ")}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Total Amount :</td>
                                                      <td>
                                                        {i.init_amt ? (
                                                          <>
                                                            {Number(
                                                              i.init_amt
                                                            ).toFixed(2)}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {Number(
                                                              i.current_price
                                                            ).toFixed(2)}
                                                          </>
                                                        )}{" "}
                                                        USD
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Memo :</td>
                                                      <td>{i.memo}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="center-line">
                                                <div className="center-blob top">
                                                  <img src={i.logo} alt="" />
                                                </div>
                                                <div className="center-line-line"></div>
                                                <div className="center-blob bottom">
                                                  <img src={i.logo} alt="" />
                                                </div>
                                              </div>
                                              <div className="right-side">
                                                <table className="mb-5">
                                                  <tbody>
                                                    <tr>
                                                      <td>Liq. Price :</td>
                                                      <td>
                                                        <span className="text-s-error">
                                                          {Number(
                                                            i.settlement_price
                                                          ).toFixed(2)}
                                                        </span>{" "}
                                                        USD
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        Collateral Asset :
                                                      </td>
                                                      <td>{i.sym}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                {
                                                  !checkPool ?
                                                    <>
                                                      <div className="text-end">
                                                        <button
                                                          className="btn btn-spl-black btn-s-default btn-sm for_z"
                                                          onClick={() => {
                                                            setDetailType(false);
                                                            setDetailTypeRe(true);
                                                          }}
                                                        >
                                                          Re Pay
                                                        </button>
                                                      </div>
                                                    </> :
                                                    <></>
                                                }

                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {type === "Open Orders" ? (
                                    <>
                                      {lend ? (
                                        <>
                                          {openOrderDataPool.map((i, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                                  ? "special_card_deposit"
                                                  : ""
                                                  }`}
                                                onClick={() => {
                                                  gettingData(i);
                                                  setCardClicked(index);
                                                }}
                                              >
                                                <div className="selection-section">
                                                  <label className="spl-checkbox-green">
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        cardClicked === index
                                                          ? true
                                                          : false
                                                      }
                                                      name="data"
                                                      className="data_radio"
                                                    />
                                                    <span className="show-content"></span>
                                                  </label>
                                                </div>
                                                <div className="left-side">
                                                  <table>
                                                    <tbody>
                                                      <tr>
                                                        <td>Date & Time :</td>
                                                        <td>
                                                          {i.time_duration
                                                            .toString()
                                                            .replace("T", " ")}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Total Amount :</td>
                                                        <td>
                                                          {i.amt ? (
                                                            <>
                                                              {Number(
                                                                i.amt
                                                              ).toFixed(2)}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {Number(
                                                                i.current_price
                                                              ).toFixed(2)}
                                                            </>
                                                          )}{" "}
                                                          USD
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Memo :</td>
                                                        <td>{i.memo}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="center-line">
                                                  <div className="center-blob top">
                                                    <img src={i.logo} alt="" />
                                                  </div>
                                                  <div className="center-line-line"></div>
                                                  <div className="center-blob bottom">
                                                    <img src={i.logo} alt="" />
                                                  </div>
                                                </div>
                                                <div className="right-side">
                                                  <table className="mb-5">
                                                    <tbody>
                                                      <tr>
                                                        <td>Liq. Price :</td>
                                                        <td>
                                                          <span className="text-s-error">
                                                            {i.post_price ? (
                                                              <>
                                                                {Number(
                                                                  i.post_price
                                                                ).toFixed(2)}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {Number(
                                                                  i.init_amt
                                                                ).toFixed(2)}
                                                              </>
                                                            )}
                                                          </span>{" "}
                                                          USD
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          Collateral Asset :
                                                        </td>
                                                        <td>{i.sym}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {openOrderDataParticipant.map(
                                            (i, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                                    ? "special_card_deposit"
                                                    : ""
                                                    }`}
                                                  onClick={() => {
                                                    gettingData(i);
                                                    setCardClicked(index);
                                                  }}
                                                >
                                                  <div className="selection-section">
                                                    <label className="spl-checkbox-green">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          cardClicked === index
                                                            ? true
                                                            : false
                                                        }
                                                        name="data"
                                                        className="data_radio"
                                                      />
                                                      <span className="show-content"></span>
                                                    </label>
                                                  </div>
                                                  <div className="left-side">
                                                    <table>
                                                      <tbody>
                                                        <tr>
                                                          <td>Date & Time :</td>
                                                          <td>
                                                            {i.time_duration
                                                              .toString()
                                                              .replace(
                                                                "T",
                                                                " "
                                                              )}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Total Amount :
                                                          </td>
                                                          <td>
                                                            {i.init_amt ? (
                                                              <>
                                                                {Number(
                                                                  i.init_amt
                                                                ).toFixed(2)}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {Number(
                                                                  i.current_price
                                                                ).toFixed(2)}
                                                              </>
                                                            )}{" "}
                                                            USD
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>Memo :</td>
                                                          <td>{i.memo}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div className="center-line">
                                                    <div className="center-blob top">
                                                      <img
                                                        src={i.logo}
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="center-line-line"></div>
                                                    <div className="center-blob bottom">
                                                      <img
                                                        src={i.logo}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="right-side">
                                                    <table className="mb-5">
                                                      <tbody>
                                                        <tr>
                                                          <td>Liq. Price :</td>
                                                          <td>
                                                            <span className="text-s-error">
                                                              {Number(
                                                                i.settlement_price
                                                              ).toFixed(2)}
                                                            </span>{" "}
                                                            USD
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Collateral Asset :
                                                          </td>
                                                          <td>{i.sym}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <div className="text-end"></div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {type === "History" ? (
                                        <>
                                          {lend ? (
                                            <>
                                              {openPositionDataPool.map(
                                                (i, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                                        ? "special_card_deposit"
                                                        : ""
                                                        }`}
                                                      onClick={() => {
                                                        gettingData(i);
                                                        setCardClicked(index);
                                                      }}
                                                    >
                                                      <div className="selection-section">
                                                        <label className="spl-checkbox-green">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              cardClicked ===
                                                                index
                                                                ? true
                                                                : false
                                                            }
                                                            name="data"
                                                            className="data_radio"
                                                          />
                                                          <span className="show-content"></span>
                                                        </label>
                                                      </div>
                                                      <div className="left-side">
                                                        <table>
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Date & Time :
                                                              </td>
                                                              <td>
                                                                {i.time_duration
                                                                  .toString()
                                                                  .replace(
                                                                    "T",
                                                                    " "
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Total Amount :
                                                              </td>
                                                              <td>
                                                                {i.amt ? (
                                                                  <>
                                                                    {Number(
                                                                      i.amt
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Number(
                                                                      i.current_price
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                )}{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Memo :</td>
                                                              <td>{i.memo}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="center-line">
                                                        <div className="center-blob top">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="center-line-line"></div>
                                                        <div className="center-blob bottom">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="right-side">
                                                        <table className="mb-5">
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Liq. Price :
                                                              </td>
                                                              <td>
                                                                <span className="text-s-error">
                                                                  {i.post_price ? (
                                                                    <>
                                                                      {Number(
                                                                        i.post_price
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {Number(
                                                                        i.amt
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </span>{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Collateral Asset
                                                                :
                                                              </td>
                                                              <td>{i.sym}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                              {openOrderDataPool.map(
                                                (i, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === (index + 10)
                                                        ? "special_card_deposit"
                                                        : ""
                                                        }`}
                                                      onClick={() => {
                                                        gettingData(i);
                                                        setCardClicked((index + 10));
                                                      }}
                                                    >
                                                      <div className="selection-section">
                                                        <label className="spl-checkbox-green">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              cardClicked ===
                                                                (index + 10)
                                                                ? true
                                                                : false
                                                            }
                                                            name="data"
                                                            className="data_radio"
                                                          />
                                                          <span className="show-content"></span>
                                                        </label>
                                                      </div>
                                                      <div className="left-side">
                                                        <table>
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Date & Time :
                                                              </td>
                                                              <td>
                                                                {i.time_duration
                                                                  .toString()
                                                                  .replace(
                                                                    "T",
                                                                    " "
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Total Amount :
                                                              </td>
                                                              <td>
                                                                {i.amt ? (
                                                                  <>
                                                                    {Number(
                                                                      i.amt
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Number(
                                                                      i.current_price
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                )}{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Memo :</td>
                                                              <td>{i.memo}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="center-line">
                                                        <div className="center-blob top">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="center-line-line"></div>
                                                        <div className="center-blob bottom">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="right-side">
                                                        <table className="mb-5">
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Liq. Price :
                                                              </td>
                                                              <td>
                                                                <span className="text-s-error">
                                                                  {i.post_price ? (
                                                                    <>
                                                                      {Number(
                                                                        i.post_price
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {Number(
                                                                        i.init_amt
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </span>{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Collateral Asset
                                                                :
                                                              </td>
                                                              <td>{i.sym}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {openPositionDataParticipant.map(
                                                (i, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === index
                                                        ? "special_card_deposit"
                                                        : ""
                                                        }`}
                                                      onClick={() => {
                                                        gettingData(i);
                                                        setCardClicked(index);
                                                      }}
                                                    >
                                                      <div className="selection-section">
                                                        <label className="spl-checkbox-green">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              cardClicked ===
                                                                index
                                                                ? true
                                                                : false
                                                            }
                                                            name="data"
                                                            className="data_radio"
                                                          />
                                                          <span className="show-content"></span>
                                                        </label>
                                                      </div>
                                                      <div className="left-side">
                                                        <table>
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Date & Time :
                                                              </td>
                                                              <td>
                                                                {i.time_duration
                                                                  .toString()
                                                                  .replace(
                                                                    "T",
                                                                    " "
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Total Amount :
                                                              </td>
                                                              <td>
                                                                {i.init_amt ? (
                                                                  <>
                                                                    {Number(
                                                                      i.init_amt
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Number(
                                                                      i.current_price
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                )}{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Memo :</td>
                                                              <td>{i.memo}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="center-line">
                                                        <div className="center-blob top">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="center-line-line"></div>
                                                        <div className="center-blob bottom">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="right-side">
                                                        <table className="mb-5">
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Liq. Price :
                                                              </td>
                                                              <td>
                                                                <span className="text-s-error">
                                                                  {Number(
                                                                    i.settlement_price
                                                                  ).toFixed(2)}
                                                                </span>{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Collateral Asset
                                                                :
                                                              </td>
                                                              <td>{i.sym}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}

                                              {openOrderDataParticipant.map(
                                                (i, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClicked === (index + 10)
                                                        ? "special_card_deposit"
                                                        : ""
                                                        }`}
                                                      onClick={() => {
                                                        gettingData(i);
                                                        setCardClicked((index + 10));
                                                      }}
                                                    >
                                                      <div className="selection-section">
                                                        <label className="spl-checkbox-green">
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              cardClicked ===
                                                                (index + 10)
                                                                ? true
                                                                : false
                                                            }
                                                            name="data"
                                                            className="data_radio"
                                                          />
                                                          <span className="show-content"></span>
                                                        </label>
                                                      </div>
                                                      <div className="left-side">
                                                        <table>
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Date & Time :
                                                              </td>
                                                              <td>
                                                                {i.time_duration
                                                                  .toString()
                                                                  .replace(
                                                                    "T",
                                                                    " "
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Total Amount :
                                                              </td>
                                                              <td>
                                                                {i.init_amt ? (
                                                                  <>
                                                                    {Number(
                                                                      i.init_amt
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Number(
                                                                      i.current_price
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </>
                                                                )}{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>Memo :</td>
                                                              <td>{i.memo}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="center-line">
                                                        <div className="center-blob top">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="center-line-line"></div>
                                                        <div className="center-blob bottom">
                                                          <img
                                                            src={i.logo}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="right-side">
                                                        <table className="mb-5">
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                Liq. Price :
                                                              </td>
                                                              <td>
                                                                <span className="text-s-error">
                                                                  {Number(
                                                                    i.settlement_price
                                                                  ).toFixed(2)}
                                                                </span>{" "}
                                                                USD
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Collateral Asset
                                                                :
                                                              </td>
                                                              <td>{i.sym}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="text-end" id="closebtn">
                          <ul className="spl-button-list d-flex justify-content-start justify-content-end">
                            <li className="px-2">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={() => setShow1(true)}
                              >
                                Close all Order
                              </button>
                            </li>
                          </ul>
                          <Modal
                            show={show1}
                            onHide={() => setShow1(false)}
                            backdrop="static"
                            keyboard={false}
                            className="modal-dialog-coin"
                          >
                            <div className="popup_error">
                              <div className="popup_error_head">
                                <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
                                  Confirm !
                                </p>
                                <div className="event_line"></div>
                              </div>
                              <p className="popup_error_body_text pt-4 pb-3">
                                Do you want to close all orders ?
                              </p>
                              <div className="d-flex justify-content-between ms-5 me-5 pb-4">
                                <button
                                  type="button"
                                  className="btn btn-spl-black ps-3 pe-3 mt-3"
                                  onClick={() => setShow1(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-spl-black ps-3 pe-3 mt-3"
                                  onClick={closeAllOrders}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </Modal>
                        </div>
                        {type === "Open Positions" ? (
                          <>
                            <div className="card special_card_deposit  spl-box-content mb-5">
                              <div>
                                <h1 className="box-title">Manage Position</h1>
                                {lend ? (
                                  <>
                                    {cardDataPool._memo === "" ? (
                                      <p className="plain_text text-center">
                                        Select from orders to proceed.
                                      </p>
                                    ) : (
                                      <div className="list-content">
                                        <table className="mb-5">
                                          <tbody>
                                            <tr>
                                              <td>Liquation Price :</td>
                                              <td>
                                                {cardDataPool._liquationPrice}{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Collateralized Asset :</td>
                                              <td>
                                                {
                                                  cardDataPool._colateralizedAsset
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Total Amount :</td>
                                              <td>
                                                {Number(
                                                  cardDataPool._totalAmount
                                                ).toFixed(2)}{" "}
                                                USD
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Alt_id :</td>
                                              <td>
                                                {cardDataPool._alt_id} ...
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Memo :</td>
                                              <td>
                                                {cardDataPool._memo.substring(
                                                  0,
                                                  8
                                                )}{" "}
                                                ...
                                                <span
                                                  onClick={() =>
                                                    setIsCopied(true)
                                                  }
                                                >
                                                  {isCopied ? (
                                                    <img
                                                      src={green_tick}
                                                      className="copy_img"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={copy}
                                                      className="copy_img"
                                                      onClick={() => {
                                                        navigator.clipboard.writeText(
                                                          cardDataPool._memo
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {cardDataParticipant.withdrawableHedge ===
                                      "" ? (
                                      <>
                                        <p className="plain_text text-center">
                                          Select from orders to proceed.
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <div className="list-content">
                                          <table className="mb-5">
                                            <tbody>
                                              <tr>
                                                <td>Withdrawable Hedge :</td>
                                                <td>
                                                  {
                                                    cardDataParticipant.withdrawableHedge
                                                  }{" "}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Entry Price :</td>
                                                <td>
                                                  {
                                                    cardDataParticipant.entryPrice
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Mark Price :</td>
                                                <td>
                                                  {cardDataParticipant.markPrice}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>PNL (ROI %) :</td>
                                                <td>
                                                  {cardDataParticipant.roe}%
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Liquidation Price :</td>
                                                <td>
                                                  {
                                                    cardDataParticipant.liquationPrice
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Collateralized Asset :</td>
                                                <td>
                                                  {
                                                    cardDataParticipant.colateralizedAsset
                                                  }

                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Allocated Margin :</td>
                                                <td>
                                                  {Number(
                                                    cardDataParticipant.allocatedMargin
                                                  ).toFixed(3)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Total Amount :</td>
                                                <td>
                                                  {Number(
                                                    cardDataParticipant.totalAmount
                                                  ).toFixed(2)}{" "}
                                                  USD
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Memo :</td>
                                                <td>
                                                  {cardDataParticipant.memo.substring(
                                                    0,
                                                    8
                                                  )}{" "}
                                                  ...
                                                  <span
                                                    onClick={() =>
                                                      setIsCopied(true)
                                                    }
                                                  >
                                                    {isCopied ? (
                                                      <img
                                                        src={green_tick}
                                                        className="copy_img"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={copy}
                                                        className="copy_img"
                                                        onClick={() => {
                                                          navigator.clipboard.writeText(
                                                            cardDataParticipant.memo
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        {!checkPool ? (
                                          <>
                                            <div className="row">
                                              {detailType && !detailTypeRe ? (
                                                <>
                                                  <div>
                                                    <h1 className="box-title text-center">
                                                      Adjust Margin
                                                    </h1>
                                                    <div className="col-12  btn-group select-coin-btn">
                                                      <button
                                                        type="button"
                                                        className="select_coin_btn w-100 mb-4 selectcoin-btn"
                                                        onClick={() =>
                                                          setShowModalCoin(true)
                                                        }
                                                      >
                                                        <div className="row">
                                                          <div className="col-2">
                                                            {coinImg === "" ? (
                                                              <></>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  src={coinImg}
                                                                  className="select_img"
                                                                  alt="coin"
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className="col-8 text-center">
                                                            <b>{coin}</b>
                                                          </div>
                                                          <div className="col-2 text-center">
                                                            <img
                                                              src={dropdown}
                                                              alt="drop"
                                                            />
                                                          </div>
                                                        </div>
                                                      </button>
                                                    </div>
                                                    <div className="input1 w-100">
                                                      <input
                                                        type="number"
                                                        name="amount"
                                                        placeholder="Margin Amount:"
                                                        className="input_earn w-100 orderpage-input"
                                                        value={amount}
                                                        onChange={(e) =>
                                                          setAmount(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="m-position-bottom">
                                                      <div className="m-position-bottom-flex d-flex justify-content-between">
                                                        <button
                                                          className="btn btn-spl-black btn-s-default p-2"
                                                          onClick={removeMargin}
                                                        >
                                                          Remove Margin
                                                        </button>
                                                        <button
                                                          className="btn btn-g-dark-blue btn-s-default p-2"
                                                          onClick={addMargin}
                                                        >
                                                          Add Margin
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {detailTypeRe ? (
                                                <>
                                                  <div>
                                                    <h1 className="box-title text-center">
                                                      Loan Re Payment
                                                    </h1>
                                                    <div className="table-content">
                                                      <table className="mb-5">
                                                        <tbody>
                                                          <tr>
                                                            <td>Currency :</td>
                                                            <td>
                                                              {
                                                                cardDataParticipant.colateralizedAsset
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              Liq. Price :
                                                            </td>
                                                            <td>
                                                              {
                                                                cardDataParticipant.liquationPrice
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>% ROE:</td>
                                                            <td>
                                                              {
                                                                cardDataParticipant.roe
                                                              }
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              Re-pay Date :
                                                            </td>
                                                            <td>
                                                              {
                                                                cardDataParticipant.date
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="m-position-bottom">
                                                      <div className="m-position-bottom-flex d-flex justify-content-between">
                                                        <button
                                                          className="btn btn-spl-black btn-s-default"
                                                          onClick={() => {
                                                            setDetailType(true);
                                                            setDetailTypeRe(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          Cancel
                                                        </button>
                                                        <button
                                                          className="btn btn-g-dark-blue btn-s-default"
                                                          onClick={repay}
                                                        >
                                                          Re Pay
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {type === "Open Orders" ? (
                              <>
                                <div className="card special_card_deposit  spl-box-content mb-5">
                                  <div>
                                    <h1 className="box-title">Manage Order</h1>
                                    {lend ? (
                                      <>
                                        {cardDataPool._memo === "" ? (
                                          <p className="plain_text text-center">
                                            Select from orders to proceed.
                                          </p>
                                        ) : (
                                          <div className="list-content">
                                            <table className="mb-5">
                                              <tbody>
                                                <tr>
                                                  <td>Liquation Price :</td>
                                                  <td>
                                                    {
                                                      cardDataPool._liquationPrice
                                                    }{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Collateralized Asset :</td>
                                                  <td>
                                                    {
                                                      cardDataPool._colateralizedAsset
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Total Amount :</td>
                                                  <td>
                                                    {Number(
                                                      cardDataPool._totalAmount
                                                    ).toFixed(2)}{" "}
                                                    USD
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Alt_id :</td>
                                                  <td>
                                                    {cardDataPool._alt_id} ...
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Memo :</td>
                                                  <td>
                                                    {cardDataPool._memo.substring(
                                                      0,
                                                      8
                                                    )}{" "}
                                                    ...
                                                    <span
                                                      onClick={() =>
                                                        setIsCopied(true)
                                                      }
                                                    >
                                                      {isCopied ? (
                                                        <img
                                                          src={green_tick}
                                                          className="copy_img"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={copy}
                                                          className="copy_img"
                                                          onClick={() => {
                                                            navigator.clipboard.writeText(
                                                              cardDataPool._memo
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {cardDataParticipant.withdrawableHedge ===
                                          "" ? (
                                          <>
                                            <p className="plain_text text-center">
                                              Select from orders to proceed.
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <div className="list-content">
                                              <table className="mb-5">
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      Withdrawable Hedge :
                                                    </td>
                                                    <td>
                                                      {
                                                        cardDataParticipant.withdrawableHedge
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Entry Price :</td>
                                                    <td>
                                                      {
                                                        cardDataParticipant.entryPrice
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Mark Price :</td>
                                                    <td>
                                                      {cardDataParticipant.markPrice}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>PNL (ROE %) :</td>
                                                    <td>
                                                      {cardDataParticipant.roe}%
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Liquidation Price :</td>
                                                    <td>
                                                      {
                                                        cardDataParticipant.liquationPrice
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Collateralized Asset :
                                                    </td>
                                                    <td>
                                                      {
                                                        cardDataParticipant.colateralizedAsset
                                                      }

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Allocated Margin :</td>
                                                    <td>
                                                      {Number(
                                                        cardDataParticipant.allocatedMargin
                                                      ).toFixed(3)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Total Amount :</td>
                                                    <td>
                                                      {Number(
                                                        cardDataParticipant.totalAmount
                                                      ).toFixed(2)}{" "}
                                                      USD
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Memo :</td>
                                                    <td>
                                                      {cardDataParticipant.memo.substring(
                                                        0,
                                                        8
                                                      )}{" "}
                                                      ...
                                                      <span
                                                        onClick={() =>
                                                          setIsCopied(true)
                                                        }
                                                      >
                                                        {isCopied ? (
                                                          <img
                                                            src={green_tick}
                                                            className="copy_img"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={copy}
                                                            className="copy_img"
                                                            onClick={() => {
                                                              navigator.clipboard.writeText(
                                                                cardDataParticipant.memo
                                                              );
                                                            }}
                                                          />
                                                        )}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <button
                                              className="btn btn-g-dark-blue btn-s-default"
                                              onClick={closeOrder}
                                            >
                                              Close this order
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {type === "History" ? (
                                  <>
                                    <>
                                      <div className="card special_card_deposit  spl-box-content mb-5">
                                        <div>
                                          <h1 className="box-title">History</h1>
                                          {lend ? (
                                            <>
                                              {cardDataPool._memo === "" ? (
                                                <p className="plain_text text-center">
                                                  Select from orders to proceed.
                                                </p>
                                              ) : (
                                                <div className="list-content">
                                                  <table className="mb-5">
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          Liquation Price :
                                                        </td>
                                                        <td>
                                                          {
                                                            cardDataPool._liquationPrice
                                                          }{" "}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          Collateralized Asset :
                                                        </td>
                                                        <td>
                                                          {
                                                            cardDataPool._colateralizedAsset
                                                          }
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Total Amount :</td>
                                                        <td>
                                                          {Number(
                                                            cardDataPool._totalAmount
                                                          ).toFixed(2)}{" "}
                                                          USD
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Alt_id :</td>
                                                        <td>
                                                          {cardDataPool._alt_id}{" "}
                                                          ...
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Memo :</td>
                                                        <td>
                                                          {cardDataPool._memo.substring(
                                                            0,
                                                            8
                                                          )}{" "}
                                                          ...
                                                          <span
                                                            onClick={() =>
                                                              setIsCopied(true)
                                                            }
                                                          >
                                                            {isCopied ? (
                                                              <img
                                                                src={green_tick}
                                                                className="copy_img"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={copy}
                                                                className="copy_img"
                                                                onClick={() => {
                                                                  navigator.clipboard.writeText(
                                                                    cardDataPool._memo
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {cardDataParticipant.withdrawableHedge ===
                                                "" ? (
                                                <>
                                                  <p className="plain_text text-center">
                                                    Select from orders to
                                                    proceed.
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="list-content">
                                                    <table className="mb-5">
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            Withdrawable Hedge :
                                                          </td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.withdrawableHedge
                                                            }{" "}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>Entry Price :</td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.entryPrice
                                                            }
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>Mark Price :</td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.markPrice
                                                            }
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>PNL (ROE %) :</td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.roe
                                                            }
                                                            %
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Liquidation Price :
                                                          </td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.liquationPrice
                                                            }
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Collateralized Asset
                                                            :
                                                          </td>
                                                          <td>
                                                            {
                                                              cardDataParticipant.colateralizedAsset
                                                            }

                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Allocated Margin :
                                                          </td>
                                                          <td>
                                                            {Number(
                                                              cardDataParticipant.allocatedMargin
                                                            ).toFixed(3)}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Total Amount :
                                                          </td>
                                                          <td>
                                                            {Number(
                                                              cardDataParticipant.totalAmount
                                                            ).toFixed(2)}{" "}
                                                            USD
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>Memo :</td>
                                                          <td>
                                                            {cardDataParticipant.memo.substring(
                                                              0,
                                                              8
                                                            )}{" "}
                                                            ...
                                                            <span
                                                              onClick={() =>
                                                                setIsCopied(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {isCopied ? (
                                                                <img
                                                                  src={
                                                                    green_tick
                                                                  }
                                                                  className="copy_img"
                                                                />
                                                              ) : (
                                                                <img
                                                                  src={copy}
                                                                  className="copy_img"
                                                                  onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                      cardDataParticipant.memo
                                                                    );
                                                                  }}
                                                                />
                                                              )}
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {historyType === "Deposit History" ? (
                    <>
                      <div className="container" id="historymain">
                        <div className="row ">
                          <div className="col-xl-8">
                            <div className="row enable_scroll_4 ps-2 pt-2 pe-4">
                              {noDepositHistory ? (
                                <>
                                  <p className="text-center text_design api_head pt-5">
                                    There is no Deposit to show.
                                  </p>
                                </>
                              ) : (
                                <>
                                  {depositHistoryData.map((i, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`table_section.coin-item-row history-repay-box mb-3 card ${cardClickedDeposit === index
                                          ? "special_card_deposit"
                                          : ""
                                          }`}
                                        onClick={() => {
                                          gettingDataDeposit(i);
                                          setCardClickedDeposit(index);
                                        }}
                                      >
                                        <div className="selection-section">
                                          <label className="spl-checkbox-green">
                                            <input
                                              type="checkbox"
                                              checked={
                                                cardClicked === index
                                                  ? true
                                                  : false
                                              }
                                              name="data"
                                              className="data_radio"
                                            />
                                            <span className="show-content"></span>
                                          </label>
                                        </div>
                                        <div className="left-side">
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>Coin :</td>
                                                <td>{i.coin}</td>
                                              </tr>
                                              <tr>
                                                <td>Network :</td>
                                                <td>{i.network}</td>
                                              </tr>
                                              <tr>
                                                <td>Memo :</td>
                                                <td>{i.memo}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="center-line">
                                          <div className="center-blob top">
                                            <img src={i.logo} alt="" />
                                          </div>
                                          <div className="center-line-line"></div>
                                          <div className="center-blob bottom">
                                            <img src={i.logo} alt="" />
                                          </div>
                                        </div>
                                        <div className="right-side">
                                          <table className="mb-5">
                                            <tbody>
                                              <tr>
                                                <td>Amount :</td>
                                                <td>
                                                  <span className="text-s-error">
                                                    {i.amount}
                                                  </span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Price:</td>
                                                <td>{i.price}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4">
                            <div className="card special_card_deposit  spl-box-content mb-5">
                              <h1 className="box-title">Manage Deposit</h1>
                              {cardDataDeposit._memo_dep === "" ? (
                                <>
                                  <p className="plain_text text-center">
                                    Select deposit order to proceed.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div className="list-content">
                                    <table className="mb-5">
                                      <tbody>
                                        <tr>
                                          <td>Coin :</td>
                                          <td>{cardDataDeposit._coin} </td>
                                        </tr>
                                        <tr>
                                          <td>Network :</td>
                                          <td>{cardDataDeposit._network}</td>
                                        </tr>
                                        <tr>
                                          <td>Memo:</td>
                                          <td>
                                            {cardDataDeposit._memo_dep.substring(
                                              0,
                                              8
                                            )}{" "}
                                            . . .
                                            <span
                                              onClick={() => setIsCopied(true)}
                                            >
                                              {isCopied ? (
                                                <img
                                                  src={green_tick}
                                                  className="copy_img"
                                                />
                                              ) : (
                                                <img
                                                  src={copy}
                                                  className="copy_img"
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      cardDataDeposit._memo_dep
                                                    );
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Amount :</td>
                                          <td>{cardDataDeposit._amount}</td>
                                        </tr>
                                        <tr>
                                          <td>Price :</td>
                                          <td>{cardDataDeposit._price}</td>
                                        </tr>
                                        <tr>
                                          <td>Ticker :</td>
                                          <td>{cardDataDeposit._ticker}</td>
                                        </tr>
                                        <tr>
                                          <td>Address :</td>
                                          <td>{cardDataDeposit._address}</td>
                                        </tr>
                                        <tr>
                                          <td>Deposit at :</td>
                                          <td>{cardDataDeposit._time}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div className="m-position-bottom">
                                      <div className="m-position-bottom-flex d-flex justify-content-between">
                                        <button
                                          className="btn btn-spl-black btn-s-default"
                                          onClick={validate}
                                        >
                                          Validate
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {historyType === "Withdraw History" ? (
                        <>
                          <p className="text-center text_design api_head pt-5" style={{ marginBottom: "300px" }}>
                            There is no Withdraw to show.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div class="deposit-card">
                <div className="card-title d-flex align-items-center">
                  <div className="icon">
                    <img src={tick} alt="tick" />
                  </div>
                  <h2>
                    Deposit Successful <span className="symbol">!</span>
                  </h2>
                </div>
                <div className="card-maincontent">
                  <h3>
                    Memo <span className="colon">:</span>{" "}
                    {cardDataDeposit._memo_dep.substring(0, 10)} . . .
                  </h3>
                  <h3>
                    Ticker <span className="colon">:</span>{" "}
                    {cardDataDeposit._ticker}
                  </h3>
                  <div className="transaction-details">
                    <div className="tnxid">
                      <h3>
                        Address <span className="colon">:</span>{" "}
                        {cardDataDeposit._address.substring(0, 10)} . . .
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="depositcard-footer">
                  <span className="sidedesign"></span>
                  <h4>Total Transfer</h4>
                  <span className="amount">$ {cardDataDeposit._amount}</span>
                </div>
                <div class="design designtop">
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                </div>
                <div class="design designbottom">
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <Modal
        show={showA}
        onHide={() => setShowA(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Congratulations !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            Please order has been placed.
          </p>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowA(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showB}
        onHide={() => setShowB(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="popup_error">
          <div className="popup_error_head">
            <p className="popup_error_head_text text-start ps-3 pt-2 mb-1">
              Done !
            </p>
            <div className="event_line"></div>
          </div>
          <p className="popup_error_body_text pt-4 pb-3">
            All of your orders has been deleted.
          </p>
          <div className="d-flex justify-content-center pb-4">
            <CountdownCircleTimer
              size={40}
              strokeWidth={3}
              isPlaying
              duration={3}
              colors={"red"}
              colorsTime={[3, 0]}
            >
              {({ remainingTime }) => {
                return (
                  <img
                    src={cross}
                    className="cross"
                    onClick={() => setShowB(false)}
                  />
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModalCoin}
        onHide={() => setShowModalCoin(false)}
        backdrop="static"
        keyboard={false}
        className="modal-dialog-coin"
      >
        <div className="coin_popup">
          <div className="popup_coin_upper_section p-3">
            <div className="row">
              <div className="text-end" onClick={() => setShowModalCoin(false)}>
                <img
                  src={cross}
                  className="cross"
                  onClick={() => setShowModalCoin(false)}
                />
              </div>
              <div
                className="text-start inter_text"
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  marginTop: "-20px",
                }}
                onClick={() => setShowModalCoin(false)}
              >
                Select a Token
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  <img src={search} style={{ height: "18px" }} />
                </span>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setSearchParam(e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
          <ul className="coin_pop mb-4 p-3 pt-3">
            {marginAsset.map((items) => {
              if((items.symbol).toLowerCase().includes(searchParam.toLowerCase()) || (items.name).toLowerCase().includes(searchParam.toLowerCase())){
              return (
                <div
                  className="row coin_hover mb-3 pt-3 pb-3"
                  onClick={() => {
                    setShowModalCoin(false);
                    setCoin(items.symbol);
                    setCoinImg(items.link);
                  }}
                >
                  <div className="row text-start ps-4">
                    <li className="coin-list">
                      <img src={items.link} alt="coin" className="select_img" />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="ps-3 pt-2 coin-name">
                        {items.symbol}
                      </span>
                      <span className="ps-3 pt-2 coin_subname">
                        {items.name}
                      </span>
                    </li>
                  </div>
                </div>
              );
                }
            })}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default Order;
