import React, { useState, useEffect } from "react";
import Animation from "../../Animation";
import Footer from "../../layouts/Footer/index";
import { MiniChart } from "react-ts-tradingview-widgets";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Lend from "./Lend";
import { Helmet } from 'react-helmet';

function Home() {
  const { selectedType } = useSelector((state) => state.auth);
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();

  // AOS.init();
  AOS.init({
    duration: 1000,
    once: false,
  });

  setTimeout(() => {
    setLoad(false);
  }, 2000);

  return (
    <>
    <Helmet>
        <title>Discover the Future of Finance with Our Crypto Platform - Join Now!</title>
        <meta name="description" content="Welcome to our platform, where you can experience the future of finance through our user-friendly cryptocurrency services. Buy, sell, and trade your favorite digital assets with ease and security. Join our community of crypto enthusiasts today!" />
        <link rel="canonical" href="https://www.example.com" />
      </Helmet>
      <section className="" data-aos="fade-up">
        <Lend />
      </section>
      <div className="container" id="mainhome">
        <section className="mt-5 pt-5">
          <p className="text-center api_head" data-aos="fade-right">
            FLITCHCOIN as a
            <span className="text_design"> One - Stop Solution </span>
          </p>
          <p className="api_text" data-aos="fade-right">
            At Flitchcoin, we strive to make managing digital currency as simple
            and hassle-free as possible. Our platform serves as a one-stop-shop
            for all your digital currency needs, offering a range of payment,
            staking, and liquidity lending solutions to help you thrive. Our
            free and easy-to-use account services makes it easy for you to get
            started with managing digital currency, providing you with all the
            tools and resources you need.
          </p>
          <div className="container overflow-hidden position-relative">
            <div className="d-none d-lg-block position-relative">
              <Animation />
            </div>
            <div className="row mt-5 pt-4">
              <div className="col-lg-6 ">
                <p
                  className="text-center text-lg-end text-lg-end api_head"
                  data-aos="fade-up"
                >
                  NO MATTER
                </p>
              </div>
              <div className="col-lg-6">
                <p
                  className="text-center text-lg-start text-start api_head"
                  data-aos="fade-down"
                >
                  WHETHER IT'S
                </p>
              </div>
            </div>
            <div className="row mt-4" data-aos="zoom-in-up">
              <div className="col-xl-6" id="stable">
                <p
                  className="text-primary text-center api_head"
                  id="stablepara"
                >
                  Stable
                </p>
                <div
                  className="card parent_card back text-center m-3"
                  id="stable"
                >
                  {load ? (
                    <></>
                  ) : (
                    <MiniChart
                        colorTheme="light"
                        width="100%"
                        isTransparent
                        symbol="USDT"
                      ></MiniChart>
                  )}
                </div>
              </div>
              <div className="col-xl-6">
                <p className="text-danger text-center api_head">Volatile</p>
                <div
                  className="card parent_card back text-center m-3"
                  id="volatile"
                >
                  {load ? (
                    <></>
                  ) : (
                    <MiniChart
                        colorTheme="light"
                        width="100%"
                        isTransparent
                        symbol="BTC"
                      ></MiniChart>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row mt-5 mb-4" data-aos="fade-down">
          <div className="col-lg-6">
            <p className="api_head">Flitchcoin for Pool Investors</p>
            <p className="api_text pe-lg-3">
              Maximize your returns with Flitchcoin's high yield stablecoin
              funds. As a pool investor, you have access to a range of
              investment, borrowing, and staking options that offer risk-free
              opportunities to grow your portfolio.
            </p>
          </div>
          {/* <div className="col-lg-2 mb-5"></div> */}
          <div className="col-lg-6">
            <p className="api_head">Flitchcoin for Part. Investors</p>
            <p className="api_text pe-lg-3">
              As a participant investor at Flitchcoin, you have access to a
              range of high yield flitch funds where you can invest, borrow, and
              flexibly stake your cryptocurrencies.
            </p>
          </div>
        </section>
      </div>
      <div
        className="mt-5 mb-5"
        style={{ background: "#000" }}
        id="mainhome2"
        data-aos-anchor-placement="center-center"
      >
        <div className="container py-2 py-md-5">
          <div className="row">
            <div className="col-xl-8" data-aos="fade-left">
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="row"
                    style={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}
                  >
                    <p className="text-end mb-0"> Experience the</p>
                    <p className="text-end"> Power of</p>
                  </div>
                </div>
                <div className="col-md-9">
                  <p className="text-end text-md-start flitch_earn">Flitch-EARN</p>
                </div>
              </div>
              <p className="mt-3" style={{color: '#D3D3D3', fontWeight: 400}}>
                Experience the Flitchcoin advantage and diversify your
                cryptocurrency portfolio with our extended earn service. Reduce
                your risk exposure and borrow assets to take them off the
                markets with our lending and borrowing feature.
                <br />
                At Flitchcoin, we understand the importance of risk management,
                which is why we offer cutting-edge solutions for stablecoin
                investors. With high liquidity, flexible staking and earn
                programs where you can choose the period that best suits your
                needs. Don't let risk hold you back
              </p>
            </div>
            <div className="col-xl-1"></div>
            <div className="col-xl-3 mt-5" data-aos="fade-right">
              <Link to="/login" style={{ textDecoration: "none" }}>
                <div className="button_special mt-5">
                  <a href="">
                    Start earning &nbsp;&nbsp;
                    <span className="shift">›</span>
                  </a>
                  <div className="mask"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="mainhome3">
      <section className="row pt-3 mb-md-5">
          <div className="col-lg-5 d-flex flex-column justify-content-center h_get_to_know">
            <h1 className="pt-5 api_head">Essence of Passive Income Is <span className="text_design"> Risk - Management</span> </h1>
            <p className="pt-4 api_text">
              At <b className="text-dark"> Flitchcoin</b>, we are committed to <b className="text-dark">transparency</b> and <b className="text-dark">stability</b> in all aspects of our business. We welcome clear regulation and value engagement with <b className="text-dark">policymakers</b> to ensure transparency and stability for our customers in order to promote a more responsible and stable future for cryptocurrency. Choose Flitchcoin and join us in building a more transparent and stable future.
            </p>
          </div>
          <div className="col-12 col-md-11 col-lg-6 mt-1 ms-md-5 card parent_card back d-flex flex-column align-items-center justify-content-center" style={{alignSelf: 'center'}} id="xrp">
            {
              load ?
                <></> :
                <>
                <p className="ps-3 know_no_risk">KNOW / NO<br/>RISK</p>
                </>
            }
          </div>
          <p className="mt-5 pt-5 api_text mb-5">At <b className="text-dark"> Flitchcoin</b>, we believe in building delightful user experiences that make it <b className="text-dark"> easy and intuitive</b> for people to interact with your business. Our <b className="text-dark"> Lending, Staking, and Earn</b> platforms are designed to be easy to use, and you can get started right away. With Flitchcoin, we created beautiful, intuitive ways for your entire ecosystem to engage with that helped in improving the overall user experience and driving growth.
            <br />Join us and see the difference Flitchcoin can make for you and your business.</p>
        </section>
        {selectedType === "accept" ||
        selectedType === undefined ||
        selectedType === null ? (
          <>
            <div
              className="settings_box my-2 my-md-5 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div className="row px-3 px-md-4">
                <div className="col-12 col-lg-6 graybox_img">
                  <img src="https://flitchcoin.s3.eu-west-2.amazonaws.com/sign.webp" className="" alt="graybox" />
                </div>
                <div className="col-12 col-lg-6" id="graybox">
                  <p className="api_head">
                    <span className="text_design">Sign Up Now</span> to get the
                    most out of your investments.
                  </p>
                  <p className="api_text pe-3">
                    Are you ready to take control of your investments? Look no
                    further than Flitchcoin. Our platform offers a range of
                    high-yield stable coin funds, as well as the ability to
                    invest, borrow, and stake your stable coins with immense
                    ease and enables you to elevate your investments
                    exponentially.
                  </p>
                  <div className="text-center">
                    <button
                      className="w-md-50 btn-dark round-btn px-5"
                      onClick={() => navigate("/sign-up")}
                    >
                      SignUp Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Home;
